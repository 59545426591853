import * as enums from '@worldfavor/constants/enums'

/*
 * MODAL SERVICE
 *
 * Used for invoking modals for different purposes.
 * For example:
 * - performing create/delete/update any object
 * - creating any object and then tag it to another object automatically
 * - open an object with a specific template id
 * - open a picker
 *
 * This service uses the dataOpsService to perform the actual operations on the data
 */
(function () {
    'use strict'

    angular
        .module('wf.data')
        .service('modalService', modalService)

    modalService.$inject = ['$uibModal', '$ngBootbox', '$translate', '$q', '$timeout', 'apiProxy', 'dataOperationsService', 'dataModeller', 'screenLoaderService', '$sanitize', 'dataQuery', 'wfObject', '$rootScope', 'wfTranslate', 'wfAuth', 'wfMeasureService']

    function modalService($uibModal, $ngBootbox, $translate, $q, $timeout, apiProxy, dataOps, dataModeller, screenLoader, $sanitize, dataQuery, wfObject, $rootScope, wfTranslate, wfAuth, wfMeasureService) {
        const service = {
            editor,
            create,
            createWithPromise,
            edit,
            confirmDelete,
            createWithRelation,
            createInfluence,
            editSettings,
            editConditions,
            editRequirement,
            openItem,
            open: openItem,
            openCreatorAndPicker,
            verifyItem,
            askToSendUserInviteMail,
            openMailHistory,
            editFormattedObjectSettings,
            addTo,
            sendTo,
            editVisibility,
            startOrdering,
            previewMail,
            openInfluenceCreator,
            alert,
            openGuidance,
            openMeasureAnswerCreator,
        }
        const defaultSettings = {

        }

        return service

        function askToSendUserInviteMail(auth0User) {
            const jqDf = $.Deferred()
            $ngBootbox.customDialog({
                title: 'Skicka inbjudan',
                message: 'Bekräfta att du vill skicka inbjudan till:<span style=\'font-size:14px;\'><br /><br /><b>' + (auth0User.user_metadata ? (auth0User.user_metadata.name || auth0User.name) : auth0User.name) + '</b><br />' + auth0User.email + '</span>',
                onEscape: true,
                buttons: {
                    cancel: {
                        label: $translate.instant('Cancel'),
                        className: 'wf-btn-link',
                        callback() {
                            jqDf.resolve()
                        },
                    },
                    primary: {
                        label: 'Skicka nu',
                        className: 'btn-primary',
                        callback() {
                            return apiProxy('authentication.sendInviteMailToColleague', dataOps.prepareWfObject(auth0User)).then((res) => {
                                jqDf.resolve()
                            })
                        },
                    },
                },
            })

            return jqDf.promise()
        }

        function confirmDelete(item, options) {
            let modalTitle; let modalMessage
            const jqDf = $.Deferred()
            options = options || {}

            if (options.title) modalTitle = options.title
            else {
                modalTitle = item.type === enums.objectType.dataRelation
                    ? $translate.instant('confirmDeleteModal.dataRelation.title')
                    : $translate.instant('confirmDeleteModal.content.title')
            }

            if (options.message) modalMessage = options.message
            else {
                modalMessage = item.type === enums.objectType.dataRelation
                    ? $translate.instant('confirmDeleteModal.dataRelation.message')
                    : $translate.instant('confirmDeleteModal.content.message')
            }

            $ngBootbox.customDialog({
                title: modalTitle,
                message: modalMessage,
                onEscape: true,
                className: 'modal-confirmDelete', //Adding classname for user removal modal
                buttons: {
                    cancel: {
                        label: $translate.instant('Cancel'),
                        className: 'btn-default',
                        callback() {
                        },
                    },
                    primary: {
                        label: options.button || $translate.instant('OK'),
                        className: 'btn-danger',
                        callback() {
                            let itemToDelete

                            // if (item.type === enums.objectType.virtualDataRelation) {
                            // 	itemToDelete =
                            // }
                            return dataOps.destroy(item, { thirdParty: options.thirdParty }).then(() => {
                                jqDf.resolve()
                            })
                        },
                    },
                },
            })
            return jqDf.promise()
        }

        function editSettings(item) {
            const model = dataModeller.objectSettings.getCollectionModelFromItem(item)

            edit(model, {
                action: 'multi.savesettings',
                bypassAdapter: true,
                windowClass: 'modal-raw-settings-editor list-group-item-overflow-hidden',
            }).then((updatedSettings) => {
                item.settings = updatedSettings
            })
        }

        function editConditions(item) {
            const model = dataModeller.objectConditions.getCollectionModelFromItem(item)

            edit(model, {
                action: 'multi.savesettings',
                bypassAdapter: true,
                windowClass: 'modal-raw-settings-editor list-group-item-overflow-hidden',
            }).then((updatedConditions) => {
                item.conditions = updatedConditions
            })
        }

        function editFormattedObjectSettings(options) {
            const
                item = options.item

            const forOrganization = options.forOrganization

            const currentItemSettings = (item.type === enums.objectType.dataRelation || item.type === enums.objectType.network ? item.settings : item.conditions) || {}

            let settingsModel

            let originalSettingsModel

            const selectorOptionsDefinitions = options.objectSettings

            const editableSettingKinds = _.chain(options.objectSettings).map('settingKind').value()

            const additionalFormFieldData = {}

            const customControlsFormFieldData = {}

            let duplicateSettingKinds = {}

            const newSettingsBeforeSaving = {}

            let formSpec

            const jqDf = $.Deferred()

            if (selectorOptionsDefinitions) {
                if (selectorOptionsDefinitions.length > _.uniq(editableSettingKinds).length) {
                    duplicateSettingKinds = _.chain(editableSettingKinds).filter((val, i, iteratee) => { return _.includes(iteratee, val, i + 1) }).uniq().keyBy().value()
                }

                formSpec = {
                    schema: {},
                    form: [],
                    onBeforeRender(model) {
                        const output = {}
                        originalSettingsModel = model

                        // Needed in editor() > dataOperationContext {} > saved(), where id and type is set on itemData
                        _.assign(settingsModel, {
                            id: item.id,
                            type: item.type,
                        })

                        _.forEach(selectorOptionsDefinitions, (settingDef) => {
                            const
                                hasCurrentSettingValue = currentItemSettings.hasOwnProperty(settingDef.settingKind)

                            let currentSettingValue = currentItemSettings[settingDef.settingKind]

                            if (typeof settingDef.getValue === 'function') {
                                output[settingDef.modelKey] = settingDef.getValue(currentSettingValue, currentItemSettings)
                            }
                            else if (hasCurrentSettingValue) {
                                if (_.isArrayLike(currentSettingValue)) {
                                    currentSettingValue = currentSettingValue.join(',')
                                }

                                output[settingDef.modelKey] = _.findIndex(settingDef.options, (option) => {
                                    return typeof option.checkValue === 'function'
                                        ? option.checkValue(currentSettingValue, currentItemSettings)
                                        : option.value === currentSettingValue
                                })
                            }
                            else {
                                output[settingDef.modelKey] = _.findIndex(settingDef.options, (option) => {
                                    return typeof option.checkValue === 'function' ? option.checkValue(currentSettingValue, currentItemSettings) : option.whenNotSet
                                })
                            }

                            if (options.showSettingToggles) {
                                output[settingDef.modelKey] = undefined
                            }
                        })

                        return output
                    },
                    onBeforeSubmit(model) {
                        let valid = true

                        originalSettingsModel.items = []
                        originalSettingsModel.settingsToggledState = {}

                        _.forEach(model, (optionIndex, key) => {
                            let
                                settingDef

                            let selectorOptions

                            let selectedOption

                            let selectedValue

                            let valueIsTypeOfObject

                            let additionalKey

                            let additionalValue

                            let originalSettingsModelItem

                            let mergedNewSettingBeforeSaving

                            if (options.showSettingToggles) {
                                if (key.startsWith('set_')) {
                                    originalSettingsModel.settingsToggledState[key.substr(4)] = model[key]
                                }

                                if (!model['set_' + key]) {
                                    return
                                }
                            }

                            settingDef = _.find(selectorOptionsDefinitions, { modelKey: key })
                            selectorOptions = settingDef.options
                            selectedOption = selectorOptions ? selectorOptions[optionIndex] || {} : null

                            if (settingDef.settingKind in duplicateSettingKinds) {
                                originalSettingsModelItem = _.find(originalSettingsModel.items, { settingKind: settingDef.settingKind })
                                if (originalSettingsModelItem) {
                                    _.remove(originalSettingsModel.items, originalSettingsModelItem)
                                }
                                mergedNewSettingBeforeSaving = newSettingsBeforeSaving[settingDef.settingKind]
                                if (mergedNewSettingBeforeSaving) {
                                    if (settingDef.customControl) {
                                        selectedValue = _.assign(mergedNewSettingBeforeSaving, customControlsFormFieldData[key].value)
                                    }
                                    else if (typeof settingDef.setValue === 'function') {
                                        selectedValue = settingDef.setValue(mergedNewSettingBeforeSaving, model[settingDef.modelKey])
                                    }
                                    else {
                                        selectedValue = typeof selectedOption.setValue === 'function' ? selectedOption.setValue(mergedNewSettingBeforeSaving) : selectedOption.value
                                    }
                                }
                            }

                            if (!selectedValue) {
                                if (settingDef.customControl) {
                                    selectedValue = _.assign(currentItemSettings[settingDef.settingKind] || {}, customControlsFormFieldData[key].value)
                                }
                                else if (typeof settingDef.setValue === 'function') {
                                    selectedValue = settingDef.setValue(mergedNewSettingBeforeSaving, model[settingDef.modelKey])
                                }
                                else {
                                    selectedValue = typeof selectedOption.setValue === 'function' ? selectedOption.setValue(currentItemSettings[settingDef.settingKind]) : selectedOption.value
                                }
                            }

                            valueIsTypeOfObject = typeof selectedValue === 'object'

                            // If the value is an object then it shouldn't use 'whenNotSet'
                            if ((selectedOption && !selectedOption.whenNotSet) || ((settingDef.customControl || settingDef.type === 'date') && selectedValue) || mergedNewSettingBeforeSaving || (selectedOption && selectedOption.whenNotSet && valueIsTypeOfObject && selectedValue)) {
                                // TODO:
                                // Some values might be an array in the json response but when saved from here it should be joined string
                                // Example:
                                // if (key === "limitQuestionAnswerTypes") {
                                // 	selectedValue = selectedValue.split(",")
                                // }

                                if (valueIsTypeOfObject) {
                                    if (selectedOption && selectedOption.additionalWhenSelected) {
                                        additionalKey = key + '|' + optionIndex + '|additional'
                                        additionalValue = additionalFormFieldData[additionalKey].value
                                        // additionalValue = formSpec.schema.properties[additionalKey]["x-schema-form"].value;
                                        _.assign(selectedValue, additionalValue)
                                    }

                                    if (!selectedValue || _.isEmpty(selectedValue)) return // If selectedValue is falsy or an empty object then skip adding to the model (condition will be deleted in db)

                                    if (selectedOption && typeof selectedOption.validate === 'function' && selectedOption.validate(selectedValue) === false) {
                                        valid = false
                                    }

                                    newSettingsBeforeSaving[settingDef.settingKind] = selectedValue
                                    selectedValue = JSON.stringify(selectedValue)
                                }
                                else newSettingsBeforeSaving[settingDef.settingKind] = selectedValue

                                if (typeof selectedValue !== 'undefined') {
                                    if (settingDef.settingKind in duplicateSettingKinds) {
                                        originalSettingsModelItem = _.find(originalSettingsModel.items, { settingKind: settingDef.settingKind })
                                        if (originalSettingsModelItem) {
                                            originalSettingsModelItem.value = selectedValue
                                        }
                                        else originalSettingsModel.items.push({ settingKind: settingDef.settingKind, value: selectedValue })
                                    }
                                    else originalSettingsModel.items.push({ settingKind: settingDef.settingKind, value: selectedValue })
                                }
                            }

                        })

                        if (!valid) return false

                        // ObjectSettings not defined in the settings but that are still existing on the object are
                        // included on the modal unchanged.
                        _.forEach(currentItemSettings, (value, key) => {
                            if (_.includes(editableSettingKinds, key)) return

                            if (key == 'dataRelation') value = ''
                            else if (key === 'childrenSettings') return
                            else if (value instanceof Array) {
                                // These settings are stored like joined strings in db but are split into arrays when loaded.
                                // They must be joined here before being saved.
                                if (_.includes(['objectTypes', 'limitQuestionAnswerTypes', 'contextParentWfids', 'attachObjectTypes'], key)) value = value.join(',')
                            }

                            // Existing values MUST be stringified if they are objects otherwise they will be removed in db
                            originalSettingsModel.items.push({ settingKind: key, value: typeof value === 'object' ? JSON.stringify(value) : value })
                        })

                        if (options.onBeforeSubmit) {
                            options.onBeforeSubmit(originalSettingsModel)
                        }

                        return originalSettingsModel
                    },
                }

                formSpec.schema = {
                    type: 'object',
                    properties: {},
                    required: [],
                }

                _.forEach(selectorOptionsDefinitions, (settingDef, index) => {
                    // settingDef.realSettingKind = settingDef.settingKind;
                    // settingDef.settingKind = settingDef.settingKind.replace(/\./g, "|")

                    if (settingDef.settingKind in duplicateSettingKinds) {
                        // If the same setting is edited using multiple input then we use another key in the form so that it's unique
                        settingDef.modelKey = settingDef.settingKind + '_' + index
                    }
                    else settingDef.modelKey = settingDef.settingKind

                    formSpec.form.push(settingDef.modelKey)
                    if (settingDef.required !== false) {
                        formSpec.schema.required.push(settingDef.modelKey)
                    }

                    if (settingDef.options) {
                        formSpec.schema.properties[settingDef.modelKey] = {
                            title: settingDef.label,
                            type: 'integer',
                            'x-schema-form': {
                                type: 'select',
                                titleMap: _.map(settingDef.options, (item, index) => {
                                    return { value: index, name: item.name }
                                }),
                                // onChangeExtended: function () {
                                // 	console.log(arguments)
                                // },
                                condition: settingDef.condition ? 'vm.model[\'' + settingDef.condition.key + '\'] == ' + settingDef.condition.index : undefined,
                            },
                        }

                        _.each(settingDef.options, (option, index) => {
                            let
                                key

                            if (option.additionalWhenSelected) {
                                key = settingDef.modelKey + '|' + index + '|additional'

                                formSpec.schema.properties[settingDef.modelKey]['x-schema-form'].hasDependant = true
                                formSpec.schema.properties[settingDef.modelKey]['x-schema-form'].dependantTitle = option.additionalWhenSelected.display.label()

                                formSpec.form.push(key)

                                additionalFormFieldData[key] = { value: currentItemSettings[settingDef.settingKind] || {} }

                                formSpec.schema.properties[key] = {
                                    title: option.additionalWhenSelected.display.label(),
                                    type: 'string',
                                    'x-schema-form': {
                                        isDependant: true,
                                        dependencyKey: settingDef.modelKey,
                                        condition: 'vm.model[\'' + settingDef.modelKey + '\'] === ' + index,
                                        type: 'template',
                                        template: '<div>' + option.additionalWhenSelected.template + '</div>',
                                        value: additionalFormFieldData[key].value,
                                        onInit: option.additionalWhenSelected.onInit,
                                        onChanged(value) {
                                            additionalFormFieldData[key].value = value
                                        },
                                    },
                                }
                            }
                        })
                    }
                    else if (settingDef.type === 'date') {
                        formSpec.schema.properties[settingDef.modelKey] = {
                            title: settingDef.label,
                            placeholder: settingDef.placeholder,
                            type: 'string',
                            format: 'date',
                            key: settingDef.modelKey,
                            'x-schema-form': {
                                key: settingDef.modelKey,
                                condition: settingDef.condition ? 'vm.model[\'' + settingDef.condition.key + '\'] == ' + settingDef.condition.index : undefined,
                            },
                        }
                    }
                    else if (settingDef.customControl) {
                        customControlsFormFieldData[settingDef.modelKey] = { value: currentItemSettings[settingDef.settingKind] || {} }

                        formSpec.schema.properties[settingDef.modelKey] = {
                            title: settingDef.label,
                            type: 'string',
                            'x-schema-form': {
                                condition: settingDef.condition ? 'vm.model[\'' + settingDef.condition.key + '\'] == ' + settingDef.condition.index : undefined,
                                type: 'template',
                                template: '<div>' + settingDef.customControl.template + '</div>',
                                value: customControlsFormFieldData[settingDef.modelKey].value,
                                onInit: settingDef.customControl.onInit,
                                onChanged(value) {
                                    customControlsFormFieldData[settingDef.modelKey].value = value
                                },
                            },
                        }
                    }

                })
            }

            settingsModel = {
                id: null,
                type: 79,
                objectId: item.id,
                objectType: item.type,
                forOrganization,
                items: [],
            }

            if (options.showSettingToggles) {
                [...formSpec.form].forEach((key) => {
                    const propSettings = formSpec.schema.properties[key]['x-schema-form']

                    if (propSettings.isDependant) {
                        if (propSettings.dependencyKey) {
                            propSettings.condition += ' && vm.model[\'set_' + propSettings.dependencyKey + '\']'
                        }
                        return
                    }

                    formSpec.schema.properties['set_' + key] = {
                        title: $translate.instant('Change') + ': ' + formSpec.schema.properties[key].title + (propSettings.hasDependant ? ' / ' + propSettings.dependantTitle : ''),
                        type: 'boolean',
                        'x-schema-form': {
                            condition: propSettings.condition,
                        },
                    }

                    if (propSettings.condition) {
                        propSettings.condition += ' && vm.model[\'set_' + key + '\']'
                    }
                    else {
                        propSettings.condition = 'vm.model[\'set_' + key + '\']'
                    }

                    formSpec.form.splice(formSpec.form.indexOf(key), 0, 'set_' + key)
                })
            }

            edit(settingsModel, {
                action: options.action || 'multi.savesettings',
                customFormSpecification: formSpec,
                bypassAdapter: true,
                windowClass: 'modal-width-700',
            }).then((result) => {
                if (options.action) {
                    jqDf.resolve()
                    return
                }

                const actual = result.actual || result
                const useSettingsPropName = item.type === enums.objectType.dataRelation || item.type === enums.objectType.network

                if (result.bag) {
                    item[useSettingsPropName ? 'settingsBag' : 'conditionsBag'] = result.bag
                }

                if (useSettingsPropName) item.settings = actual
                else item.conditions = actual
                jqDf.resolve()
            })
            return jqDf.promise()
        }

        function editRequirement(options) {
            const
                item = options.item

            let requirementModel

            const forOrganizationId = options.forOrganizationId

            const selectorOptions = options.selectorOptions

            let formSpec

            let originalRequirementModel

            const jqDf = $.Deferred()

            if (selectorOptions) {
                formSpec = {
                    schema: {},
                    form: ['optionIndex'],
                    onBeforeRender(model) {
                        const presetAges = [365, 183, 90, 30]
                        // Existing requirement item is loaded from server in wfForm directive

                        originalRequirementModel = model

                        // Needed in editor() > dataOperationContext {} > saved(), where id and type is set on itemData
                        _.assign(requirementModel, model)


                        const isDeviationRuleSet = [ enums.requirementRule.withinDeviationFromSamePeriodLastYear,
                            enums.requirementRule.withinDeviationFromPreviousPeriod
                        ].includes(model.rule)

                        return {
                            optionIndex: _.findIndex(selectorOptions, { rule: model.rule, value: isDeviationRuleSet ? undefined : model.value }),
                            comment: model.comment,
                            presetMaxAge: model.maxAgeInDays ? (_.includes(presetAges, model.maxAgeInDays) ? model.maxAgeInDays.toString() : 'custom') : '',
                            customMaxAgeInDays: model.maxAgeInDays && !_.includes(presetAges, model.maxAgeInDays) ? model.maxAgeInDays.toString() : '',
                            deviationPercentage: isDeviationRuleSet ? model.value : undefined
                        }
                    },
                    onBeforeSubmit(model) {
                        const selectedOption = selectorOptions[model.optionIndex] || {}

                        originalRequirementModel.rule = selectedOption.rule
                        originalRequirementModel.value = selectedOption.value

                        if ([ enums.requirementRule.withinDeviationFromSamePeriodLastYear,
                            enums.requirementRule.withinDeviationFromPreviousPeriod ].includes(selectedOption.rule)
                        ) {
                            originalRequirementModel.value = model.deviationPercentage
                        }

                        originalRequirementModel.comment = model.comment
                        if (model.presetMaxAge === 'custom') {
                            if (!model.customMaxAgeInDays || model.customMaxAgeInDays === '0') originalRequirementModel.maxAgeInDays = null
                            else originalRequirementModel.maxAgeInDays = model.customMaxAgeInDays
                        }
                        else originalRequirementModel.maxAgeInDays = model.presetMaxAge

                        if (options.showSettingToggles) {
                            originalRequirementModel.settingsToggledState = {}
                            Object.keys(model).forEach((key) => {
                                if (key.startsWith('set_')) {
                                    originalRequirementModel.settingsToggledState[key.substr(4)] = model[key]
                                }
                            })
                        }
                        return originalRequirementModel
                    },
                }

                formSpec.schema = {
                    type: 'object',
                    properties: {
                        optionIndex: {
                            title: $translate.instant(options.useGoal ? 'Goal' : 'Requirement'),
                            type: 'integer',
                            'x-schema-form': {
                                type: 'select',
                                titleMap: _.map(selectorOptions, (item, index) => {
                                    return { value: index, name: item.name }
                                }),
                            },
                        },
                    },
                    required: ['optionIndex'],
                }
            }

            // if (forOrganizationId) {
            formSpec.form.push('comment')
            formSpec.schema.properties['comment'] = {
                title: $translate.instant('Comment'),
                type: 'string',
                'x-schema-form': {
                    type: 'textarea',
                },
            }
            // }

            if (item.type === enums.objectType.dataRelation && (item.childType === enums.objectType.question || item.childType === enums.objectType.structure)) {
                formSpec.form.push('presetMaxAge')
                formSpec.schema.properties['presetMaxAge'] = {
                    title: $translate.instant('ReportingPeriod'),
                    type: 'string',
                    'x-schema-form': {
                        type: 'select',
                        titleMap: [
                            { value: '', name: $translate.instant('modules.valueChain.requirements.None') },
                            { value: '365', name: $translate.instant('calendarFrequency.yearly') },
                            { value: '183', name: $translate.instant('calendarFrequency.halfYearly') },
                            { value: '90', name: $translate.instant('calendarFrequency.quarterly') },
                            { value: '30', name: $translate.instant('calendarFrequency.monthly') },
                            { value: 'custom', name: $translate.instant('calendarFrequency.custom') },
                        ],
                    },
                }

                formSpec.form.push('customMaxAgeInDays')
                formSpec.schema.properties['customMaxAgeInDays'] = {
                    // 'title': $translate.instant("MaxAgeInDays"),
                    type: 'string',
                    'x-schema-form': {
                        condition: 'vm.model.presetMaxAge === \'custom\'',
                        isDependant: true,
                        type: 'string',
                        title: $translate.instant('modules.valueChain.objectSettings.reportingPeriod.customMaxAgeInDays'),
                        maxLength: 4,
                        validationMessage: {
                            number: $translate.instant('validationMessages.notANumber'),
                        },
                        $validators: {
                            number(value) {
                                return !value || /^\d+$/.test(value)
                            },
                        },
                    },
                }
            }

            if (item.childType === enums.objectType.measure) {
                formSpec.form.push('deviationPercentage')
                formSpec.schema.properties['deviationPercentage'] = {
                    type: 'string',
                    'x-schema-form': {
                        required: true,
                        condition: `vm.model.optionIndex === ${_.findIndex(selectorOptions, { rule: enums.requirementRule.withinDeviationFromSamePeriodLastYear })} || vm.model.optionIndex === ${_.findIndex(selectorOptions, { rule: enums.requirementRule.withinDeviationFromPreviousPeriod })}`,
                        isDependant: true,
                        type: 'string',
                        title: $translate.instant('modules.valueChain.requirements.deviationPercentage'),
                        maxLength: 2,
                        validationMessage: {
                            deviationPercentageNumber: $translate.instant('validationMessages.notValidDeviationPercentage'),
                        },
                        $validators: {
                            deviationPercentageNumber(value) {
                                return !value || /^\d{1,3}$/.test(value)
                            },
                        },
                    },
                }
            }

            // Existing requirement item is loaded from server in wfForm directive
            requirementModel = {
                type: 60,
                objectId: item.id,
                objectType: item.type,
                derivedType: item.type == enums.objectType.dataRelation ? item.childType : null,
                organizationId: forOrganizationId,
            }

            if (options.showSettingToggles) {
                [...formSpec.form].forEach((key) => {
                    const propSettings = formSpec.schema.properties[key]['x-schema-form']

                    if (propSettings.isDependant) {
                        return
                    }

                    formSpec.schema.properties['set_' + key] = {
                        title: $translate.instant('Change') + ': ' + formSpec.schema.properties[key].title + (propSettings.hasDependant ? ' / ' + propSettings.dependantTitle : ''),
                        type: 'boolean',
                        'x-schema-form': {
                            condition: propSettings.condition,
                        },
                    }

                    if (propSettings.condition) {
                        propSettings.condition += ' && vm.model[\'set_' + key + '\']'
                    }
                    else {
                        propSettings.condition = 'vm.model[\'set_' + key + '\']'
                    }

                    formSpec.form.splice(formSpec.form.indexOf(key), 0, 'set_' + key)
                })
            }

            const promise = edit(requirementModel, {
                title: wfTranslate.instant(['Edit', { key: options.useGoal ? 'Goal' : 'Requirement', textTransform: 'lowercase' }]),
                action: options.action || 'multi.saveRequirements',
                customFormSpecification: formSpec,
                bypassAdapter: true,
            })

            promise.cancelled(() => {
                jqDf.resolve()
            })

            promise.then((updatedRequirement) => {
                const output = {}

                if (options.action) {
                    jqDf.resolve()
                    return
                }

                if (updatedRequirement.rule == 0 && updatedRequirement.value == null) {
                    if (updatedRequirement.wfid) wfObject.eject(updatedRequirement.wfid)

                    if (forOrganizationId) {
                        output.specific = null
                    }
                    else output.standard = null

                    // if (!forOrganizationId) {
                    // 	item.requirement = null;
                    // }
                    // else if (forOrganizationId && item.requirement && item.requirement.organizationId == forOrganizationId) // If existing requirement was org specific
                    // 	item.requirement = item.requirement.innerRequirement;
                }
                else {
                    wfObject.inject([updatedRequirement])
                    updatedRequirement = wfObject.get(updatedRequirement.wfid)

                    if (forOrganizationId) {
                        output.specific = updatedRequirement
                    }
                    else output.standard = updatedRequirement
                    // output.specific = updatedRequirement;
                    // if (forOrganizationId && item.requirement && item.requirement.organizationId == forOrganizationId) {
                    // 	// If existing requirement already was org specific
                    // 	updatedRequirement.innerRequirement = item.requirement.innerRequirement; // Set inner requirement to be the same on the updated requirement
                    // }
                    // else if (forOrganizationId && !item.requirement.organizationId) {
                    // 	// If existing requirement was not org specific
                    // 	updatedRequirement.innerRequirement = item.requirement // Set inner requirement on the updated requirement to be the old requirement
                    // }

                    // item.requirement = updatedRequirement;
                }

                jqDf.resolve(output)
            })

            const jqPromise = jqDf.promise()

            jqPromise.cancelled = promise.cancelled

            return jqPromise
        }

        function edit(item, options) {
            options = angular.extend({ action: 'multi.update', isCreate: false }, defaultSettings, options)

            return editor(item, options)
        }

        function create(item, options) {
            createWithPromise(item, options)
        }

        function createWithPromise(item, options) {
            options = angular.extend({ action: 'multi.create', isCreate: true }, defaultSettings, options)

            return editor(item, options)
        }

        function editor(item, options) {
            let savedTriggered = false
            const jqDf = $.Deferred()
            const promise = jqDf.promise()
            let dataOperationContext
            const modalInstance = $uibModal.open({
                animation: true,
                backdrop: 'static',
                templateUrl: 'scripts/wf/item/form.controller.html',
                controller: 'FormController',
                controllerAs: 'vm',
                windowClass: 'has-form' + (options.windowClass ? ' ' + options.windowClass : ''),
                resolve: {
                    dataOperationContext() {
                        return $timeout(() => {
                            dataOperationContext = {
                                item,
                                limitLanguages: options.limitLanguages,
                                requireAllLanguages: options.requireAllLanguages,
                                simplifyForm: options.simplifyForm,
                                topItem: options.topItem,
                                isCreate: options.isCreate,
                                title: options.title,
                                titleTranslate: options.titleTranslate,
                                description: options.description,
                                objectTypeWord: options.objectTypeWord,
                                submitCaption: options.submitCaption,
                                submitButtonCssClass: options.submitButtonCssClass,
                                replaceFormSpecification: options.replaceFormSpecification,
                                customFormSpecification: options.customFormSpecification,
                                modalDeferred: jqDf, // Will resolve in form.controller.js after wfForm directive is finished with saving extra stuff
                                onBeforeSubmitTriggered: options.onBeforeSubmitTriggered,
                                influence: options.influence,
                                intersectionSettings: options.intersectionSettings,
                                showSubmitButton: options.showSubmitButton,
                                showAttachInformation: options.showAttachInformation,
                                focus: options.focus,
                                wording: options.wording,
                                typeOptionsByProperty: options.typeOptionsByProperty,
                                formFieldTitlesByProperty: options.formFieldTitlesByProperty,
                                requiredFields: options.requiredFields,
                                saved(itemData) {
                                    const jqDf2 = $.Deferred()
                                    let savedPromise

                                    savedTriggered = true

                                    itemData.type = item.type
                                    itemData.id = item.id

                                    if (typeof options.action === 'function')
                                    {
                                        savedPromise = options.action(dataOps.prepareWfObject(itemData))
                                    }
                                    else if (options.bypassAdapter)
                                    {
                                        savedPromise = apiProxy(options.action, dataOps.prepareWfObject(itemData))
                                    }
                                    else
                                    {
                                        if (options.isCreate) savedPromise = dataOps.create(itemData, {
                                            influence: options.influence,
                                            networkId: options.networkId,
                                            contextParents: options.contextParents,
                                            thirdParty: options.thirdParty,
                                        })
                                        else
                                        {
                                            savedPromise = dataOps.update(item, itemData, { thirdParty: options.thirdParty })
                                        }
                                    }

                                    if (!savedPromise) jqDf2.reject()
                                    else {
                                        savedPromise.then((res) => {
                                            // var newPromise = jqDf.resolve(res);

                                            // if (typeof newPromise === 'undefined')
                                            // 	jqDf2.resolve(res);
                                            // else
                                            // 	newPromise.then(function () {
                                            // 		jqDf2.resolve(res);
                                            // 	});

                                            jqDf2.resolve(res) // .then is in form.controller.js > submit()
                                        })

                                        if (savedPromise.catch) {
                                            savedPromise.catch((res) => {
                                                jqDf2.reject(res) // .fail is in form.controller.js > submit()
                                                // console.info(arguments)
                                            })
                                        }
                                    }

                                    return jqDf2.promise()
                                },
                            }

                            setTimeout(() => {
                                _.assign(promise.formControl, dataOperationContext.formControl)
                            }, 300)

                            return dataOperationContext
                        })
                    },
                },
            })

            promise.modal = modalInstance

            // Adds posibility to add a "cancelled" handle to the promise, to be called when the form modal is closed by clicking "Cancel" button
            let callbackWhenCancelled
            const originalThen = promise.then
            promise.cancelled = function (callback) {
                callbackWhenCancelled = callback
                return promise
            }
            promise.then = function (callback) {
                return _.assign(originalThen(callback), { cancelled: promise.cancelled })
            }
            modalInstance.closed.then(() => {
                if (!savedTriggered && typeof callbackWhenCancelled === 'function') callbackWhenCancelled()
            })

            promise.formControl = {}

            return promise
        }

        function createWithRelation(options) {
            const
                jqDf = $.Deferred()

            let additionalDataRelationResults

            let modalPromise

            const i = 0

            options = angular.extend({
                initialValues: undefined,
                objectType: undefined,
                dataRelationOptions: {
                    kind: undefined,
                    virtual: false,
                    item1: undefined,
                    item2: undefined,
                    virtualItem1: undefined,
                    contextParentWfid: undefined,
                },
                additionalDataRelations: undefined,
                simplifyForm: false,
                compilerControl: null,
                influence: undefined,
                networkId: undefined,
                setSettings: undefined,
                intersectionSettings: undefined,
                requiredFields: undefined,
            }, options)

            delete options.contextParents
            modalPromise = createWithPromise(_.assign({ type: options.objectType }, options.initialValues), options)
            // console.log(options);
            modalPromise.then((newItem) => {
                if (options.setSettings) {
                    dataOps.saveSettings({
                        item: newItem,
                        settings: options.setSettings,
                    }).then(() => {
                        doCreateRelation()
                    })
                }
                else {
                    doCreateRelation()
                }

                function doCreateRelation() {
                    createRelation(newItem, options.dataRelationOptions, options.intersectionSettings).then((firstResult) => {
                        let additionalPromises
                        // console.log("First result", firstResult);

                        handleGlobalPostCreationMessages(newItem, firstResult)

                        if (options.additionalDataRelations && options.additionalDataRelations.length === 0) {
                            // console.log("No additional relations")
                            resolve(firstResult)
                        }
                        else {
                            additionalPromises = []
                            additionalDataRelationResults = []

                            // console.log("With additional relations")

                            _.each(options.additionalDataRelations, (drOptions) => {
                                additionalPromises.push(createRelation(newItem, drOptions, options.intersectionSettings))
                            })

                            $.when.apply(null, additionalPromises).then(() => {
                                // console.log("WHEN")
                                resolve(firstResult)
                            })
                        }

                    }, (errorResult) => {
                        handleGlobalPostCreationMessages(newItem, errorResult)
                        resolve(null)
                    })
                }
            })

            modalPromise.modal.closed.then(() => {
                if (!modalPromise.modal.valid) jqDf.resolve(false)
            })

            return jqDf.promise()

            function resolve(res) {
                // console.log("Resolving", res, additionalDataRelationResults);
                jqDf.resolve(res, additionalDataRelationResults)
                if (options.compilerControl && options.compilerControl.compile) options.compilerControl.compile()
            }

            function createRelation(item, dataRelationOptions, intersectionSettings) {
                const
                    innerJqDf = $.Deferred()

                const methodName = dataRelationOptions.virtual ? 'createVirtualSubItemRelation' : 'createSubItemRelation'

                let params

                if (dataRelationOptions.virtual) {
                    params = [dataRelationOptions.item1, item, dataRelationOptions.kind, dataRelationOptions.virtualItem1]
                }
                else {
                    params = [dataRelationOptions.item1, item, {
                        kind: dataRelationOptions.kind,
                        virtualItem: dataRelationOptions.virtualItem1,
                        contextParentWfid: dataRelationOptions.contextParentWfid || _.get(intersectionSettings, 'contextParents[0]'),
                    }]
                }

                dataOps[methodName]
                    .apply(null, params).then((res) => {
                        if (additionalDataRelationResults) additionalDataRelationResults.push(res)

                        // console.log(i++, res)
                        if (dataRelationOptions.alsoWrapInVirtual) {
                            if (!res.settings) res.settings = {} // Needed in hierarchical when the settings are not put on the relation when newly created

                            wfObject.inject(_.assign(_.clone(res), {
                                type: enums.objectType.virtualDataRelation,
                                id: 0,
                                wfid: '81-|' + res.wffid + '|' + res.wfcid,
                                originalRelationWfid: res.wfid,
                            }))
                        }

                        innerJqDf.resolve(res)
                    }, (res) => {
                        innerJqDf.reject(res)
                    })
                return innerJqDf.promise()
            }

            function handleGlobalPostCreationMessages(content, relation) {

                // When creating a user and giving it access to an organization
                if (content.type === enums.objectType.individual
					&& options.dataRelationOptions.kind === enums.subItemsKind.usersOnOrg
					&& options.dataRelationOptions.item1.type === enums.objectType.organization
					&& content.alreadyExisted
                ) {
                    if (relation.succeeded === false) {
                        alert($translate.instant('SomethingWentWrongDuringSaving'))
                    }
                    else {
                        $ngBootbox.customDialog({
                            title: $translate.instant('modules.users.existingUserAddedToOrganization.modalTitle'),
                            message: $translate.instant('modules.users.existingUserAddedToOrganization.modalMessage', {
                                name: content.name,
                                email: content.email,
                            }),
                            onEscape: true,
                            className: 'modal-width-500',
                            buttons: {
                                cancel: {
                                    label: $translate.instant('OK'),
                                    className: 'btn-primary',
                                },
                            },
                        })
                    }
                }
            }
        }

        function openInfluenceCreator(options) {
            let
                promise

            const model = {
                fulfillmentDueAt: moment().add(1, 'months').format('YYYY-MM-DD'),
                activatedAt: moment().format('YYYY-MM-DD'),
            }

            const formSpec = {
                schema: {
                    type: 'object',
                    properties: {
                        fulfillmentDueAt: { type: 'string', format: 'date', title: $translate.instant('modules.valueChain.influence.dueDateLabel') },
                        activatedAt: { type: 'string', format: 'date', title: $translate.instant('modules.valueChain.influence.activatedAtLabel') },
                    },
                    required: [],
                },
                form: [
                    {
                        key: 'fulfillmentDueAt',
                        format: 'yyyy-mm-dd',
                    },
                    {
                        key: 'activatedAt',
                        format: 'yyyy-mm-dd',
                    },
                ],
            }

            if (options.isInternal) {
                formSpec.schema.properties['userId'] = {
                    type: 'integer',
                }
                formSpec.schema.required.push('userId')
                // console.log(formSpec.form.unshift)
                formSpec.form.unshift({
                    key: 'userId',
                    type: 'select',
                    title: $translate.instant('User'),
                })

                // (function (element) {
                promise = dataOps.getObject({
                    objectId: 322825, // Contains the current organization's users
                    objectType: enums.objectType.dataRelation,
                }).then((colleaguesStructureDataRelation) => {
                    const
                        users = _.sortBy(colleaguesStructureDataRelation.childContent.childs, 'childContent.name')

                    const titleMap = []

                    _.forEach(users, (item) => {
                        const user = item.childContent
                        if (user.worldfavorUserId > 0) titleMap.push({ value: user.worldfavorUserId, name: user.name + (user.email.length && user.given_name && user.given_name.length ? ' <' + user.email + '>' : '') })
                    })

                    formSpec.form[0].titleMap = titleMap
                })
                // })(formSpec.form[0]);
            }

            if (promise) {
                promise.then(() => {
                    showForm()
                })
            }
            else {
                showForm()
            }

            function showForm() {
                edit(model, {
                    title: 'Create influence',
                    action(model) {
                        return $q((resolve, reject) => {
                            dataOps.createInfluence({
                                channelId: options.networkId || 0,
                                organization: { id: options.organizationId },
                                userId: model.userId,
                                isInternal: options.isInternal,
                                influenceModel: model,
                                allowDataRelations: true,
                                item: options.item,
                                comment: options.comment,
                            }).then((influence) => {
                                resolve(influence)
                            })
                        })
                    },
                    customFormSpecification: formSpec,
                })

            }
        }

        function createInfluence(options) {
            options = angular.extend({
                organization: undefined,
                structure: undefined,
                organizationInputMethod: 1,
            }, options)

            createWithPromise({ type: 13, organizationId: options.organization.id })
        }

        function openCreatorAndPicker(options) {
            const
                jqDf = $.Deferred()

            let modalInstance

            let promise

            options = angular.extend({
                size: 'sm',
                relationTarget: undefined,
                objectTypes: undefined,
                pick: true,
                create: true,
                sourceItem: undefined,
                sourceItemLoadSettings: undefined,
                sourceItemFirstLevelAsFilter: undefined,
                compilerControl: undefined,
                title: undefined,
                description: undefined,
                descriptionHtml: undefined,
                influence: undefined,
                networkId: undefined,
                noFormHeader: undefined,
                submitCaption: undefined,
                submitAndCloseCaption: undefined,
                closeCaption: undefined,
                templateId: undefined,
                relationBucket: undefined,
                sourceList: undefined,
                sourceLists: undefined,
                translations: undefined,
                hideListHeader: undefined,
                displayTopItem: undefined,
                showTopItemAboveTitle: undefined,
                emptyState: undefined,
                onEscape: true,
                backdrop: true,
                buttons: undefined,
                outerController: undefined,
                intersection: undefined,
                canCombineSourceListsFilter: undefined,
                createObjectOfType: undefined,
                simplifyForms: undefined,
                disableUntoggle: false,
                onToggleCallback: undefined,
                hideItem: undefined,
                customSettingsForObjectTypes: undefined,
                onContentCreated: undefined,
            }, options)

            openModal()

            function openModal() {
                modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'scripts/wf/picker/picker.controller.html',
                    controller: 'PickerController',
                    controllerAs: 'pickerVm',
                    windowClass: options.pick !== false ? 'picker-modal' : undefined,
                    size: options.size,
                    keyboard: options.onEscape,
                    backdrop: options.backdrop,
                    resolve: {
                        modalContext() {
                            const jqDf2 = $.Deferred()

                            jqDf.resolve()
                            jqDf2.resolve(options)

                            return jqDf2.promise()
                        },
                    },
                })
            }

            promise = jqDf.promise()
            promise.modal = modalInstance
            if (options.compilerControl && options.compilerControl.compile) {
                modalInstance.closed.then(() => {
                    // console.log("closed")
                    options.compilerControl.compile()
                })
            }
            promise.closed = function (callback) {
                modalInstance.closed.then((res) => {
                    callback(options.relationBucketResult)
                })
                return promise
            }

            return promise
        }

        function verifyItem(item, influence) {
            const
                jqDf = $.Deferred()

            let modalPromise

            const userFullname = $sanitize(wfAuth.getWorldfavorUser().name)

            const orgName = $sanitize(wfAuth.getOrganization().name)

            modalPromise = createWithPromise({ type: 67 }, {
                title: $translate.instant('Sign_Verb'),
                submitCaption: $translate.instant('Sign_Verb'),
                action(res) {
                    res.objectId = item.id
                    res.objectType = item.type
                    res.isVerified = true
                    screenLoader.show($translate.instant('modules.valueChain.influence.message_verifying'))
                    if (influence && !influence.isSigned) res.influenceId = influence.id

                    return apiProxy('utility.createVerification', dataOps.prepareWfObject(res))
                },
                customFormSpecification: {
                    form: [
                        {
                            type: 'template',
                            template: '<div class="pb20">{{form.certify}}</div><div class="well well-primary well-sm mb20"><div class="pb5"><i class="fa fa-user" style="color:#3498DB;width:16px;"></i><span class="text-bold">' + userFullname + '</span></div><div><i class="fa fa-building" style="color:#3498DB;width:16px"></i>' + orgName + '</div></div>',
                            certify: $translate.instant('modules.valueChain.influence.signingCertify'),
                        },
                        'city',
                        'verifierPhoneNumber',
                    ],
                },
            })

            modalPromise.then((verification) => {
                wfObject.inject(verification)
                jqDf.resolve(wfObject.get(verification.wfid))
            })

            modalPromise.modal.closed.then(() => {
                jqDf.resolve(false)
                // console.log("closed");
            })

            return jqDf.promise()
        }

        function openItem(options) {
            const
                jqDf = $.Deferred()

            let modalInstance

            let promise

            let modalScope

            if (options.templateUrl) {
                modalScope = options.scope
            }

            options = angular.extend({
                size: 'lg',
                animation: true,
                templateUrl: 'scripts/wf/item/modal.controller.html',
                controller: 'ModalController',
                controllerAs: 'vm',
                compilerControl: null,
                backdrop: true,
                keyboard: true,
            }, options)

            openModal()

            if (typeof options.onClosed === 'function') {
                modalInstance.closed.then(() => {
                    options.onClosed()
                })
            }
            if (options.compilerControl && options.compilerControl.compile) {
                modalInstance.closed.then(() => {
                    options.compilerControl.compile()
                })
            }

            promise = jqDf.promise()
            promise.modal = modalInstance

            return promise

            function openModal() {
                modalInstance = $uibModal.open({
                    size: options.size,
                    animation: options.animation,
                    templateUrl: options.templateUrl,
                    controller: options.controller,
                    controllerAs: options.controllerAs,
                    keyboard: options.keyboard,
                    backdrop: options.backdrop,
                    windowClass: options.windowClass,
                    scope: modalScope,
                    onLoaded: options.onLoaded,
                    resolve: {
                        modalContext() {
                            const
                                jqDf2 = $.Deferred()

                            let resolveCount = 0; let len

                            let resolveThenCallblack

                            if (options.resolve) // Resolve can either be a promise object or an array of promise objects
                            {
                                if (angular.isArray(options.resolve))
                                {
                                    len = options.resolve.length
                                    resolveThenCallblack = function () {
                                        resolveCount++

                                        if (resolveCount === len) load()
                                    }

                                    for (let i = 0; i < len; i++) {
                                        options.resolve[i].then(resolveThenCallblack)
                                    }
                                }
                                else if (options.resolve.then)
                                {
                                    options.resolve.then(() => {
                                        load()
                                    })
                                }
                                else if (typeof options.resolve === 'function')
                                {
                                    // options.resolve().then(function () {
                                    // });

                                    load()
                                }
                                else throw ('Resolve option is not a promise.')
                            }
                            else load()

                            return jqDf2.promise()

                            function load() {
                                if (options.meta)
                                {
                                    options.item = options.meta
                                    jqDf2.resolve(options)
                                    jqDf.resolve()
                                }
                                else if (options.getObject)
                                {
                                    dataOps.getObject({
                                        objectId: options.getObject.objectId || options.getObject.id,
                                        objectType: options.getObject.objectType || options.getObject.type,
                                        childrenLoadDepth: options.getObject.childrenLoadDepth,
                                        includeChildrensRelatedContent: options.getObject.includeChildrensRelatedContent,
                                        bypassCache: true,
                                    }).then((res) => {
                                        options.item = res
                                        jqDf2.resolve(options)
                                        jqDf.resolve()
                                    })
                                }
                                else if (options.itemByPath)
                                {
                                    dataOps.getObjectByPath(options.itemByPath.sourceItem, options.itemByPath.path).then((res) => {
                                        options.item = res
                                        jqDf.resolve()
                                        jqDf2.resolve(options)
                                        $timeout(() => {
                                        }, 500)
                                    })
                                }
                                else if (options.item)
                                {
                                    // console.info(options.item)
                                    if (options.item.wfid) // Already loaded
                                    {
                                        jqDf2.resolve(options)
                                        jqDf.resolve()
                                    }
                                    else if (typeof options.item === 'function') {
                                        options.item().then((res) => {
                                            options.item = res
                                            jqDf2.resolve(options)
                                            jqDf.resolve()
                                        })
                                    }
                                    else
                                    {
                                        dataOps.getObject({
                                            objectId: options.item.objectId || options.item.id,
                                            objectType: options.item.objectType || options.item.type,
                                            childrenLoadDepth: options.item.childrenLoadDepth,
                                            includeChildrensRelatedContent: options.item.includeChildrensRelatedContent,
                                        }).then((res) => {
                                            options.item = res
                                            jqDf2.resolve(options)
                                            jqDf.resolve()
                                        })
                                    }
                                }
                                else
                                {
                                    jqDf2.resolve(options)
                                    jqDf.resolve()
                                }
                            }
                        },
                    },
                })
            }
        }

        function openMailHistory(options) {
            let
                modalInstance

            options = angular.extend({
                // size: 'lg',
                animation: true,
                templateUrl: 'scripts/wf/mail/mailHistory.controller.html',
                controller: 'MailHistoryController',
                controllerAs: 'mhVm',
                compilerControl: null,
            }, options)

            openModal()

            function openModal()
            {
                modalInstance = $uibModal.open({
                    size: options.size,
                    animation: options.animation,
                    templateUrl: options.templateUrl,
                    controller: options.controller,
                    controllerAs: options.controllerAs,
                    windowClass: 'modal-width-800 modal-fit-window',
                    resolve: {
                        modalContext() {
                            const
                                jqDf = $.Deferred()

                            const resolveCount = 0; let len

                            let resolveThenCallblack

                            jqDf.resolve(options)

                            return jqDf.promise()
                        },
                    },
                })
            }
        }

        function addTo(item, excludeItemWfidsFromPicker) {
            // return openCreatorAndPicker({
            // 	hideListHeader: true,
            // 	compilerControl: null, //vm.context.itemCompilers[item.wfid],
            // 	title: $translate.instant('Add'),
            // 	create: false,
            // 	sourceItem: '73-347315',
            // 	relationTarget: { item: item, kind: enums.subItemsKind.parentsByUser }
            // });

            return openCreatorAndPicker({
                hideListHeader: true,
                compilerControl: null, //vm.context.itemCompilers[item.wfid],
                title: $translate.instant('AddTo'),
                create: false,
                // sourceItem: '73-347315',
                sourceLists: [
                    {
                        items() {
                            const self = this
                            return $q((resolve, reject) => {
                                dataOps.getObject('71-11966',  {
                                    objectType: 71,
                                    objectId: 11966,
                                    childrenLoadDepth: 0,
                                }).then((res) => {
                                    const output = dataQuery.makeItemComposites(res.childs)
                                    self.title = res.title

                                    // console.log(self.title, _.map(output, function (item) {
                                    // 	return item.content.title
                                    // }), output);

                                    resolve(output)
                                })
                            })
                        },
                    },
                    {
                        hideImages: true,
                        items() {
                            const self = this
                            const alreadyAddedInList = {}

                            return $q((resolve, reject) => {
                                dataOps.getObject('71-203',  {
                                    objectType: 71,
                                    objectId: 203,
                                    childrenLoadDepth: 1,
                                }).then((res) => {
                                    let
                                        output

                                    const promises = []

                                    _.each(res.childs, (dataRelation) => {
                                        promises.push(dataOps.getObject({
                                            objectId: dataRelation.childId,
                                            objectType: dataRelation.childType,
                                            childrenLoadDepth: 10,
                                            getterConditions: {
                                                contextVariable1: 'onlyLatestAnswers',
                                                applyIntersectionIfPossible: true,
                                            },
                                        }))
                                    })

                                    $q.all(promises).then(() => {
                                        output = dataQuery.getHierarchyAsList(res, [enums.objectType.structure, enums.objectType.question, enums.objectType.measure], {
                                            asItemComposites: true,
                                            filter(theItem) {
                                                if (alreadyAddedInList[theItem.wfid] || item.wfid === theItem.wfid) return false
                                                else alreadyAddedInList[theItem.wfid] = true

                                                // return !item.content.ancestorId && item.dataRelation.childType === enums.objectType.structure
                                                // ;

                                                let predicate = !theItem.content.ancestorId && theItem.dataRelation.childType === enums.objectType.structure

                                                if (excludeItemWfidsFromPicker) predicate = predicate && !_.includes(excludeItemWfidsFromPicker, theItem.wfid)

                                                return predicate
                                            },
                                        })
                                        self.title = res.title
                                        resolve(output)
                                    })
                                })
                            })
                        },
                    },
                ],
                relationTarget: { item, kind: enums.subItemsKind.parentsByUser },
            })
        }

        function sendTo(item) {
            return openCreatorAndPicker({
                showTopItemAboveTitle: true,
                hideListHeader: false,
                hideFilters: false,
                translations: {
                    addWord: $translate.instant('Send'),
                    toWord: $translate.instant('To'),
                    filterButton_all: $translate.instant('AllUsers'),
                    filterButton_selected: $translate.instant('Sent'),
                },
                compilerControl: null, //vm.context.itemCompilers[item.wfid],
                title: $translate.instant('Assign'),
                create: false,
                objectTypes: [enums.objectType.individual],
                relationTarget: { item, kind: enums.subItemsKind.parentsByUser },
            })
        }

        function editVisibility(item) {
            return openCreatorAndPicker({
                title: $translate.instant('SetVisibility'),
                showTopItemAboveTitle: true,
                relationTarget: {
                    item,
                    kind: enums.subItemsKind.parentsByUser,

                    settingsByItemType: {
                        52: {
                            kind: enums.subItemsKind.visible,
                            prependItem() {
                                let publicNetwork = wfObject.get('52-1')
                                if (!publicNetwork) {
                                    publicNetwork = wfObject.inject({
                                        id: 1,
                                        type: 52,
                                        wfid: '52-1',
                                        title: $translate.instant('PublicInformation'),
                                    })
                                }
                                return {
                                    type: 81,
                                    wfid: '81-|' + '71-11008' + '|' + publicNetwork.wfid,
                                    parentType: 71,
                                    parentData1: null,
                                    childId: item.childId,
                                    childType: 52,
                                    wffid: '71-11009',
                                    wfcid: publicNetwork.wfid,
                                    childContent: publicNetwork,
                                    order: 0,
                                }
                            },
                        },
                    },
                },
                objectTypes: [enums.objectType.network], //, enums.objectType.individual ],
                create: false,
                // sourceItem: res,
                // templateId: 65
            })
        }

        function startOrdering(catalogueName) {
            $uibModal.open({
                animation: true,
                onEscape: false,
                backdrop: 'static',
                keyboard: false,
                size: 'width-1000',
                windowClass: 'modal-fit-window has-form',
                templateUrl: 'scripts/wf/eCommerce/ordering.template.html',
                controller: 'orderingController',
                controllerAs: 'orderVm',
                resolve: {
                    modalContext() {
                        return $q((resolve, reject) => {
                            resolve({
                                catalogueName,
                            })
                        })
                    },
                },
            })
        }

        function previewMail(parameters) {
            $uibModal.open({
                animation: true,
                templateUrl: 'scripts/wf/mail/mailPreview.controller.html',
                controller: 'MailPreviewController',
                controllerAs: 'vm',

                size: 'width-800',
                resolve: {
                    modalContext() {
                        return $q((resolve, reject) => {
                            resolve(parameters)
                        })
                    },
                },
            })
        }

        //------------------------------------------------------------------------
        //------------------------------------------------------------------------

        function alert() {
            let additionalClass = ' icon-horizontal'

            let positionClass = 'pull-left'

            var options = {
                headerText: '',
                title: '',
                message: '',
                class: 'modal-alert modal-info',
                icon: 'fa fa-info',
                type: 'info',
                vertical: false,
                onClose: undefined,
                buttons: {
                    ok: {
                        label: 'OK',
                        className: 'btn-hollow action',
                        callback() {
                            if (typeof options.onClose === 'function') options.onClose()
                        },
                    },
                },
                types: {
                    info: {
                        icon: 'fa fa-info',
                        class: 'modal-alert modal-info',
                    },
                    success: {
                        icon: 'fa fa-check',
                        class: 'modal-alert modal-success',

                    },
                    warning: {
                        icon: 'fa fa-exclamation-triangle',
                        class: 'modal-alert modal-warning',
                    },
                    danger: {
                        icon: 'fa fa-trash',
                        class: 'modal-alert modal-danger',
                    },
                },

                //ng-bootbox configs
                onEscape: true,
                show: true,
                backdrop: true,
                closeButton: true,
                animate: true,
            }

            if (typeof arguments[0] === 'string') {
                options.message = arguments[0]
                options.class = options.class.concat(additionalClass)
            }
            else if (typeof arguments[0] === 'object') {
                const newOptions = arguments[0]

                if (newOptions.type) {
                    const newType = options.types[newOptions.type]
                    if (newOptions.icon) {
                        newType.icon = newOptions.icon
                    }
                    _.assign(newOptions, newType)
                }

                if (newOptions.vertical) {
                    additionalClass = ' icon-vertical'
                    positionClass = 'pull-none'
                }

                if (newOptions.class) newOptions.class = newOptions.class.concat(additionalClass)
                else newOptions.class = options.class.concat(additionalClass)

                if (newOptions.modalCssClass) newOptions.class += ` ${newOptions.modalCssClass}`

                _.assign(options, newOptions)
            }

            const message = '<div class="content">' +
							'<div class="icon ' + positionClass + '">' +
								'<i class="' + options.icon + '" aria-hidden="true"></i>' +
							'</div>' +
							'<div class="text pull-none">' +
								'<p class="title">' + options.title + '</p>' +
								'<p class="message">' + options.message + '</p>' +
							'</div>' +
						'</div>'

            const bootboxOptions = {
                title: options.headerText,
                message,
                className: options.class,
                icon: options.icon,
                onEscape: options.onEscape,
                show: options.show,
                backdrop: options.backdrop,
                closeButton: options.closeButton,
                animate: options.animate,
                buttons: options.buttons,
            }

            $ngBootbox.customDialog(bootboxOptions)
        }

        function openGuidance() {
            const
                options = {
                    title: 'No title set for this modal',
                    message: '',
                }

            let templateHtml = ''

            if (typeof arguments[0] === 'object') {
                options.title = arguments[0].title
                options.message = arguments[0].message
            }
            else {
                console.error('Please make sure that you pass an object to openGuidance() with the following properties {title: \'My title\', message: \'My message, can be HTML\'} ')
                return
            }

            templateHtml =
				'<div class="modal-header">' +
					'<button type="button" class="bootbox-close-button close" wf-click="$close()" aria-hidden="true">×</button>' +
					'<h3 class="modal-title">' + options.title + '</h3>' +
				'</div>' +
				'<div class="modal-body">' +
					'<div class="message">' + options.message + '</div>' +
				'</div>' +
				'<div class="modal-footer">' +
					'<button class="btn wf-btn-link" wf-click="$close()">' + $translate.instant('Close') + '</button>' +
				'</div>'

            $uibModal.open({
                animation: true,
                size: 'width-700',
                windowClass: 'guidance',
                backdrop: 'static',
                template: templateHtml,
            })
        }

        function openMeasureAnswerCreator(measureDataRelation, measure, options) {
            const {
                fulfillmentResult,
            } = options || {}

            const requiredPeriod = fulfillmentResult && fulfillmentResult.requiredMeasurePeriod

            return $q((resolve, reject) => {
                const
                    periodRangesByIndex = {}

                let periodSettingsObjectWfid

                let measurePeriodSettings

                let formSpecification

                if (measureDataRelation) {
                    measurePeriodSettings = _.get(measureDataRelation.originalDataRelation, 'settings.measurePeriodSettings')
                    if (measurePeriodSettings) periodSettingsObjectWfid = measureDataRelation.originalDataRelation.wfid
                    else {
                        measurePeriodSettings = _.get(measureDataRelation, 'settings.measurePeriodSettings')
                        if (measurePeriodSettings) periodSettingsObjectWfid = measureDataRelation.wfid
                    }
                }

                const initialValues = {
                    unitId: measure.unitId,
                    frequency: _.get(measurePeriodSettings, 'frequency') || enums.calendarFrequency.yearly,
                    periodSettingsObjectWfid: periodSettingsObjectWfid || null,
                    measureId: measure.id,
                    measureAnswerFormId: 'measureAnswerForm_' + _.uniqueId(),
                }

                if (options && options.additionalPropertiesForInitialValues) _.assign(initialValues, options.additionalPropertiesForInitialValues)

                wfMeasureService.getMeasureAnswerFormSpecification({
                    measure,
                    measureAnswerFormId: initialValues.measureAnswerFormId,
                    periodSettings: measurePeriodSettings || { frequency: enums.calendarFrequency.yearly },
                    periodRangesByIndex,

                }).then((res) => {
                    const lockedPeriod = _.get(measurePeriodSettings, 'lockPeriod')
                    let periodIndex = null

                    if (lockedPeriod) {
                        const startDate = lockedPeriod.split('|')[0]
                        periodIndex = _.findKey(periodRangesByIndex, { period: startDate })
                    }
                    else if (requiredPeriod) {
                        periodIndex = _.findKey(periodRangesByIndex, { period: requiredPeriod.start })
                    }

                    if (periodIndex) {
                        _.assign(initialValues, {
                            periodSelection: periodIndex,
                        })
                    }
                    formSpecification = res

                    createWithRelation({
                        objectType: 25,
                        topItem: measure,
                        initialValues,
                        dataRelationOptions: { kind: 7, item1: measure },
                        customFormSpecification: formSpecification,

                        influence: options ? options.influence : undefined,
                        networkId: options ? options.networkId : undefined,
                        contextParents: options ? options.contextParentWfids : undefined,
                        intersectionSettings: options ? options.intersectionSettings : undefined,
                        showAttachInformation: options ? options.showAttachInformation : undefined,

                        onBeforeSubmitTriggered(event) {
                            const
                                model = event.getModel()

                            let periodRange

                            if (!model.periodSelection) {
                                event.cancelSubmit()
                                return
                            }

                            periodRange = periodRangesByIndex[model.periodSelection]

                            model.period = periodRange.period
                            model.periodEnd = periodRange.periodEnd
                            model.intervalNameSpecification = periodRange.nameSpecification || null

                            event.setModel(model)

                            event.continueSubmit()
                        },
                    }).then((createdItem) => {
                        if (createdItem) {
                            createdItem.createdAt = moment().format()
                            resolve(createdItem)
                        }
                        else resolve(false)
                    })
                })
            })
        }
    }
})()
