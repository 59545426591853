import invert from 'lodash/invert'

export const objectType = {
    _notAvailable: -1,
    worldfavor: 99,
    profile: 1,
    initiative: 2,
    contribution: 3,
    participation: 4,
    influenceSphere: 5,
    contactPerson: 6,
    stakeholders: 7,
    idea: 8,
    qmod_topCategory: 9,
    qmod_subCategory: 10,
    question: 11,
    principlesOfSR: 12,
    influence: 13,
    solution: 14,
    orgActivity: 15,
    flowStatement: 16,
    qmodAnswerClaim: 17,
    orgDocument: 18,
    guide: 19,
    levUtb: 20,
    measure: 21,
    customTag: 22,
    g4_group: 23,
    g4_groupObject: 24,
    measureAnswer: 25,
    g4_reserved4: 26,
    g4_reserved5: 27,
    g4_reserved6: 28,
    g4_reserved7: 29,
    g4_reserved8: 30,
    questionAnswer: 31,
    embed_widget: 32,
    embed_customCSS: 33,
    visibilityPackage: 34,
    layer: 35,
    layerItem: 36,
    layer_reservered3: 37,
    layer_reservered4: 38,
    layer_reservered5: 39,
    layer_reservered6: 40,
    layer_reservered7: 41,
    layer_reservered8: 42,
    layer_reservered9: 43,
    statement: 44,
    userInfo: 45,
    condition: 46,
    tagCollection: 48,
    multipleContent: 49,
    link: 50,
    page: 51,
    network: 52,
    fulfillment: 53,
    menuItem: 54,
    image: 55,
    addon: 56,
    workTask: 57,
    location: 58,
    notification: 59,
    requirement: 60,
    visibilityTag: 61,
    endpointPermission: 62,
    menuPermission: 63,
    mailTemplate: 64,
    orgInputMethod: 65,
    internalComment: 66,
    verification: 67,
    organizationClaim: 68,
    mailOutbound: 69,
    logEvent: 70,
    structure: 71,
    multilingual: 72,
    dataRelation: 73,
    website: 74,
    country: 75,
    layoutConfig: 76,
    settingKind: 77,
    settingsBundle: 78,
    objectSetting: 79,
    virtualDataRelation: 81,
    questionAnswerType: 83,
    unit: 84,
    account: 87,
    loginSession: 88,
    embed: 90,
    sustainabilityRoom: 91,
    invoice: 92,
    widget: 93,
    mailSettings: 94,
    standards: 95,
    publicInfluenceRoom: 96,
    accessTag: 97,
    parameter: 98,
    parameterValue: 99,
    individual: 100,
    organization: 101,
    _logEntry: 102,
    originalObject: 103,
    worldfavorProduct: 104,
    billingInformation: 105,
    certificate: 106,
    productionSite: 107,
    historicDataRelation: 108,
    analyzeRequirement: 109,
    analyzeJob: 110,
    analyzeResult: 111,
    person: 112,
    supplier: 113,
    holding: 114,
    signupOrganization: 115,
    finding: 116,
    findingStepAnswer: 117,
    relativeMeasure: 118,
    relativeMeasureStep: 119,
    productService: 120,
    jobSpecification: 122,
    jobInvocation: 123,
    publicProfile: 124,
    dateItem: 125,
    thirdPartyPermission: 126,
    calculationSchedule: 127,
    levUtb_user: 301,
    levUtb_mail: 302,
    levUtb_eduStudent: 303,
}

export const ObjectType = objectType

export const eventType = {
    none: 0,
    like: 1,
    join: 2,
    add: 3,
    share: 4,
    update: 5,
    copy: 6,
    invite: 7,
    done: 8,
    facebookInvite: 9,
    create: 10,
    comment: 11,
    important: 12,
    answer: 13,
    helpPrioritize: 14,
    respectPrinciples: 15,
    claim: 16,
    inspire: 17,
    inviteRequest: 18,
    praise: 19,
    facebookLike: 20,
    suggest: 21,
    logIn: 22,
    publish: 23,
    finished: 24,
    send: 25,
    delete: 26,
    invalidate: 27,
    fulfill: 28,
    unfulfill: 29,
    sign: 30,
    collab_1_Adopt: 1001,
}

export const subItemsKind = {
    notDefined: 0,
    children: 1,
    parents: 2,
    verifications: 3,
    relatedContent: 4,
    relatedContentByUser: 5,
    verifies: 6,
    childrenByUser: 7,
    parentsByUser: 8,
    usersOnOrg: 9,
    visible: 10,
    anscestor: 11,
    relatedParents: 12,
    relatedParentsByUser: 13,
    contextChildren: 14,
    contextParents: 15,
    linkageTo: 16,        // Linkage between standards/guidelines according to offical linkage documents. Child-like, by the system, type 4
    linkageFrom: 17,      // Linkage between standards/guidelines according to offical linkage documents. Parent-like, by the system, type 4
    linkageChildren: 18,  // Aggregated linkage between standards/guidelines derived from offical linkage documents. Child-like, by the system, type 5
    linkageParents: 19,   // Aggregated linkage between standards/guidelines derived from offical linkage documents. Parent-like, by the system, type 5
}

export const SubItemsKind = subItemsKind

export const requirementRule = {
    notDefined: 0,
    noValue: 1,
    anyValue: 2,
    specificValue: 3,
    minValue: 4,
    maxValue: 5,
    span: 6,
    specificValues: 7,
    manual: 8,
    allSubItemsFulfilled: 9,
    preferredValue: 10,
    preferredValueWithRelatedContent: 11,
    anyValueWithRelatedContent: 12,
    specificValueWithRelatedContent: 13,
    inPeriod: 14,
    inPeriodWithRelatedContent: 15,
    inPeriodWithRelatedContentExceptIfNA: 16, // Any answer in current or previous period (if not "N/A" more information is required)
    anyValueWithRelatedContentExceptIfNA: 17, // Any answer in a specific period (if not "N/A" more information is required)
    inPeriodWithRelatedContentIfNA: 18, // Any answer in current or previous period (if "N/A" more information is required)
    emptyRequirement: 19,
    withinDeviationFromSamePeriodLastYear: 20,
    withinDeviationFromPreviousPeriod: 21
}

export const uiMode = {
    view: 1,
    work: 2, // For reporting
    admin: 3,
}

export const mailPurpose = {
    notificationMail: 1,
    valueChainInvitation: 2,
    valueChainReminder: 3,
    colleagueInvitation: 4,
}

export const valueChainMailTaskStatusMessagePreset = {
    noPreset: 0,
    timeAgoInformation: 1,
    timeLeftWarning: 2,
    sameDayWarning: 3,
    overdueWarning: 4,
}

export const dataFormat = {
    multiline: 1,
    image: 2,
    location: 3,
    country: 4,
}

export const mailStatus = {
    notSent: -1,
    sent: 0,
    queued: 1,
    rejected: 2,
    invalid: 3,
    scheduled: 4,
    bounced: 5,
    softBounced: 6,
    hardBounced: 7,
    other: 8,
    sendFailed: 9,
}

export const fulfillmentState = {
    unfulfilled: 0,
    fulfilled: 1,
    assessmentNeeded: 2,
    reportingNeeded: 3,
    expired: 4,
    certificateExpired: 5,
}

export const mathOperation = {
    addition: 0,
    subtraction: 1,
    multiplication: 2,
    division: 3,
    replace: 4,
}

export const missingSourceValuePolicy = {
    invalidatePeriod: 0,
    useZeroAndContinue: 1,
    ignoreStepAndContinue: 2,
    useCustomValueAndContinue: 3,
    useNextStep: 4,
}

export const dataSource = {
    reportedData: 0,
    internalData: 1,
    constantData: 2,
}

export const calendarFrequency = {
    yearly: 1,
    halfYearly: 2,
    quarterly: 3,
    monthly: 4,
    custom: 5,
}

export const culture = {
    sv_SE: 'sv-SE',
    en_US: 'en-US',
}

export const organizationType = {
    visible: 0,
    hidden: 1,
    temporary: 2,
}

export const findingStepAnswerState = {
    answerNeeded: 0,
    answerApproved: 1,
    answerDenied: 2,
    answerUpdated: 3,
}

export const findingSeverity = {
    observation: 0,
    minor: 1,
    major: 2,
    critical: 3,
    zeroTolerance: 4,
}

export const quantity = {
    amount: 1,        // Arbitrary amount
    length: 2,        // Example: meter, kilometer
    mass: 3,          // Example: kilogram, tones
    volume: 4,        // Example: cubic meter, liter
    energy: 5,        // Example: joule, watt, kilowatt hour
    time: 6,          // Example: hours
    money: 7,         // Example: usd, eur, sek
    fraction: 8,      // Example: %, ‰, ppm
    area: 9,          // Example: square meters
    temperature: 10,  // Example: celcius, fahrenheit
    division: 11,     // Example: KWh/m²
}

export const analyzeResultTask = {
    notDefined: 0,
    writeLocalLabel: 1,
    writeGlobalLabel: 2,
    writeLocalAndGlobalLabel: 3,
    categorizeTargetOrganization: 4,
    uncategorizeTargetOrganization: 5,
    createObject: 6,
    createInfluences: 7,
    deleteInfluences: 8,
}

export const ids = {
    root: {
        solutionPackagingRoot: 19882,
        transparency: 19883,
        sustManagement: 19884,
        sustSourcing: 19885,
        sustSourcing2: 22087,
        sustSourcing3: 22088,
        sustSourcing4: 22089,
        sustSourcing5: 25901,
        sustLending: 19886,
        sustInvestments: 25870,
    },
    common: {
        customFrameworks: 203,  // All custom frameworks created by an organization are put here as childrenByUser
        isoStandards: 13546,    // All accessible ISO standards
        guidelines: 13547,      // All guidelines
        standardPackagesPicker_MgmtAndInvestments: 81994,
        standardPackagesPicker_Sourcing: 81995,

        standardAnalyzePackagesPicker_MgmtAndInvestments: 82001,
        standardAnalyzePackagesPicker_Sourcing: 82002,

        standardDashboardsPicker_Environmental: 80246,
        standardDashboardsPicker_Social: 80247,
        standardDashboardsPicker_Governance: 80248,
        standardDashboardsPicker_Investments: 81883,
        standardDashboardsPicker_Sourcing: 81993,
        standardDashboardsPicker_ESRS: 101160, // European Sustainability Reporting Standards

        standardInternalPackagesPicker: 80226,

        standardCategoriesPicker_Sourcing: 82098,
    },
    scopeDashboard: {
        privateScope: 19238,          // Private scope / Scope for Sustainability Management
        publicScope: 19237,           // Public scope / Scope for Public Profile
        sustSourcingScope: 19931,     // Sustainable Sourcing Scope
        sustSourcingScope2: 22099,    // Sustainable Sourcing2 Scope
        sustSourcingScope3: 22100,    // Sustainable Sourcing3 Scope
        sustSourcingScope4: 22101,    // Sustainable Sourcing4 Scope
        sustSourcingScope5: 25900,    // Sustainable Sourcing5 Scope
        sustLendingScope: 19932,      // Sustainable Lending Scope
        sustInvestmentsScope: 25869,  // Sustainable Investments Scope

        privatePermissions: 19892,
        sustSourcingPermissions: 19914,
        sustSourcing2Permissions: 22090,
        sustSourcing3Permissions: 22093,
        sustSourcing4Permissions: 22096,
        sustSourcing5Permissions: 25902,
        sustInvestmentsPermissions: 25871,

        viewsForPrivateScope: 19937,          // Views for Private Scope / Scope for Sustainability Management
        viewsForPublicScope: 19936,           // Views for Public Scope / Scope for Public Profile
        viewsForSustSourcingScope: 19935,     // Views for Sustainable Sourcing Scope
        viewsForSustSourcingScope2: 22102,    // Views for Sustainable Sourcing2 Scope
        viewsForSustSourcingScope3: 22103,    // Views for Sustainable Sourcing3 Scope
        viewsForSustSourcingScope4: 22104,    // Views for Sustainable Sourcing4 Scope
        viewsForSustSourcingScope5: 25899,    // Views for Sustainable Sourcing5 Scope
        viewsForSustLendingScope: 19934,      // Views for Sustainable Lending Scope
        viewsForSustInvestmentsScope: 25868,  // Views for Sustainable Investments Scope
    },
    objectTypes: {

    },
}

export const objectTypeName = invert(objectType)

export const subItemsKindName = invert(subItemsKind)

export const packageType = {
    requirementPackage: 0,
    analyzePackage: 1,
}

export const daysOfWeek = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
}

export const objectSettingKind = {
    limitQuestionAnswerTypes: 110,
    objectTypes: 16,
    measurePeriodSettings: 149,
}
