export default {
    Ok: 'OK',
    Link_Description: '',
    Menu_CollaborationSystems: 'System för samarbete',
    MarkAsFulfilled: 'Markera som uppfylld',
    Website: 'Webbsida',
    PhoneNumber: 'Telefonnummer',
    Menu_StandardsFilters: 'Standarder',
    Menu_Publish: 'Publicera',
    NotActivated: 'Inte aktiverad',
    Menu_Home: 'Hem',
    Menu_Apps: 'Appar',
    Activated: 'Aktiverad',
    Not_Activated: 'Ej aktiverad',
    MenuItems: 'Menyval',
    Fulfillment_Description: '',
    Answer_Verb_Short: 'Svar',
    Organization: 'Organisation',
    Menu_Administrate: 'Administrera',
    Addons: 'Tillägg',
    EndpointPermissions: 'Endpoint-behörigheter',
    MailTemplates: 'Emailmallar',
    CanNotBeVerified: 'Kan ej verifieras',
    NotRelevant: 'Ej relevant',
    Delete: 'Radera',
    InternalComment: 'Intern kommentar',
    Method: 'Metod',
    Menu_InternalReportingRoom: 'Internt rapporteringsrum',
    Cancel: 'Avbryt',
    MeasureAnswer: 'Mätdata',
    Change: 'Ändra',
    Create: 'Skapa',
    CreatedAt: 'Skapad',
    WorkTasks: 'Arbetsuppgifter',
    ContactUs: 'Kontakta oss',
    ContactPerson: 'Kontaktperson',
    ObjectType: 'Objekttyp',
    Invite: 'Bjud in',
    Images: 'Bilder',
    Object: 'Objekt',
    ObjectID: 'Objekt ID',
    LogOut: 'Logga ut',
    Verification: 'Verifiering',
    Update: 'Uppdatera',
    Upload: 'Ladda upp',
    Influence_Noun: 'Informationsförfrågan',
    Organizations: 'Organisationer',
    Statements: 'Kommentarer',
    Generic: 'Generiskt',
    OrgNumber: 'Organisationsnummer',
    OrgNumber_short: 'Org.nummer',
    Calculating: 'Beräknar',
    Menu_Filters: 'Publik rapportering',
    GreatWork: 'Bra jobbat',
    Verified: 'Verifierat',
    NotFound: 'Hittades inte',
    InternalComment_Description: 'Ett kommentar i frågan från en representant inom organisationen',
    SignOut: 'Logga ut',
    Activity_Description: '',
    Select_Ellipsis: 'Välj...',
    Measure_Description: '',
    PrinciplesOfISO26k: 'Principerna i ISO 26000',
    Remove: 'Ta bort',
    NotFulfilled: 'Uppfylls inte',
    FilterContent_Noun_IA: 'Ett filterinnehåll',
    EndpointPermission: 'Endpoint-behörighet',
    Network: 'Nätverk',
    Networks: 'Nätverk',
    Network_IA: 'Ett nätverk',
    Network_DA: 'Nätverket',
    Search: 'Sök',
    Select: 'Välj',
    Public: 'Publika',
    Menu_DataFilters: 'Datatyper',
    SignIn: 'Logga in',
    Question_IA: 'En fråga',
    Question_DA: 'Frågan',
    ClosedNetwork: 'Stängt nätverk',
    Position: 'Befattning',
    SearchOrganizations: 'Sök organisationer',
    Menu_PluginSolutions: 'Plugin solutions',
    IUnderstand: 'Jag förstår',
    New_Neutrum: 'Nytt',
    Conditions: 'Villkor',
    Filter_Noun_IA: 'Ett filter',
    RequestHistory: 'Skickade informationsförfrågningar',
    SelectAccessibleOrganization: 'Välj organisation',
    Menu_Colleagues: 'Kollegor',
    Content: 'Innehåll',
    Comment: 'Kommentar',
    Company: 'Företag',
    Country: 'Land',
    Countries: 'Länder',
    Country_IA: 'Ett land',
    Country_DA: 'Landet',
    NoCountry: 'Inget land',
    OrganizationCountries: 'Länder på organisationer',
    MailTemplate: 'Emailmall',
    Menu_Activities: 'Aktiviteter',
    Menu_Involve: 'Involvera',
    InfluenceVerb: 'Påverka',
    Activity: 'Aktivitet',
    Colleagues: 'Kollegor',
    Colleague: 'Kollega',
    SaveChanges: 'Spara ändringar',
    ReportedBy: 'Rapporterat av',
    Document: 'Dokument',
    Language: 'Språk',
    Claimed: 'Hävdat',
    Created_PastTense: 'Skapade',
    MeasureAnswers: 'Mätdata',
    Fulfills: 'Uppfyller',
    Document_Description: '',
    Menu_Structures: 'Datastrukturer',
    ChangeFilter: 'Ändra filter',
    Statement: 'Kommentar',
    Fulfillments: 'Uppfyllnader',
    FilterContent_Noun: 'Filterinnehåll',
    NotSpecified: 'Ej angivet',
    Current: 'Aktuell',
    Requirements: 'Krav',
    Internal: 'Interna',
    By: 'Av',
    No: 'Nej',
    On: 'På',
    Or: 'Eller',
    To: 'Till',
    Permissions: 'Behörighet',
    NotVerified: 'Inte verifierat',
    NotDefined: 'Inte definierat',
    Statement_Description: 'En kommentar i frågan från en representant inom organisationen',
    ClosedNetworks: 'Stängda nätverk',
    AccountSettings: 'Kontoinställningar',
    MakeVisible: 'Gör synlig',
    Unpublished: 'Opublicerat',
    OrganizationInfo: 'Organisationsinformation',
    Objects: 'Objekt',
    Fulfilled_Verb: 'Uppfylld',
    Assign: 'Tilldela',
    Add: 'Lägg till',
    All: 'Alla',
    AllUsers: 'Alla användare',
    New: 'Ny',
    Yes: 'Ja',
    InternalComments: 'Interna kommentarer',
    Email: 'Email',
    Mail: 'Mail',
    Added: 'Tillagda',
    Addon: 'Tillägg',
    Aside: 'Åt sidan',
    Close: 'Stäng',
    Processing: 'Behandlas',
    Image: 'Bild',
    Links: 'Länkar',
    Other: 'Annat',
    LogIn: 'Logga in',
    Users: 'Användare',
    Untag: 'Ta bort tagg',
    Value: 'Värde',
    Title: 'Titel',
    Pages: 'Sidor',
    Phone: 'Telefon',
    Calculate: 'Beräkna',
    Notification: 'Notifiering',
    Change_Verb: 'Ändra',
    Username: 'Användarnamn',
    Lastname: 'Efternamn',
    Published: 'Publicerad',
    OrgInputMethod: 'Inputmetod',
    RestrictedAccess: 'Begränsad åtkomst',
    ChangeOrgInputMethod: 'Ändra inmatningsmetod',
    Influences: 'Påverkningar',
    Influenced: 'Påverkad',
    Question: 'Fråga',
    AnswerHistory: 'Svarshistorik',
    Answers: 'Svar',
    Link_Verb: 'Länk',
    Link_Noun: 'Länk',
    Menu_Impact: 'Påverka',
    Notifications: 'Notifieringar',
    Fulfillment: 'Uppfyllnad',
    CreatedBy: 'Skapat av',
    TagCollections: 'Tagg-samlingar',
    Menu_GAP: 'GAP-analys\r\n',
    MenuItem: 'Menyval',
    Menu_Layers: 'Filter',
    OrgName: 'Organisationens namn',
    Menu_Manage: 'Hantera',
    Locations: 'Platser',
    Firstname: 'Förnamn',
    Questions: 'Frågor',
    Menu_InternalFilters: 'Strukturer\r\n',
    Activities: 'Aktiviteter',
    Search_Ellipsis: 'Sök...',
    Visible: 'Synlig',
    LoadMore: 'Ladda fler',
    Menu_InternalStructures: 'Skapa strukturer',
    Menu_Addons: 'Tillägsverktyg',
    QuestionAnswer: 'Svar på fråga',
    QuestionAnswers: 'Svar på frågor',
    Fulfilled_Plural: 'Uppfylld',
    Menu_Documents: 'Dokument',
    Fulfilled: 'Uppfylls',
    Menu_PrivateNetwork: 'Nätverk för delning av privat data',
    Link_IA: 'En länk',
    Link_DA: 'Länken',
    Sent_Plural: 'Skickat',
    Menu_SupportAgreement: 'Supportavtal',
    Menu_Relations: 'Relationer',
    Expand: 'Expandera',
    Collapse: 'Kollapsa',
    Menu_AccountSettings: 'Kontoinställningar',
    MenuPermissions: 'Menybehörigheter',
    Measure: 'Mätvärde',
    Measures: 'Mätvärden',
    Measure_IA: 'Ett mätvärde',
    Measure_DA: 'Mätvärdet',
    Menu_Measures: 'Mätvärden',
    RelativeMeasure: 'Relativt mätvärde',
    RelativeMeasures: 'Relativa mätvärden',
    RelativeMeasure_IA: 'Ett relativt mätvärde',
    RelativeMeasure_DA: 'Relativa mätvärdet',
    Question_Description: '',
    Activity_IA: 'En aktivitet',
    Activity_DA: 'Aktiviteten',
    Year: 'År',
    Years: 'År',
    Month: 'Månad',
    Months: 'Månader',
    Day: 'Dag',
    Days: 'Dagar',
    Unpublished_Plural: 'Opublicerat',
    MultipleTypes: 'Flera typer',
    Requirement: 'Krav',
    Request: 'Förfrågan',
    Related: 'Relaterat',
    Document_IA: 'Ett dokument',
    Document_DA: 'Dokumentet',
    Menu_Status: 'Frågor',
    Menu_Plugin: 'Plugin',
    Tag_Noun: 'Tagg',
    Tag_Verb: 'Tagga',
    Menu_OrgSettings: 'Organisationsinställningar',
    Selected: 'Vald',
    Selected_Plural: 'Valda',
    Verifications: 'Verifieringar',
    Description: 'Beskrivning',
    Menu_ISO26000Definitions: 'ISO 26000-definitioner',
    Answer_Noun: 'Svar',
    Answer_Verb: 'Svar',
    VisibilityTags: 'Synlighetstaggar',
    InfluenceHistory: 'Påverkanshistorik',
    Filters: 'Filter',
    ShowAllActiveFilters: 'Visa alla aktiva filter',
    ShowLessActiveFilters: 'Visa mindre aktiva filter',
    WorldfavorPublic: 'Worldfavor Publik',
    FilterContents_Noun: 'Filterinnehåll',
    Menu_Report: 'Rapportera',
    LearnMore: 'Läs mer',
    Documents: 'Dokument',
    MakeInvisible: 'Gör osynlig',
    WorkTask: 'Arbetsuppgift',
    VerifiedBy: 'Verifierat av',
    Permission: 'Behörighet',
    Location: 'Plats',
    MenuPermission: 'Menybehörighet',
    Filter_Noun: 'Filter',
    NextPage: 'Nästa sida',
    PreviousPage: 'Föregående sida',
    Edit: 'Redigera',
    File: 'Fil',
    From: 'Från',
    Done: 'Klart',
    Misc: 'Diverse',
    Name: 'Namn',
    NormalMode: 'Normal vy',
    ExpandedMode: 'Expanderad vy',
    Next: 'Nästa',
    Open: 'Öppna',
    OpenInNewWindow: 'Öppna som hel sida',
    Link: 'Länk',
    Logo: 'Logga',
    User: 'Användare',
    Unit: 'Enhet',
    Hide: 'Dölj',
    Help: 'Hjälp',
    Type: 'Typ',
    Text: 'Text',
    Rule: 'Regel',
    Role: 'Roll',
    Sent: 'Skickat',
    Send: 'Skicka',
    Save: 'Spara',
    SaveAndContinue: 'Spara och fortsätt',
    Show: 'Visa',
    Page: 'Sida',
    Period: 'Period',
    TagCollection: 'Tagg-samling',
    Subject: 'Ämne',
    ViewMode: 'Visningsläge',
    VisibilityTag: 'Synlighetstagg',
    ConfirmDeleteMessage: 'Vill du radera det här?',
    Report: 'Rapportera',
    CreateAndClose: 'Skapa och stäng',
    Unseen: 'Osedda',
    MarkAllSeen: 'Sätt alla till sedda',
    Structures: 'Strukturer',
    Structure: 'Struktur',
    Found: 'Hittade',
    Area: 'Område',
    SubSupplier: 'Underleverantör',
    SubSuppliers: 'Underleverantörer',
    Supplier: 'Leverantör',
    Suppliers: 'Leverantörer',
    Supplier_IA: 'En leverantör',
    Supplier_DA: 'Leverantören',
    RelatedContent: 'Relaterat innehåll',
    Sender: 'Avsändare',
    GetContent: 'Hämta innehåll',
    EnterWebAddress: 'Ange webbadress',
    Address: 'Adress',
    EditPermissions: 'Redigera behörighet',
    Your: 'Din',
    PublicInformation: 'Offentlig Information',
    date: 'Datum',
    Date: 'Datum',
    Dates: 'Datum',
    Date_IA: 'Ett datum',
    Date_DA: 'Datumet',
    EndDate: 'Slutdatum',
    Time: 'Tid',
    Switch_Label_On: 'På',
    Switch_Label_Off: 'Av',
    ChooseExisting: 'Välj befintliga',
    organizationEmptySearchResult: 'Ingen organisation matchar din sökning',
    noOrganizationEmptyState: {
        header: 'Inget här ännu',
        description: 'Det verkar som du inte har användaråtkomst till något organisationskonto i Worldfavor. Kontakta vår support på support@worldfavor om du borde fått åtkomst till ett organisationskonto och misstänker att något är fel.',
    },
    buttons: {
        add: 'Lägg till fil',
        upload: 'Ladda upp',
    },
    'modules.upload': {
        descriptionSinglefile: 'Välj en fil att ladda upp',
        field: {
            preview: 'Förhandgranskning',
            filename: 'Filnamn',
            progress: 'Uppladdningsförfarande',
        },
        previewNotAvailable: 'Inte tillgänglig',
        formLabel: 'Fil',
        formLabelImage: 'Uppladdad bild',
        formLabelFile: 'Uppladdad fil',
        note: 'Notera att den nuvarande filen kommer tas bort om du ersätter den',
        squareImageInfo: 'Var god ladda upp en <strong>kvadratisk</strong> bild för bästa resultat',
        discardButton: 'Avbryt',
        dropBoxes: {
            initial: {
                label: 'Ladda upp ny fil',
                headerText: 'Dra och släpp',
                descriptionText: 'eller',
                browseButtonText: 'Bläddra',
                changeButtonText: 'Ersätt fil',
            },
            imageUpload: {
                label: 'Ladda upp ny bild',
                headerText: 'Dra och släpp en bild',
                descriptionText: 'eller',
                browseButtonText: 'Bläddra',
                changeButtonText: 'Ladda upp ny bild',
            },
            acceptUpload: {
                label: 'Ladda upp ny fil',
                headerText: 'Släpp filen här',
                descriptionText: '',
                browseButtonText: '',
            },
            rejectUpload: {
                label: 'Ladda upp ny fil',
                headerText: 'Filformatet stöds inte',
                descriptionText: 'Vänligen använd ett vanligt förekommande filformat för dokument och bilder',
                browseButtonText: '',
            },
        },
    },
    AddInformation: 'Lägg till information',
    ExistingInformation: 'Befintlig information',
    Existing: 'Befintliga',
    DeleteTag: 'Ta bort härifrån',
    'modules.report': {
        invite: {
            inviteHeader: 'Är hållbarhet och compliance inte ditt område?',
            inviteDescription: 'Bjud kostnadsfritt in en <strong>ansvarig kollega</strong>.',
            inviteButtonText: 'Bjud in',
        },
        publicRequestFrom: {
            label: 'Publika förfrågningar från:',
            tooltipText: 'Den här organisationen har efterfrågat att du och/eller dina kollegor ska rapportera viktigt information till deras värdekedja.',
        },
        requestFrom: {
            label: 'Förfrågningar från:',
            tooltipText: 'Den här organisationen har efterfrågat att du och/eller dina kollegor ska rapportera viktigt information till deras värdekedja.',
        },
        contactPersons: {
            label: 'Kontaktpersoner:',
            tooltipText: 'Samtliga kontaktpersoner till organisationen som efterfrågat informationen. Notera att en kollega kan ha bjudit in dig utan att ha lagt till dig som kontaktperson till ett specifikt företag.',
        },
        syncingReportItems: 'Uppdaterar vyn',
        reportPageInfo: 'Förfrågningar er organisation ska rapportera på',
        emptyStateHeader: 'Ni har inga informationsförfrågningar ännu',
        emptyStateDescription: 'När någon skickar en förfrågan till er kommer du se den här och kan rapportera informationen direkt till mottagaren.\nDu kommer få en notifiering när det sker.',
        emptyStateButton: 'Tillbaka till Dashboard',
        informationRequestsFrom: 'Informationsförfrågningar från: {{orgname}}',
        addRemoveContactPerson: 'Välj eller ändra kontaktpersoner',
        showPreviousAnswers: 'Visa tidigare svar',
        hidePreviousAnswers: 'Dölj tidigare svar',
        verificationDocuments: {
            previouslySignedDocumentsHeader: 'Tidigare signerade dokument',
            lastSigned: 'Senast signerad',
            previouslySigned: 'Tidigare signerade',
            emptyStateHeader: 'Ni har inte skrivit under era svar',
            emptyStateDescription: 'När ni besvarat alla frågor och lagt till efterfrågad information till vänster ska ni skriva under era svar här. Då skapas ett dokument som du alltid hittar här.',
            viewModeEmptyState: {
                header: 'Inte underskrivet',
            },
        },
        publicProfileOptions: {
            noUrlTooltipText: 'Din profil har ingen webbadress. Skriv in URL-adressen i Profilinställningar',
            publishProfileButtonLabel: 'Publicera',
            previewProfileButtonLabel: 'Förhandsvisa',
            profileSettings: 'Profilinställningar',
            lastPublishedAt: 'Senast publicerad på:',
            noAddressMessage: 'You haven\'t set the address for your public profile.',
            clickToOpenSettingsLabel: 'Click here to set it up.',
            publishConfirmationModal: {
                title: 'Publish changes?',
                description: 'You are about to publish the information to the following address:',
                publishButton: 'Publish',
            },
            successMessageModal: {
                title: 'Profilen publicerades framgångsrikt',
                description: '',
            },
            profileSettingsModal: {
                profileUrl: 'Webbadress',
                profileUrlValidationMessage: 'Ange webbadressen för din profil',
                coverImg: 'Omslagsbild',
            },
            errorModal: {
                title: 'Ursäkta, något gick fel',
                message: 'Ett oväntat fel inträffade.\nOm felet kvarstår, kontakta vår kundsupport.',
            },
            previewSuccessModal: {
                title: 'Förhandsgranska profil',
                description: 'Förhandsgranskningen är genererad och kan öppnas.',
                openPreview: 'Öppna förhandsgranskning',
            },
        },
        messageAfterSigning: {
            headerMessage: 'Bra jobbat!',
            completedMessage: 'Du har gjort klart allt i denna förfrågan. Om det behövs kompletterande information här kommer du meddelas via email.',
            unansweredRequests: {
                preSentence: 'Du har fortfarande ',
                unansweredRequestsSingular: ' {{count}} förfrågan att slutföra.',
                unansweredRequestsPlural: ' {{count}} förfrågningar att slutföra.',
            },
            dashboardButtonText: 'Gå tillbaka till dashboarden',
            closeButtonText: 'Stäng',
            justCloseButtonText: 'eller fortsätt rapportera här',
        },
        influenceGrouper: {
            productionSites: {
                emptyStateHeader: 'Inga produktionsanläggningar inlagda',
                emptyStateDescription: 'Lägg till produktionsanläggningar genom att klicka på knappen här ovanför.',
            },
            productServices: {
                emptyStateHeader: 'Inga produkter eller tjänster inlagda',
                emptyStateDescription: 'Lägg till produkter eller tjänster genom att klicka på knappen här ovanför.',
            },
            organizations: {
                emptyStateHeader: 'Inga organisationer inlagda',
                emptyStateDescription: 'Lägg till organisationer genom att klicka på knappen här ovanför.',
            },
            suppliers: {
                emptyStateHeader: 'Inga leverantörer inlagda',
                emptyStateDescription: 'Lägg till leverantörer genom att klicka på knappen här ovanför.',
            },
            holdings: {
                emptyStateHeader: 'Inga innehav inlagda',
                emptyStateDescription: 'Lägg till innehav genom att klicka på knappen här ovanför.',
            },
        },
    },
    'modules.internalValueChain': {
        collaborationTool: 'Samarbetsverktyg',
    },
    'modules.valueChain': {
        publicContactPerson: 'Publik kontaktperson',
        publicContactNoAddContact: 'Eftersom denna organisation har en publik kontaktperson kan du inte ändra kontaktpersoner',
        publicContactWfAddingInfo: 'Ändra publik kontaktperson (detta kan endast göras av Worldfavor och skyddar åtkomsten till denna organisation)',
        onHoverTextForAggregatedData: 'Öppna detaljerade resultat',
        onHoverTextForDownloadAggregatedData: 'Exportera rapporterad data',
        suppliersThatRequiresAction: 'Kräver åtgärd',
        noSuppliersRequiresAction: 'Inga kräver åtgärd.',
        failedMails: 'Misslyckade mailutskick',
        organizationCount: 'Antal organisationer',
        basedOnFiltering: 'Baserat på filtrering',
        SubSupplierTo: 'Underleverantör till:',
        batchObjectChange: {
            buttonLabel: 'Massändra objekt',
            allPackagesButtonLabel: 'Massändra i alla kravpaket',
            buttonCaption: 'Inställningar och krav',
            modalHeader: 'Massändra objekt',
            savingSettings: 'Sparar ändringar...',
            savingRequirements: 'Sparar krav...',
            successSaving: 'Ändringar sparade. Du behöver ladda om sidan för att se ändringarna.',
            errorSaving: 'Kunde inte spara ändringar, vänligen ladda om sidan och försök igen.',
            questionSettings: 'Inställningar på frågor',
            measureSettings: 'Inställningar på mätvärden',
            questionRequirements: 'Krav på frågor',
            measureRequirements: 'Krav på mätvärden',
            taskStructureRequirements: 'Krav på \'Lägg till information\'',
            measureFrequencyMismatch: {
                title: 'Olika svarsfrekvens',
                message: 'Inställningen för svarsfrekvens måste vara samma på alla mätvärden för att kunna ändra krav på dem.',
            },
        },
        batchFulfillmentChange: {
            buttonLabel: 'Massändra uppfyllnad',
            modalHeader: 'Massändra uppfyllnad för manuell bedömning',
            progressModalHeader: 'Massändra uppfyllnad',
            savingFulfillments: 'Sparar uppfyllnad...',
            successSaving: 'Uppfyllnad har sparats.',
            errorSaving: 'Kunde inte spara uppfyllnad, vänligen ladda om sidan och försök igen.',
        },
        actions: {
            changeContactPersonOnOrg: 'Ändra kontaktperson',
            changePublicContactPersonOnOrg: 'Ändra publik kontaktperson',
            changeCategoriesOnOrg: 'Ändra kategorisering',
            createInfluenceOnOrg: 'Lägg till kravpaket',
            createInternalInfluenceOnOrg: 'Lägg till internt kravpaket',
            mailHistory: 'Emailhistorik',
            sendMail: 'Skicka emailmeddelande',
            reminderMail: 'Skicka påminnelsemail',
            createAnalyzeJobOnOrg: 'Lägg till analys',
        },
        sendMail: {
            noRequirementUserModal: {
                title: 'Något saknas',
                message: 'Denna organisation saknar kontaktperson och/eller kravpaket.',
                description: 'Du behöver lägga till minst en kontaktperson och ett kravpaket för att kunna skicka inbjudan',
            },
            noUserSelectedModal: {
                message: 'Välj en användare för att fortsätta',
            },
            noActiveInfluenceModal: {
                headerText: 'Något saknas',
                title: 'Inget av kravpaketen är aktivt ännu',
                message: 'Ni har satt anpassade aktiveringsdatum som ligger i framtiden (eller inte är angivna).\nAnge dagens eller ett förflutet aktiveringsdatum på åtminstone ett av kravpaketen om du vill kunna skicka emailmeddelandet på en gång.',
            },
            allInfluencesNeedAssessment: {
                title: 'Samtliga kravpaket behöver bedömmas',
                message: 'Ni måste utföra manuell bedömning på åtminstone ett av kravpaketen om du vill kunna skicka emailmeddelandet på en gång.',
            },
        },
        dueDate: 'Förfallodatum',
        requireAction: 'Kräver åtgärd',
        partiallyFulfilled: 'Ej uppfylld signering',
        packages: 'Kravpaket',
        aggregatedView: {
            title: 'Detaljerade resultat',
        },
        influenceYears: 'Kravår',
        influenceDates: 'Kravdatum',
        internalInfluence: {
            sidePanelHeader: 'Interna kravpaket',
        },
        influence: {
            remove: {
                modalTitle: 'Ta bort informationsförfrågan',
                modalMessage: '<i class="fa fa-trash-o"></i><div class=\'pt15\'>Vill du ta bort informationsförfrågan <br /><strong>{{influencename}}</strong> från <strong>{{orgname}}</strong>?</div>',
            },
            emptyStateHeader: 'Det finns inga informationsförfrågningar',
            message_noStructureSelected: 'Du måste välja ett kravpaket',
            changesRequiresSigning: 'Ni har gjort ändringar som ej skrivits under',
            signingCertify: 'Härmed intygar jag att informationen som lämnats är sanningsenlig',
            modalHeader: 'Lägg till krav för<br /><strong>{{orgname}}</strong>',
            fulfillmentLabel: 'Kravstatus',
            dueDateLabel: 'Förfallodatum',
            activatedAtLabel: 'Aktiveras',
            activationDate: 'Aktiveringsdatum',
            creatorUserLabel: 'Ägare',
            targetUserLabel: 'Mottagare',
            mailSendoutState: 'Mailutskick-status',
            mailSendoutStateDescription: 'Välj om inbjudnings- eller påminnelsemail ska skickas',
            noTargetUser: 'Ingen specific mottagaranvändare',
            hasTargetUser: 'Har mottagaranvändare',
            assignedQuestionsLabel: 'Innehåller frågor tilldelade till dig',
            selectRequirementButtonCaption: 'Välj kravpaket',
            submitButtonCaption: 'Spara krav',
            requirement: 'Krav',
            requirementPreview: {
                modalHeader: 'Krav för {{orgname}}',
            },
            requirementSelection: {
                modalHeader: 'Krav',
                emptyStateHeader: 'Alla tillgängliga kravpaket har redan lagts till',
            },
            noReqPackagesSelected: {
                title: 'Inga kravspaket har valts',
                description: 'Var god och välj minst ett kravspaket.',
            },
            hasSpecialRequirements: 'Har undantag',
            mailSentCount: 'Antal skickade email',
            isOverdue: 'Är försenad',
            message_header: 'Ofullständiga uppgifter',
            message_questionsLeft: 'Samtliga frågor måste vara uppfyllda innan du skriver under.',
            message_haveToFulfillQuestions: 'Du måste uppfylla fråga nummer ',
            message_generatingPdf: 'Genererar PDF...',
            message_calculatingFulfillment: 'Beräknar uppfyllnad...',
            message_verifying: 'Verifierar...',
            message_pdfFailed: 'Något gick fel vid genereringen av PDF. Försök igen senare.',
            addComment: 'Lägg till kommentar',
            addActivity: 'Lägg till en aktivitet',
            uploadFile: 'Ladda upp fil',
            addCountry: 'Lägg till land',
            uploadCertificate: 'Ladda upp certifikat',
            addFinding: 'Lägg till nytt avvikelse',
            addPerson: 'Lägg till person',
            addOrganization: 'Lägg till organisation',
            youHaveToAddAComment: 'Du måste ange en kommentar',
            haveNotYetAddedComment: 'Ni har ännu inte lagt till någon kommentar.',
            haveToAnswerQuestionBeforeAddingInfo: 'Du måste svara på frågan innan du lägger till mer information',
            calculateFulfillment: 'Beräkna uppfyllnad',
            selectOrgToCreateInfluence: {
                modalTitle: 'Välj organisation',
                modalMessage: 'Välj vilken organisation du vill skapa ett kravpaket till',
            },
            commentToReportingOrganization: 'Kommentar till den rapporterande organisationen',
            commentFrom: 'Kommentar från',
            attachedInfoForInfluence: 'Bifogad information till den rapporterande organisationen',
            lastSignedAt: 'Senast signerad',
            notSignedYet: 'Inte signerad ännu',
            requestFromAtInfo: 'skickade denna förfrågan {{activatedAt}}:',
        },
        contactPersons: {
            modalHeader: 'Kontaktpersoner för {{orgname}}',
            createFormHeader: 'Lägg till kontaktperson',
            createButtonCaption: 'Lägg till kontaktperson',
            emptyStateHeader: 'Ingen kontaktperson skapad',
        },
        suppliers: {
            emptyStateHeader: 'Du har inte lagt till någon partner ännu',
            emptyStateDescription: 'Lägg till en partner genom att klicka på <strong>Lägg till ny</strong> här ovanför',
        },
        categories: {
            header: 'Kategorier',
            createNew: 'Skapa ny kategori',
            modalHeader: 'Kategorier för {{orgname}}',
            emptyStateHeader: 'Ni har inte skapat någon kategori ännu',
            emptyStateDescription: 'Klicka på knappen <b>Skapa ny kategori</b> ovan för att komma igång',
            removeFromGroup: 'Ta bort från grupp',
            addToGroup: 'Lägg till i grupp',
            newGroup: 'Ny grupp',
            addToNewGroup: 'Lägg till i en ny grupp',
            editGroup: 'Redigera grupp',
        },
        subCategories: {
            emptyStateHeader: 'Du har inte skapat någon underkategori ännu',
            emptyStateDescription: 'Skapa en underkategori genom att klicka på <strong>Skapa ny</strong> här ovanför',
        },
        mainDemands: {
            emptyStateHeader: 'Skapa ett kravpaket',
            emptyStateDescription: 'Här sätter ni upp vilken information ni vill samla in från externa parter. Du kan dela in dem i olika områden.',
        },
        demands: {
            description: 'Alla tillgängliga för {{categoryTitle}}',
            createNew: 'Skapa nytt kravpaket',
            emptyStateHeader: 'Ni har inte skapat något kravpaket ännu',
            emptyStateDescription: 'Klicka på knappen <strong>Skapa nytt kravpaket</strong> ovan för att komma igång',
            packageFriendlyTitle: 'Titel för mottagaren (valfritt)',
            standardPackages: {
                addPackage: 'Lägg till hållbarhetstema',
                pickerHeader: 'Välj teman',
            },
            standardInternalPackages: {
                addPackage: 'Lägg till emissionsfaktorer',
                pickerHeader: 'Välj paketet emissionsfaktorer',
            },
        },
        standardCategories: {
            addCategory: 'Lägg till standardkategorier',
            pickerHeader: 'Välj kategorier',
        },
        analyze: {
            labelsFilterBarHeader: 'Analys-etiketter',
            titlesFilterBarHeader: 'Analyspaket',
            scoreFilterBarHeader: 'Analyspoäng',
            analysingData: 'Analyserar rapporterad data',
        },
        analyzePackages: {
            description: 'Alla tillgängliga för {{categoryTitle}}',
            createNew: 'Skapa nytt analyspaket',
            emptyStateHeader: 'Ni har inte skapat något analyspaket ännu',
            emptyStateDescription: 'Klicka på knappen <strong>Skapa nytt analyspaket</strong> ovan för att komma igång',
            standardPackages: {
                addPackage: 'Lägg till analys för hållbarhetstema',
                pickerHeader: 'Välj analyspaket',
            },
        },
        analyzeJob: {
            choosePackage: 'Välj analyspaket',
            creationModalHeader: 'Lägg till en analys för: {{orgname}}',
            remove: {
                modalTitle: 'Ta bort analys',
                modalMessage: '<i class="fa fa-trash-o"></i><div class=\'pt15\'>Vill du ta bort analysen <br /><strong>{{analyzejobtitle}}</strong> från <strong>{{orgname}}</strong>?</div>',
            },
        },
        organizationCategories: {
            emptyStateHeader: 'Ännu inte kategoriserad',
        },
        organizations: {
            createNew: 'Lägg till organisation',
            remove: {
                modalHeaderText: 'Ta bort {{orgname}}',
                modalTitle: 'Är du säker på att du vill ta bort organisationen?',
                modalMessage: 'Organisationen kommer att tas bort från er Data Collector.\nAlla informationsförfrågningar ni ställt till dem kommer att raderas.\n\nOm du fortfarande vill ta bort organisationen från er Data Collector så klickar du på <strong>Ta bort</strong> nedan.',
            },
            alreadyExists: {
                modalTitle: 'Organisationen finns redan',
                modalMessage: 'En organisation med det här organisationsnumret finns redan:<span style=\'font-size:14px;\'><br /><br /><b>{{orgname}}</b><br />{{orgnumber}}</span><br /><br />Vill ni lägga till den organisationen i er Data Collector?',
            },
            multipleAlreadyExists: {
                modalTitle: 'Organisationen finns redan',
                modalMessage: 'Flera organisationer med det här organisationsnumret finns redan. Välj den organisation som ni vill lägga till i er Value Chain.',
            },
            multipleAlreadyExists2: {
                modalTitle: 'Organisationen finns redan',
                modalMessage: 'Flera organisationer med det här organisationsnumret finns redan. Välj den organisation som ni vill lägga till.',
            },
            alreadyAdded: {
                modalTitle: 'Organisationen redan tillagd',
                modalMessage: 'En organisation med det här organisationsnumret är redan tillagd i er Data Collector:<span style=\'font-size:14px;\'><br /><br /><b>{{orgname}}</b><br />{{orgnumber}}</span><br />',
            },
            emptyStates: {
                noAddedOrganizations_header: 'Ni har inte lagt till några organisationer',
                noAddedOrganizations_description: 'Klicka på knappen <b>Lägg till organisation</b> ovan för att komma igång',
                failedMails_header: 'Ni har inga misslyckade mailutskick',
                noSearchOrFilterResult_header: 'Ingen organisation matchade din sökning eller filtrering',
                noFilterResult_mailHistory_header: 'Inga email matchade din filtrering',
                noFilterResult_mailHistory_description: 'Prova med andra filtreringsalternativ',
            },
        },
        notifications: {
            seeAll: 'Se alla notifieringar',
            emptyStateHeader: 'Det finns inga händelser att visa',
            emptyStateActiveFilter_header: 'Inga händelser matchade din filtrering',
            emptyStateActiveFilter_description: 'Prova med andra filtreringsalternativ',
        },
        packageEditor: {
            emptyStateHeader: 'Detta kravpaket är tomt',
            emptyStateDescription: 'Lägg till kategorier, frågor och mätvärden genom knappen ovan',
            specialSettings: {
                emptyStateHeader: 'Förkonfigurerat kravpaket',
                emptyStateDescription: 'Detta kravpaket är förkonfigurerat och dess innehåll kan inte ändras här',
            },
            targetContextParent: 'Avsedd för',
            targetContextParent_valueChainOrg: {
                label: 'Organisationer rapporterar för',
                notSet: 'Sig själva',
            },
            packageSettings: {
                requirementTexts: {
                    label: 'Individuella krav',
                    show: 'Visa kravtexter',
                    hide: 'Dölj kravtexter',
                },
                reportingUi: {
                    label: 'Rapporteringsgränssnitt',
                    useNew: 'Använd nya',
                    useOld: 'Använd gamla',
                },
                tabs: {
                    label: 'Flikar',
                    enabled: 'Aktiverat',
                    disabled: 'Inakviterat',
                },
                reportedDataDeletion: {
                    label: 'Borttagning av rapporterad data',
                    enabled: 'Tillåt',
                    disabledForMeasureAnswers: 'Tillåt inte borttagning av mätdata',
                    disabledForQuestionAnswers: 'Tillåt inte borttagning av frågesvar',
                    disabledForBoth: 'Tillåt inte borttagning av mätdata och frågesvar',
                },
                itemNumbering: {
                    label: 'Rubriknumrering för rapportör',
                    show: 'Visa',
                    hide: 'Dölj',
                },
                batchChangeObjects: {
                    label: 'Ändra alla objekt',
                    buttonCaption: 'Inställningar eller krav',
                },
                csvExport: {
                    label: 'CSV-export för rapportör',
                    enabled: 'Aktiverat',
                    disabled: 'Inakviterat',
                },
            },
        },
        requirements: {
            EmptyRequirement: 'Tomt krav (uteslut objektet från uppfyllnad)',
            CurrentRequirementInvalid_reporter: 'Kravet är inte giltigt',
            CurrentRequirementInvalid_admin: 'Det valda kravet kan inte användas. Välj ett annat krav.',
            ManualAssessment: 'Manuell bedömning',
            AllQuestionsFulfilled: 'Samtliga frågor måste uppfyllas',
            AllQuestionsAnswered: 'Alla frågor måste besvaras',
            None: 'Inget',
            AnyAnswer: 'Valfritt svar',
            Unanswered: 'Inget svar',
            MinimumOneObjectWithAttachments: 'Minst ett tillagt objekt med bifogad information',
            MinimumOneObject: 'Minst ett tillagt objekt',
            Yes: 'Ja',
            No: 'Nej',
            YesOrNotRelevant: 'Ja eller Ej relevant',
            NoOrNotRelevant: 'Nej eller Ej relevant',
            Yes_OtherwiseCommentIsRequired: 'Ja (Annars krävs kommentar)',
            No_OtherwiseCommentIsRequired: 'Nej (Annars krävs kommentar)',
            YesOrNotRelevant_OtherwiseCommentIsRequired: 'Ja eller Ej relevant (Annars krävs kommentar)',
            NoOrNotRelevant_OtherwiseCommentIsRequired: 'Nej eller Ej relevant (Annars krävs kommentar)',
            AnyAnswer_YesRequiresMoreInfo: 'Valfritt svar (Vid Ja krävs mer information)',
            AnyAnswer_NoRequiresMoreInfo: 'Valfritt svar (Vid Nej krävs mer information)',
            AnyAnswer_AccreditedRequiresMoreInfo: 'Valfritt svar (Vid Ackrediterad krävs mer information)',
            AnyAnswer_NotCompliantRequiresMoreInfo: 'Valfritt svar (Vid Ej kompatibel krävs mer information)',
            AnyAnswer_NoOrPartlyRequiresMoreInfo: 'Valfritt svar (Vid Nej eller Delvis krävs mer information)',
            AnyAnswer_YesOrPartlyRequiresMoreInfo: 'Valfritt svar (Vid Ja eller Delvis krävs mer information)',
            AnyAnswerWithMoreInfo: 'Valfritt svar med bifogad information',
            YesWithMoreInfo: 'Ja, med bifogad information',
            NotRelevantWithMoreInfo: 'Ej relevant, med bifogad information',
            AccreditedWithMoreInfo: 'Ackrediterad, med bifogad information',
            AnyAnswerSecondPreviousPeriod: 'Valfritt svar i andra föregående period',
            AnyAnswerSecondPreviousPeriodWithMoreInformation: 'Valfritt svar i andra föregående period, med bifogad information',
            AnyAnswerSecondPreviousPeriodWithMoreInformationExceptIfNA: 'Valfritt svar i andra föregående period, med bifogad information om inte svaret är N/A',
            AnyAnswerSecondPreviousPeriodWithMoreInformationIfNA: 'Valfritt svar i andra föregående period, med bifogad information om svaret är N/A',
            AnyAnswerPreviousPeriod: 'Valfritt svar i föregående period',
            AnyAnswerPreviousPeriodWithMoreInformation: 'Valfritt svar i föregående period, med bifogad information',
            AnyAnswerPreviousPeriodWithMoreInformationExceptIfNA: 'Valfritt svar i föregående period, med bifogad information om inte svaret är N/A',
            AnyAnswerPreviousPeriodWithMoreInformationIfNA: 'Valfritt svar i föregående period, med bifogad information om svaret är N/A',
            AnyAnswerCurrentPeriod: 'Valfritt svar i nuvarande period',
            AnyAnswerCurrentPeriodWithMoreInformation: 'Valfritt svar i nuvarande period, med bifogad information',
            AnyAnswerCurrentPeriodWithMoreInformationExceptIfNA: 'Valfritt svar i nuvarande period, med bifogad information om inte svaret är N/A',
            AnyAnswerCurrentPeriodWithMoreInformationIfNA: 'Valfritt svar i nuvarande period, med bifogad information om svaret är N/A',
            AnyAnswerSpecificPeriodWithMoreInformationExceptIfNA: 'Valfritt svar för period, med bifogad information om inte svaret är N/A',
            AnyAnswerWithMoreInfoIfDeviationFromSamePeriodLastYear: 'Valfritt svar, med bifogad information om det avviker för mycket från samma period föregående år',
            AnyAnswerWithMoreInfoIfDeviationFromPreviousPeriod: 'Valfritt svar, med bifogad information om det avviker för mycket från föregående period',
            AnyAnswerWithMoreInfoIfDeviationFromSamePeriodLastYear_WithValue: 'Valfritt svar, med bifogad information om det avviker {{requirementValue}}% eller mer från samma period föregående år',
            AnyAnswerWithMoreInfoIfDeviationFromPreviousPeriod_WithValue: 'Valfritt svar, med bifogad information om det avviker {{requirementValue}}% eller mer från föregående period',
            Span: 'Spann',
            specificValues: '{{answertypes}}',
            specificValueWithRelatedContent: '{{answertypes}}, med mer information',
            preferredValue: '{{answertypes}} (annars krävs mer information)',
            preferredValueWithRelatedContent: 'Valfritt svar (vid {{answertypes}} krävs mer information)',
            TargetOrganizationCountry: 'Organisationens land',
            TargetOrganizationInNetworkCategoryID: 'Organisationens kategori i nätverket',
            deviationPercentage: 'Max avvikelse i procent',
        },
        objectSettings: {
            answerOptions: {
                label: 'Svarsalternativ',
                YesNoNotRelevantAndProcessing: 'Ja, Nej, Ej relevant och Behandlas',
                YesAndNo: 'Ja och Nej',
                YesNoAndNotRelevant: 'Ja, Nej och Ej relevant',
            },
            informationThatCanBeAdded: {
                label: 'Information som kan bifogas',
                label_onAnswer: 'Information som kan bifogas på svaret',
                label_onQuestion: 'Information som kan bifogas på frågan',
                label_nested: 'Information som kan bifogas på varje alternativ',
                NoInformation: 'Ingen information',
                Comment: 'Kommentar',
                FileUpload: 'Uppladdning av fil',
                CertificateUpload: 'Uppladdning av certifikat',
                CommentAndFileUpload: 'Kommentar och Uppladdning av fil',
                CommentAndCertificates: 'Kommentar och Uppladdning av certifikat',
                Finding: 'Avvikelse',
                FindingCommentAndFileUpload: 'Avvikelse, Kommentar och Uppladdning av fil',
            },
            showSigning: {
                label: 'Signering',
                show: 'Ska signeras',
                hide: 'Ingen signering',
            },
            measurePeriodSettings: {
                label: 'Svarsfrekvens',
                dropdownAction: 'Välj svarsfrekvens',
            },

            measureSettings: {
                unitSelector: {
                    label: 'Val av enhet',
                    selectorDisabled: 'Avaktiverat',
                    selectorEnabled: 'Tillåt rapportering i en annan enhet av samma storhet',
                },
                notAvailableCheckbox: {
                    label: 'N/A-rapportering',
                    disabled: 'Avaktiverat',
                    enabled: 'Tillåt rapportering av N/A-värden',
                },
                lockPeriod: {
                    label: 'Lås rapporteringsperiod',
                    none: 'Inaktiverat',
                },
            },
            pickerSettings: {
                label: 'Hur det ska bifogas',
                notSet: '-',
                useDropdownAndOnlyCreate: 'Använd dropdown och skapa bara nytt',
                useCustomFindingCaptionAndOnlyCreate: 'Använd anpassad knapptext för avvikelse och skapa bara nytt',
                emphasizeFindingCreationAndThenDropdown: 'Betona Avvikelse och bifoga andra typer med dropdown',
                gics: 'GICS',
                sourceItem: {
                    label: 'Source list to pick items from',
                    optionInObjectTypesSelector: 'Egna alternativ',
                    'value.none': 'Not set',
                    'value.set': 'Is set',
                    pickerHeader: 'Välj källa för egna alternativ',
                },
                openerButton: {
                    label: 'Button caption',
                    'value.attach': 'Attach',
                    'value.select': 'Select',
                    'value.add': 'Add',
                },
                singlePick: {
                    label: 'Möjligt att välja flera alternativ eller endast ett',
                    'value.false': 'Flera',
                    'value.true': 'Endast ett',
                },
                firstLevelAsFilter: {
                    label: 'Är alternativen kategoriserade?',
                    'value.false': 'Nej',
                    'value.true': 'Ja',
                },
                dateItemForm: {
                    label: 'Datumvärden',
                    singleDate: 'Endast ett datum',
                    startAndEndDate: 'Start- och slutdatum',
                },
            },
            reportingPeriod: {
                customMaxAgeInDays: 'Antal dagar',
            },
        },
        aggregated: {
            exportEntirePackage: {
                modalHeader: 'Exportera all rapporterad data',
                loadingData: 'Förbereder data...',
                exportAllButtonCaption: 'Exportera all rapporterad data',
                itemErrorModal: {
                    title: 'Något gick fel',
                    message: 'Ett oväntat fel inträffade när data förbereddes för export.\n\nVänligen kontakta vår support med följande felinformation:\n<code>{{errorDetails}}</code>',
                },
                relativeMeasureCache: {
                    clearResultsButton: 'Beräkna på nytt',
                    useSavedResultsButton: 'Använd sparade beräkningsresultat',
                },
            },
        },
        organizationAdder: {
            header: 'Value chain-medlemskap',
            guidance: 'Här kan du importera <strong>{{orgname}}</strong> till din Data Collector tillsammans med rapporterade kontaktpersoner, om det finns några.<br /><br />'
				+ '<i class=\'fa fa-check\'></i> = Redan in denna Data Collector<br />'
				+ '<i class=\'fa fa-times\'></i> = Kan läggas till i denna Data Collector'
				+ '<br /><br />'
				+ 'Under varje Data Collector ser du kontaktpersonerna ni redan har för organisationen. Detta är endast tillgängligt om kontaktpersoner har rapporterats på organisationen.',
            addToValueChainButtonText: 'Lägg till i Data Collector',
            addUserButtonText: 'Lägg till en kontaktperson',
            memberButNoParentOrg: 'Tillagd i Data Collector men inte kategoriserad till rapporterande organisation',
            addReportingOrgAsParentButtonText: 'Bekräfta kategorisering',
            valueChainPicker: {
                title: 'Lägg till organisation i Data Collector',
                description: 'Klicka på en Data Collector för att lägga till organisationen där. Du kan inte ta bort organisationen från Data Collector härifrån.',
                untoggleMessage: 'Du kan inte ta bort organisationen härifrån',
            },
            peoplePicker: {
                title: 'Kontaktpersoner',
                description: 'Klicka på en kontaktperson för att lägga till den i Data Collector. Du kan inte ta bort kontaktpersoner från Data Collector härifrån.',
                untoggleMessage: 'Du kan inte ta bort kontaktpersonen härifrån',
            },
        },
        export: {
            exportOrganizationsHeader: 'Exportera organisationer',
            includeCategorizations: 'Inkludera kategoriseringar',
        },
        nextEmailSendoutInfo: {
            title: 'Schemalagda email',
            showInfo: 'Visa info',
        },
        networkSettings: {
            popoverHeader: "Inställningar",
            reportingLockStatusLabel: 'Låsstatus för rapportering',
            isReportingReadonly: {
                label: "Lås rapportering av ny data (påverkar inte intern rapportering)",
                disabled: "Rapportering är tillåten",
                enabled: "Rapportering är låst",
                setDisabled: "Tillåt rapportering",
                setEnabled: "Lås rapportering",
            },
            reportedDataLockedBeforeDate: {
                label: "Lås data som rapporterats före specifikt datum (påverkar inte intern rapportering)",
                placeholder: "Välj ett datum",
                enabled: "Data som rapporterats före {{date}} är låst",
            },
            useRelativeMeasureResultsCache: {
                label: "Cache för relativa mätvärden",
                disabled: "Ej aktivt",
                enabled: "Aktiverat",
            },
            fiscalYearMonthOffset: {
                label: "Månadsförskjutning av räkenskapsår",
                formLabel: "Månadsförskjutning av räkenskapsår (för diagram i dashboards vid gruppering på år)",
                disabled: "Ej aktivt",
            },
        },
    },
    'modules.picker': {
        Alternatives: 'Alternativ',
        chooseAlternatives: 'Välj alternativ',
        alternative: 'Alternativ',
        inputFieldPlaceholder: 'Välj eller sök efter {{ itemText }}',
        customObjectType: {
            emptyStateHeader: 'Du har inga existerande {{information}} att välja bland',
            emptyStateDescription: 'Skapa en ny {{information}}',
            emptyStateSearch: 'Inga {{information}} matchade din sökning',
        },
        objectTypes: {
            0: {
                emptyStateHeader: 'Du har ingen existerande information att välja bland',
                emptyStateDescription: 'Skapa något nytt att lägga till som information',
                emptyStateSearch: 'Inga objekt matchade din sökning',
            },
            15: {
                emptyStateHeader: 'Du har inga existerande aktiviteter att välja bland',
                emptyStateDescription: 'Skapa en ny aktivitet för att lägga till som information',
                emptyStateSearch: 'Inga aktiviteter matchade din sökning',
            },
            18: {
                createButtonCaption: 'Lägg till',
                createAndCloseButtonCaption: 'Lägg till och stäng',
                emptyStateHeader: 'Du har inga existerande dokument att välja bland',
                emptyStateDescription: 'Lägg till ett nytt dokument för att lägga till som information',
                emptyStateSearch: 'Inga dokument matchade din sökning',
            },
            25: {
                createButtonCaption: 'Spara',
            },
            44: {
                emptyStateHeader: 'Du har inga existerande kommentarer att välja bland',
                emptyStateDescription: 'Skapa en ny kommentar för att lägga till som information',
                emptyStateSearch: 'Inga kommentarer matchade din sökning',
            },
            50: {
                createButtonCaption: 'Lägg till',
                createAndCloseButtonCaption: 'Lägg till och stäng',
                emptyStateHeader: 'Du har inga existerande länkar att välja bland',
                emptyStateDescription: 'Lägg till en ny länk för att lägga till som information',
                emptyStateSearch: 'Inga länkar matchade din sökning',
            },
            75: {
                emptyStateHeader: 'Inga länder att välja mellan',
                emptyStateDescription: '',
                emptyStateSearch: 'Inga länder matchade din sökning',
            },
            90: {
                emptyStateHeader: 'Du har inga existerande media att välja bland',
                emptyStateDescription: 'Skapa ett nytt media för att lägga till som information',
                emptyStateSearch: 'Inga media matchade din sökning',
            },
            100: {
                emptyStateHeader: 'Du har inte tilldelat detta till någon',
                emptyStateDescription: ' ',
                emptyStateSearch: 'Det finns inga användare som matchar ditt sökord',
            },
            101: {
                emptyStateHeader: 'Du har inte lagt till någon organisation',
                emptyStateDescription: 'Klicka på <strong>Lägg till organisation</strong> här ovanför',
                emptyStateSearch: 'Inga organisationer matchade din sökning.<br />Prova att justera din sökning eller lägg till en ny organisation',
            },
            '101_searchServer': { // Not used yet
                emptyStateHeader: 'Sök efter organisationer eller lägg till ny',
                emptyStateDescription: 'Använd sökrutan för att hitta organistioner att välja. Du kan även lägga till nya organisationer.',
                emptyStateSearch: 'Inga organisationer matchade din sökning.<br />Prova att justera din sökning eller lägg till en ny organisation',
            },
            106: {
                createButtonCaption: 'Lägg till',
                createAndCloseButtonCaption: 'Lägg till och stäng',
                emptyStateHeader: 'Du har inga existerande certifikat att välja bland',
                emptyStateDescription: 'Lägg till ett nytt certifikat för att lägga till som information',
                emptyStateSearch: 'Inga certifikat matchade din sökning',
            },
            107: {
                createButtonCaption: 'Lägg till',
                createAndCloseButtonCaption: 'Lägg till och stäng',
                emptyStateHeader: 'Du har inga existerande produktionsanläggningar att välja bland',
                emptyStateDescription: 'Lägg till en ny produktionsanläggning för att lägga till som information',
                emptyStateSearch: 'Inga produktionsanläggningar matchade din sökning',
            },
            113: {
                createButtonCaption: 'Lägg till',
                createAndCloseButtonCaption: 'Lägg till och stäng',
                emptyStateHeader: 'Du har inga existerande leverantörer att välja bland',
                emptyStateDescription: 'Lägg till en ny leverantör för att lägga till som information',
                emptyStateSearch: 'Inga leverantörer matchade din sökning',
            },
            114: {
                createButtonCaption: 'Lägg till',
                createAndCloseButtonCaption: 'Lägg till och stäng',
                emptyStateHeader: 'Du har inga existerande innehav att välja bland',
                emptyStateDescription: 'Lägg till ett nytt innehav för att lägga till som information',
                emptyStateSearch: 'Inga innehav matchade din sökning',
            },
            118: {
                createButtonCaption: 'Lägg till',
                createAndCloseButtonCaption: 'Lägg till och stäng',
                emptyStateHeader: 'Du har inga existerande relativa mätvärden att välja bland',
                emptyStateDescription: 'Lägg till ett ny relativt mätvärde för att lägga till som information',
                emptyStateSearch: 'Inga relativa mätvärden matchade din sökning',
            },
            120: {
                createButtonCaption: 'Lägg till',
                createAndCloseButtonCaption: 'Lägg till och stäng',
                emptyStateHeader: 'Du har inga existerande produkter/tjänster att välja bland',
                emptyStateDescription: 'Lägg till ett ny produkt/tjänst för att lägga till som information',
                emptyStateSearch: 'Inga produkter/tjänster matchade din sökning',
            },
            125: {
                createButtonCaption: 'Lägg till',
                createAndCloseButtonCaption: 'Lägg till och stäng',
                emptyStateHeader: 'Du har inga existerande datum att välja bland',
                emptyStateDescription: 'Lägg till ett nytt datum för att lägga till som information',
                emptyStateSearch: 'Inga datum matchade din sökning',
            },
        },
        closeForm: 'Stäng formulär',
    },
    'modules.addon': {
        emptyStateHeader: 'Ej aktiverad',
        emptyStateDescription: 'Denna tilläggstjänst är inte aktiverad ännu för ditt användarkonto.',
    },
    'modules.influence': {
        requestFromLabel: 'Detta är en informationsförfrågan från:',
    },
    'modules.itemStatistics': {
        inconsistentData: 'Inkonsekvent data',
        inconsistentDataTooltipText: 'Datan består av värden med olika enheter och korrekt data kan därför inte visas.',
        latestAnswerTooltipText: 'Procentsatserna baseras på det senaste svaret från varje organisation',
        constructedTooltipText:  '{{ percentage }} har svarat "{{ answer }}" - {{ organizationsAnswered }} av {{ organizationsTotal }} organisationer',
    },
    'modules.verification': {
        organizationName: 'Företagsnamn',
        registrationNumber: 'Organisationsnummer',
        contactPerson: 'Kontaktperson',
        city: 'Ort',
        cityAndDate: 'Ort och datum',
        signature: 'Underskrift',
        digitallySigned: 'Underskriven digitalt',
        reference: 'Referens',
        name: 'Namn',
        email: 'Emailadress',
        phoneNumber: 'Telefon',
    },
    ActivityWhen: 'Startdatum',
    ActivityUntil: 'Slutdatum',
    UploadImage: 'Ladda upp bild',
    UploadFile: 'Ladda upp fil',
    Guidance: 'Vägledning',
    GuidanceVideo: 'Titta på informationsvideo',
    Loading: 'Laddar',
    City: 'Ort',
    Back: 'Tillbaka',
    'modules.editor': {
        title: '{{editortype}} {{objecttypeword}}',
        create: 'Skapa',
        edit: 'Redigera',
    },
    'modules.notifications': {
        emptyStateHeader: 'Det finns inga notifieringar att visa',
        emptyStateDescription: 'När det finns notifieringar kommer de att visas här',
        navBar: {
            emptyStateHeader: '<i class="fa fa-bell-o"></i><br />Inga notifieringar',
        },
        eventLog: {
            emptyStateHeader: 'Det finns ingenting att visa i er händelselogg',
            emptyStateDescription: 'När det finns något att visa så dyker det upp här',
        },
        emailTypes: {
            email: 'ett email',
            colleagueInvitation: 'en inbjudan',
            valueChainInvitation: 'en inbjudan',
            valueChainReminder: 'en påminnelse',
        },
        eventSentences: {
            createdByType: {
                15: 'skapade en aktivitet',
                18: 'laddade upp ett dokument',
                44: 'skapade en kommentar',
                50: 'lade till en länk',
                91: 'skapade ett Sustainability Room',
                106: 'laddade upp ett certifikat',
                107: 'lade till en produktionsanläggning',
            },
            removedByType: {
                15: 'tog bort en aktivitet',
                18: 'tog bort ett dokument',
                44: 'tog bort en kommentar',
                50: 'tog bort en länk',
                91: 'tog bort ett  Sustainability Room',
                106: 'tog bort ett certifikat',
                107: 'tog bort en produktionsanläggning',
            },
            influenceFulfilled: 'uppfyllde en informationsförfrågan',
            influenceUnfulfilled: 'ouppfyllde en informationsförfrågan',
            influenceSigned: 'Signerade',
            questionAnswered_specific: 'svarade <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> på frågan <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            questionAnswered: 'besvarade en fråga',
            measureAnswered: 'lade till mätbar data',
            measureAnswered_specific: 'lade till mätbar data <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> på <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            parameterAnswered: 'besvarade en parameter',
            categorized: 'kategoriserade <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> till <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            uncategorized: 'avkategoriserade <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> från <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            textPart_sourceToTarget: '<span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> till <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            textPart_sourceFromTarget: '<span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> från <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            signedIn: 'loggade in',
            moved: 'flyttade',
            updated: 'uppdaterade {{objectTypeWord}} <span class=\'itemText\'>{{objectText}}</span>',
            created: '{{creationTerm}} {{objectTypeWord}} <span class=\'itemText\'>{{objectText}}</span>',
            deleted: 'raderade {{objectTypeWord}} <span class=\'itemText\'>{{objectText}}</span>',
            deletedDataRelation: 'tog bort en relation',
            createdDataRelation: 'lade till en relation',
            updatedDataRelation: 'uppdaterade en relation',
            deletedDataRelation_specific: 'tog bort <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> från <span class=\'targetText itemText\'>{{targetText_cut}}</span>',
            createdDataRelation_specific: 'lade till <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> till <span class=\'targetText itemText\'>{{targetText_cut}}</span>',
            updatedDataRelation_specific: 'flyttade <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> till <span class=\'targetText itemText\'>{{targetText_cut}}</span>',
            sentTo: 'skickade <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> till <span class=\'targetText itemText\'>{{targetText_cut}}</span>',
            deletedDataRelation_specific_withObjectTypeWord: 'tog bort {{sourceTypeWord}} <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> från <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            createdDataRelation_specific_withObjectTypeWord: 'lade till {{sourceTypeWord}} <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> till <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            updatedDataRelation_specific_withObjectTypeWord: 'flyttade {{sourceTypeWord}} <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> till <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            createdVisibilityTag: 'gjorde {{sourceTypeWord}} <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> synlig i <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            individualUserUpdatedOneself: 'uppdaterade sina kontoinställningar',
            sendAndAttestInvoice: 'aktiverade nya tjänster för <span class=\'itemText\'>{{orgname}}</span>',
            updatedBillingInformation: 'uppdaterade faktureringsuppgifter för <span class=\'itemText\'>{{orgname}}</span>',
            createdRequirement: 'lade till ett krav',
            updatedRequirement: 'ändrade ett krav',
            deletedRequirement: 'tog bort ett krav',
            updatedOrganization: 'uppdaterade organisationsinställningar för <span class=\'itemText\'>{{orgname}}</span>',
            updatedOrganizationCustomId: 'uppdaterade <span class=\'text-capitalize\'>Anpassat ID</span> på en organisation till <strong>{{value}}</strong>',
            sentEmailToUserOnOrg: 'skickade {{emailNoun}} till <span class=\'targetText itemText\' title=\'{{emailAddress}}\'>{{username}}</span> på <span class=\'targetText itemText\'>{{orgname}}</span>{{endingText}}',
            sentEmailToUser: 'skickade {{emailNoun}} till <span class=\'targetText itemText\' title=\'{{emailAddress}}\'>{{username}}</span>{{endingText}}',
            sentEmailToAddress: 'skickade {{emailNoun}} till <span class=\'targetText itemText\'>{{emailAddress}}</span>{{endingText}}',
            sentEmail: 'skickade {{emailNoun}}{{endingText}}',
            sentencePart_inValueChain: ' i Data Collector',
        },
    },
    EventLog: 'Händelselogg',
    Swedish: 'Svenska',
    English: 'Engelska',
    'languages.byCode': {
        generic: 'Generic',
        en: 'Engelska',
        sv: 'Svenska',
        cs: 'Tjeckiska',
        de: 'Tyska',
        es: 'Spanska',
        pl: 'Polska',
        zh: 'Kinesiska (förenklad)',
        nb: 'Norska',
        da: 'Danska',
        fi: 'Finska',
        fr: 'Franska',
        pt: 'Portugisiska',
        af: 'Afrikaans',
        it: 'Italienska',
        hu: 'Ungerska',
        vi: 'Vietnamesiska',
    },
    'languages.settings': {
        sameInterfaceAndPreferredContentLanguage: '{{language}} som gränssnittsspråk och i mottagna förfrågningar (om tillgängligt)',
        differentInterfaceAndPreferredContentLanguage: '{{language_ui}} som gränssnittsspråk. {{language_content}} i mottagna förfrågningar (om tillgängligt)',
    },
    locales: {
        generic: 'Generic',
        'en-US': 'Engelska',
        'sv-SE': 'Svenska',
        'cs-CZ': 'Tjeckiska',
        'de-DE': 'Tyska',
        'es-ES': 'Spanska',
        'pl-PL': 'Polska',
        'zh-Hans': 'Kinesiska (förenklad)',
        'nb-NO': 'Norska',
        'da-DK': 'Danska',
        'fi-FI': 'Finska',
        'fr-FR': 'Franska',
        'pt-PT': 'Portugisiska',
        'af-ZA': 'Afrikaans',
        'it-IT': 'Italienska',
        'hu-HU': 'Ungerska',
        'vi-VN': 'Vietnamesiska',
    },
    ShowLinkage: 'Visa korsreferenser',
    HideLinkage: 'Dölj korsreferenser',
    Sign_Verb: 'Skriv under',
    CreateNew: 'Skapa ny',
    AddNew: 'Lägg till ny',
    Linkages: 'Korsreferenser',
    LinkagesDescription: 'Alla områden från andra ramverk som är länkade till:',
    menu: {
        UpgradeAccount: 'Uppgradera ert konto',
        Account: 'Konto',
        AccountSettings: 'Kontoinställningar',
        OrganizationUsers: 'Användare',
        OrganizationSettings: 'Organisationsinställningar',
        ChangeOrganization: 'Ändra organisation',
        SignOut: 'Logga ut',
    },
    'modules.dataStorage': {
        11: {
            emptyStateHeader: 'Ni har ännu inte lagt till några frågor',
            emptyStateDescription: 'Här samlas alla frågor som ni lägger till.',
        },
        15: {
            emptyStateHeader: 'Ni har ännu inte lagt till några aktiviteter',
            emptyStateDescription: 'Här samlas alla aktiviteter som ni lagt till i ert användarkonto. Ni kan även skapa nya aktiviteter här.',
        },
        18: {
            emptyStateHeader: 'Ni har ännu inte lagt till några dokument',
            emptyStateDescription: 'Här samlas alla dokument som ni lagt till i ert användarkonto. Ni kan även ladda upp nya dokument här.',
        },
        21: {
            emptyStateHeader: 'Ni har ännu inte lagt till några mätvärden',
            emptyStateDescription: 'Här samlas alla mätvärden som ni lägger till.',
        },
        25: {
            emptyStateHeader: 'Ni har ännu inte lagt till någon mätbar data',
            emptyStateDescription: 'Här samlas all mätbar data som ni lägger till.',
        },
        31: {
            emptyStateHeader: 'Ni har ännu inte besvarat någon fråga',
            emptyStateDescription: 'Här samlas alla svar på frågor som ni besvarat i ert användarkonto.',
        },
        44: {
            emptyStateHeader: 'Ni har ännu inte lagt till några kommentarer',
            emptyStateDescription: 'Här samlas alla kommentarer som ni lagt till i ert användarkonto. Ni kan även skapa nya kommentarer här.',
        },
        50: {
            emptyStateHeader: 'Ni har ännu inte lagt till några länkar',
            emptyStateDescription: 'Här samlas alla länkar som ni lagt till i ert användarkonto. Ni kan även lägga till nya länkar här.',
        },
        90: {
            emptyStateHeader: 'Ni har ännu inte lagt till några media',
            emptyStateDescription: 'Här samlas all media som ni lagt till i ert användarkonto. Ni kan även lägga till nya media här.',
        },
        91: {
            emptyStateHeader: 'Ni har ännu inte skapat något Sustainability Room',
            emptyStateDescription: 'Klicka på Skapa nytt för att komma igång',
        },
        93: {
            emptyStateHeader: 'Ni har ännu inte skapat något Widget',
            emptyStateDescription: 'Klicka på Skapa ny för att komma igång',
        },
        106: {
            emptyStateHeader: 'Ni har ännu inte lagt till några certifikat',
            emptyStateDescription: 'Här samlas alla certifikat som ni lagt till i ert användarkonto. Ni kan även ladda upp nya certifikat här.',
        },
        107: {
            emptyStateHeader: 'Ni har ännu inte lagt till några produktionsanläggningar',
            emptyStateDescription: 'Här samlas alla produktionsanläggningar som ni lagt till i ert användarkonto. Ni kan även lägga till nya produktionsanläggningar här.',
        },
        118: {
            emptyStateHeader: 'Ni har ännu inte lagt till några relativa mätvärden',
            emptyStateDescription: 'Här samlas alla relativa mätvärden som ni lagt till i ert användarkonto. Ni kan även lägga till nya relativa mätvärden här.',
        },
        120: {
            emptyStateHeader: 'Ni har ännu inte lagt till några produkter/tjänster',
            emptyStateDescription: 'Här samlas alla produkter/tjänster som ni lagt till i ert användarkonto. Ni kan även lägga till nya produkter/tjänster här.',
        },
        125: {
            emptyStateHeader: 'Ni har ännu inte lagt till några datum',
            emptyStateDescription: 'Här samlas alla datum som ni lagt till i ert användarkonto',
        },
        71: {
            emptyStateHeader: 'Ni har ännu inte skapat någon struktur',
            emptyStateDescription: '',
        },
        generic: {
            emptyStateHeader: 'Ni har ännu inte lagt till någon information',
            emptyStateDescription: 'Här samlas all information som ni lägger till.',
        },
    },
    In: 'I',
    To2: 'I',
    DetailedInformation: 'Detaljerad information',
    Data: 'Data',
    SeeMore: 'Se mer',
    Created: 'Skapade',
    Updated: 'Uppdaterade',
    Deleted: 'Tog bort',
    Deleted_v2: 'Borttagen',
    Item_IA: 'ett objekt',
    Item_DA: 'Objektet',
    Items: 'Objekten',
    Statement_IA: 'En kommentar',
    Structure_IA: 'En struktur',
    User_IA: 'En användare',
    QuestionAnswer_IA: 'Ett svar på en fråga',
    MeasureAnswer_IA: 'Mätbar data',
    Statement_DA: 'Kommentaren',
    Structure_DA: 'Strukturen',
    User_DA: 'Användaren',
    QuestionAnswer_DA: 'Svaret',
    MeasureAnswer_DA: 'Mätbara datan',
    Organization_IA: 'Ett företag',
    Organization_DA: 'Företaget',
    Embed: 'Media',
    Embed_IA: 'Media',
    Embed_DA: 'Mediat',
    Embeds: 'Media',
    Influence_Noun_IA: 'En informationsförfrågan',
    Influence_Noun_DA: 'Informationsförfrågan',
    VisibilityTag_IA: 'En synlighetstagg',
    VisibilityTag_DA: 'Synlighetstaggen',
    validationMessages: {
        email: 'Ange en giltig emailadress',
        required: 'Krävs',
        notANumber: 'Måste vara ett heltal',
        notValidDeviationPercentage: 'Måste vara ett positivt heltal mellan 0 och 999',
        stringTooShort: 'Texten är för kort',
        newEmailAlreadyExists: 'Den angivna nya emailadressen finns redan',
        swedishOrganizationNumber: 'Ange ett svenskt organisationsnummer (XXXXXX-XXXX)',
        vatRegOrGlnNumber: 'Du måste ange antingen organisationsnummer, momsregistreringsnummer eller glnnummer',
        password: 'Minst 8 tecken långt med små bokstäver (a-ö), stora bokstäver (A-Ö) och siffror (0-9)', // The dashes are NON-BREAKING HYPHEN
    },
    'modules.standards.griG4': {
        measuresCreatedByWf: 'Mätvärden gjorda av Worldfavor baserat på GRI G4',
    },
    'modules.sustRoom': {
        childrenHeader: 'Meny',
        editRoomButton: 'Redigera',
        editChildrenButton: 'Lägg till information',
        emptyStateHeader: 'Du har inte lagt till någon information ännu',
        emptyStateDescription: 'Klicka på <strong>Lägg till information</strong> för att komma igång',
    },
    'modules.dashboard': {
        emptyStateHeader: 'Du har ej tillgång till några tjänster i din organisations konto',
        emptyStateDescription: 'Kontakta en användare som administrerar ert konto om du har frågor kring detta.',
        internalReport: {
            header: 'Du har uppgifter kvar att slutföra',
            goto: 'Gå till Tilldelade uppgifter',
            emptyStateHeader: 'Du har slutfört alla dina uppgifter',
            emptyStateDescription: 'Om du vill se alla uppgifter du redan slutfört kan du följa länken nedan.<br /><a href=\'/report/internal\'>Se tilldelade uppgifter</a>',
        },
        organization: {
            editButton: 'Redigera',
        },
        users: {
            header: 'Användare',
            body: 'Worldfavor är bättre när ni arbetar tillsammas',
            manageButton: 'Hantera',
        },
        eventLog: {
            header: 'Senaste händelserna',
            seeAllEvents: 'Se alla händelser',
            body: '',
        },
        promotion: {
            header: '',
            body: '',
            learnMore: 'Lär dig mer om {{title}}',
        },
        visualize: {
            totalViews: 'Totalt antal visningar',
            emptyStateHeader: 'Det finns inga visningar ännu',
        },
        valueChain: {
            sentRequest: 'Skickade förfrågningar',
            fulfilledRequests: 'Besvarade förfrågningar',
            emptyStateHeader: 'Ni har ännu inte skickat några förfrågningar',
        },
        report: {
            completed: 'Avklarade förfrågningar',
            emptyStateHeader: 'Ni har inga oavklarade informationsförfrågningar',
        },
    },
    'modules.mailHistory': {
        pageHeader: 'Emailhistorik',
        mailHistoryFor: 'Emailhistorik för {{name}}',
        emptyState: {
            header: 'Ingen emailhistorik',
            description: 'Inga email har skickats till den här organisationen ännu',
        },
    },
    'modules.pageHeading': {
        helpUrl: {
            title: 'Behöver du hjälp?',
            description: 'Läs hur du använder {{solution}}',
        },
    },
    NowFulfilling: 'Uppfyller nu',
    NoLongerFulfilling: 'Uppfyller inte längre',
    'modules.users': {
        AccessLabel: 'Åtkomst',
        Full: 'Fullständig',
        Restricted: 'Begränsad',
        ViewModeLabel: 'Revisorläge',
        ViewMode: 'Aktivera revisorläge (kan enbart se, inte redigera)',
        RestrictedAccessStatus: 'Begränsad åtkomst',
        FullAccessStatus: 'Fullständig åtkomst',
        CustomUserRootButton: 'Ange begränsad åtkomst',
        ResetToOrgRootButton: 'Återställ fullständing åtkomst',
        SendInviteMail: 'Skicka inbjudningsmail',
        RemoveFromOrganization: 'Ta bort från organisationen',
        confirmRemoveFromOrganization: {
            header: 'Ta bort {{username}} från organisationen',
            message: '<i class="fa fa-user-times"></i><div class="tr"><div class="headerText">Är du säker på att du vill ta bort användaren?</div><strong>{{username}}</strong> kommer att tas bort och kommer inte längre ha tillgång till ert konto.\n\nOm du fortfarande vill ta bort användaren, klicka på knappen <strong>Ta bort användare</strong> nedan.',
            button: 'Ta bort användare',
        },
        alreadyInOrganization: {
            modalTitle: 'Användaren finns redan',
            modalMessage: 'En användare med den här emailadressen finns redan i organisationen: <span style=\'font-size:14px;\'><br /><br /><b>{{name}}</b><br />{{email}}</span><br />',
        },
        existingUserAddedToOrganization: {
            modalTitle: 'Användaren finns redan',
            modalMessage: 'En användare med den här emailadressen finns redan sedan tidigare i Worldfavor. Användaren har har nu lagts till i organisationen. <span style=\'font-size:14px;\'><br /><br /><b>{{name}}</b><br />{{email}}</span><br />',
        },
    },
    'modules.sustainabilityRoom': {
        activityFeed: {
            emptyStateHeader: 'Sätt aktiviteter till <strong class=\'text-italic\'>Publik Information</strong> för att visa dem här',
            emptyStateDescription: 'Genom att ändra synlighetsstatusen på aktiviteter till <strong>Publik Information</strong> blir de synliga i ert Sustainability Room.<br />Klicka på alternativknappen på en aktivitet i Manage och klicka på <strong>Synlig i</strong> för att ändra synlighetsinställningar.',
            emptyStateImage: '/assets/img/sustRoom_visibilityEmptyState_sv.png',
        },
        pickerDescription: 'Välj vad du vill inkludera i ert Sustainability Room',
        urlRouteName: 'Anpassa URL (portal.worldfavor.com/room/\'Anpassa denna del nedan\')',
        topAdminInfo: '<strong>Detta Sustainability Room är LIVE</strong> och nås enbart via följande länk: <a href=\'{{linkUrl}}\' target=\'_blank\'>{{linkUrl}}</a>. Anpassa länken under <strong>Redigera</strong>.<br /><br /><strong>Sustainability Room visar det ni satt som Publik Information</strong>. Klicka på alternativknappen på information ni vill visa här och ändra <strong>Synlig i</strong> till <strong>Publik Information</strong>.',
    },
    'modules.invoice': {
        invoiceTableHeader: {
            invoice: 'Faktura',
            created: 'Skapad',
            status: 'Status',
            deliveryMethod: 'Leverans metod',
            dueDate: 'Förfallodag',
            amount: 'Belopp',
            vat: 'inkl. moms',
            eMail: 'Email',
            post: 'Brev',
        },
        invoiceStages: {
            unpaid: 'Obetald',
            paid: 'Betald',
            overdue: 'Försenad',
            paymentSent: 'Betalning skickad',
        },
        expired: 'utgånget',
        subscriptionValidity: '*Varje abonnemang är giltigt för exakt ett år från inköpsdatum.',
    },
    'modules.export': {
        csv: {
            exportButton: 'Exportera',
            exportItemData: {
                modalHeader: 'Exportera data',
            },
            columns: {
                relativeMeasureStepObject: 'Beräkningssteg {{step}}',
                relativeMeasureStepSourceValue: 'Beräkningssteg {{step}} Värde',
                relativeMeasureStepUnit: 'Beräkningssteg {{step}} Enhet',
                relativeMeasureStepOperation: 'Beräkningssteg {{step}} Operation',
            },
            popover: {
                header: 'Exportera till CSV-fil',
                step1: '1. Om du vill begränsa exporten, använd filtreringen i Data Collector',
                step2: '2. Välj vilka kolumner du vill exportera',
                step3: '3. Du kan ändra namnen på kolumnerna',
                step4: '4. Klicka på Ladda ner',
                columns: 'Kolumner',
                rows: 'Rader',
                settings: 'Inställningar',
                download: 'Ladda ner CSV',
                fieldSeparator: 'Fältseparator',
                decimalSeparator: 'Decimaltecken',
                textDelimiter: 'Textavgränsare',
                comma: 'Komma',
                semiColon: 'Semikolon',
                dot: 'Punkt',
                doubleQuotes: 'Dubbla',
                singleQuotes: 'Enkla',
                dateTimeFormat: 'Tidsformat',
                includeSeconds: 'Inkludera sekunder',
                includeSecondsInfo: 'Ska tidsangivelser inkludera sekunder?',
                onlyExportLatestData: 'Exportera bara senaste data',
                useHorizontal: 'Horisontell export',
                convertMeasurements: 'Konvertera mätdata',
                includeCategorizations: 'Inkludera kategorisering',
                groupCategorizations: 'Gruppera kategorier',
            },
        },
        pdf: {
            exportButton: 'Exportera till PDF',
            popover: {
                header: 'Exportera data till PDF-fil',
                optionsHeader: 'Inkludera:',
                options: {
                    coverPage: 'Försättsblad',
                    pageHeading: 'Sidans huvudrubrik',
                    userData: 'Bifogad information',
                    measureDataChart: 'Graf för mätbar data',
                    tooltip: {
                        included: 'Inkluderat',
                        excluded: 'Exkluderat',
                    },
                },
                stylesHeader: 'Dokumentstil',
                styles: {
                    default: 'Standard',
                    compact: 'Kompakt',
                    minimal: 'Enkel',
                    elegant: 'Elegant',
                },
                note: 'Notera: Valda filter och sökresultat kommer påverka innehållet i dokumentet',
                isoStandardInfo: 'Innehållet som du vill exportera innehåller upphovsrättsskyddat material från ISO-standarder. Upphovsrättsinnehavaren tillåter inte att materialet kopieras och det kommer därför exkluderas från exporten.',
                preview: {
                    header: 'Förhandsgranskning',
                },
                previewButton: 'Förhandsgranskning',
                downloadButton: 'Ladda ner',
            },
            errorMessages: {
                couldNotDownloadFile: 'Filen kunde inte laddas ner, vänligen ladda om sidan och försök igen.',
                contactSupportMessage: 'Generering av PDF tar längre tid än förväntat. Uppdatera sidan och försök igen.\n\nOm problemet fortsätter, använd chatten för att kontakta vår support så hjälper vi dig.',
            },
        },
    },
    'modules.objectViewer': {
        children: 'Innehåll',
        childrenByUser: 'Era svar',
        relatedContent: 'Korsreferenser',
        parents: 'Förekommer i',
        parentsByUser: 'Tillagd i kategorier',
        parentsByUser_historic: 'Tidigare tillagd i kategorier',
        structureChildrenByUser: 'Innehåll',
        attachedInformation: 'Er bifogade information',
        requestsForInformation: 'Informationsförfrågningar',
        answerToAQuestion: 'Svar på en fråga',
        categorizations: 'Kategoriseringar',
        visibility: 'Synlighet',
        showGuidance: 'Visa vägledning',
        hideGuidance: 'Dölj vägledning',
        forThePeriod: 'För perioden',
        whenUntilDate: 'Startdatum / Slutdatum',
        wfInformation: 'Worldfavor Information',
        items: 'objekt',
        expandAll: 'Expandera allt',
        emptyStateAttachedInfo1: 'Lägg till information här genom att klicka på',
        emptyStateAttachedInfo2: 'knappen ovan',
        emptyStateCategorization1: 'Kategorisera det här området genom att klicka på',
        emptyStateCategorization2: 'knappen ovan',
        readMore: 'Läs mer',
        readLess: 'Dölj',
        emptyStateSearch: 'Inga objekt matchade din sökning',
    },
    'modules.scorecard': {
        addRemoveButton: 'Lägg till/ta bort mätvärden',
        emptyStateHeader: 'Ni har ännu inte lagt till någon mätbar data',
        emptyStateDescription: 'Alla mätbara data som du lägger till kommer att visas för allmänheten.',
    },
    'modules.publicProfile': {
        emptyStateText: 'There are no results for your search keywords',
        emptyStateDescription: 'Please try another search',
    },
    AddTo: 'Lägg till i kategori',
    SendTo: 'Skicka till',
    AssignTask: 'Tilldela till kollega',
    AssignedToColleague: 'Tilldelad till kollega',
    AssignedToYou: 'Tilldelad till dig',
    OpenObjectViewer: 'Detaljerad vy',
    Details: 'Detaljerad vy',
    TaggedTo: 'Taggat till',
    UrlRouteName: 'Anpassa URL',
    BackgroundImage: 'Bakgrundsbild',
    UploadBackgroundImage: 'Ladda upp bakgrundsbild',
    SomethingWentWrongDuringSaving: 'Något gick fel vid sparningen. Försök igen.',
    Belonging: 'Tillhörighet',
    Categorizations: 'Kategoriseringar',
    ChooseAttachedInformation: 'Välj bifogad information',
    AttachedInformation: 'Bifogad information',
    AttachInformation: 'Bifoga information',
    Attach: 'Bifoga',
    AttachedTo: 'Bifogat till',
    AttachTo: 'Bifoga till',
    Menu: 'Meny',
    VisibleIn: 'Synlig i',
    SetVisibility: 'Välj synlighet for',
    'modules.sunburstChart': {
        outermostCircleInfo: '<strong>Yttersta cirkeln</strong>: Er information',
        innerCirclesInfo: '<strong>Inre cirklar</strong>: Kategorier och hierarkier i ramverket',
    },
    AvailableSoon: 'Tillgänglig snart',
    Clear: 'Rensa',
    QuestionAnswerTypesById: {
        1: 'Behandlas',
        2: 'Ej relevant',
        3: 'Nej',
        4: 'Ja',
    },
    SetRequirement: 'Ange krav',
    Exception: 'Undantag',
    SetException: 'Ange undantag',
    AddException: 'Lägg till undantag',
    RemoveException: 'Ta bort undantag',
    SetFulfillment: 'Ange uppfyllnad',
    ConfirmDeletionOrganizationSpecificRequirement: 'Vill du ta bort undantaget?',
    SendMail: 'Skicka emailmeddelande',
    Requirement_IA: 'Ett krav',
    Requirement_DA: 'Kravet',
    Category: 'Kategori',
    RegistrationNumber: 'Organisationsnummer',
    Events: 'Händelser',
    Never: 'Aldrig',
    ReadMore: 'Läs mer',
    InboxTitle: 'Din inkorg',
    InboxEmptyState: 'Din inkorg är tom',
    InboxDescription: 'Se och arbeta med information som har skickats till dig.',
    InboxWhoSent: 'skickade detta till dig',
    'modules.metadata': {
        consolidatedData: {
            title: 'Aggregerad data från Data Collector',
        },
        popover: {
            itemsLimitMessage: 'Visar endast de 5 senaste objekten, klicka nedan för att se fler',
            showMore: 'Visa mer',
            showLess: 'Dölj',
        },
        expandedObjectViewer: {
            showData: 'Visa er data',
            hideData: 'Dölj er data',
            noData: 'Ni har ingen data att visa',
        },
        emptyStateMessages: {
            emptyStateAttachedInfo1: 'Lägg till information här genom att klicka på',
            emptyStateAttachedInfo2: 'knappen ovan',
            emptyStateCategorization1: 'Lägg till denna post genom att trycka på',
            emptyStateCategorization2: 'knappen ovan',
            content: 'Det finns inget innehåll',
            linkages: 'Denna punkt är inte kopplad till någon annan post i Worldfavor',
            answers: 'Det finns inga svar för objektet',
        },
    },
    SearchForKeywords: 'Sök efter nyckelord',
    NoResults: 'Inga resultat',
    Unanswered: 'Obesvarade',
    TotalAnswered: 'Totalt besvarade',
    Status: 'Status',
    PreviewOfMail: 'Förhandsgranskning av emailmeddelande',
    Reported: 'Rapporterat',
    noInformationEmptyState: {
        header: 'Ni har ännu inte lagt till någon information',
        description: 'Ni kan lägga till information genom att klicka på knappen här ovan.',
    },
    GoTo: 'Gå till',
    Message: 'Meddelande',
    'modules.promotion': {
        demoBookingPrompt: {
            header: 'Få en demo av {{name}}',
            description: 'Hej! Fyll i formuläret och klicka på Skicka så återkommer vi till dig för en demo inom kort. Som inloggad användare har vi redan din emailadress.<br /><br />Genom att be om en demo binder du dig inte till något.',
            messageInputLabel: 'Valfritt meddelande',
            submitButtonCaption: 'Skicka',
            errorMessage: 'Något gick fel vid bokningen. Försök igen.',
            confirmationText: '<strong>Tack!</strong><br /><br />Vi kontaktar dig för en demo inom kort.',
        },
        orderContactPrompt: {
            description: 'Hej! Fyll i formuläret och klicka på Skicka så återkommer vi till dig inom kort. Som inloggad användare har vi redan din emailadress.',
            errorMessage: 'Något gick fel. Försök igen.',
            confirmationText: '<strong>Tack!</strong><br /><br />Vi kontaktar dig inom kort.',
        },
        isoStandards: {
            gridCard: {
                title: 'Lägg till fler ISO-standarder till ert konto',
                description: 'Worldfavor erbjuder digitala arbetsverktyg för ett flertal ISO-standarder',
                button: 'Lägg till ISO-standarder',
                footerText: 'ISO-standarder tillhandahålls i samarbete med SIS,',
                sis: 'Swedish Standards Institute',
            },
        },
        wfPromotions: {
            videoCaptions: 'Reasons why you should watch this amazing video',
            banner: {
                text: 'Want to know more about how others are taking the next step towards becoming a sustainable company?',
                buttonText: 'Get in touch',
            },
        },
    },
    'modules.eCommerce': {
        productPicker: {
            stepTitle: 'Välj tjänster',
            buttonLabel: 'Gå vidare',
            errorMessage: 'Välj minst en tjänst för att gå vidare',
            alertModal: {
                title: 'Ursäkta, något gick fel',
                message: 'Ett oväntat fel inträffade.\nLadda om sidan och försök igen.',
            },
        },
        checkout: {
            stepTitle: 'Beställ',
            headerTitle: 'Beställ',
            headerDescription: 'Fyll i faktureringuppgifter',
            buttonLabel: 'Förhandsgranska faktura',
            table: {
                record: 'Tjänst',
                billingPeriod: 'Gäller till och med',
                numberOfYears: 'År',
                costPerMonth: 'Summa/månad',
                costPerYear: 'Summa/år',
                cost: 'Summa',
                info: 'Alla summor är exklusive moms. Alla tjänster faktureras årsvis.',
                total: 'Totalt: ',
                excludingVat: '(exkl. moms)',
            },
            billingInformationTitle: 'Faktureringsuppgifter',
            acceptTerms: 'Jag accepterar',
            termsAndConditions: 'tjänsteavtalet',
            deliveryByPost: 'Brev',
        },
        invoicePreview: {
            stepTitle: 'Förhandsgranska faktura',
            headerTitle: 'Bekräfta beställning',
            headerDescription: 'Kontrollera att alla uppgifter stämmer innan du bekräftar',
            buttonLabel: 'Bekräfta beställning',
            alertModal: {
                title: 'Ursäkta, ett oväntat fel inträffade i beställningsprocessen',
                message: 'Fakturan har ej genererats och inga tjänster har aktiverats.\nLadda om sidan och försök igen.\n\nVi beklagar detta.',
            },
        },
        finalStep: {
            stepTitle: 'Beställning genomförd',
            headerTitle: 'Tack!',
            headerDescription: 'Du kan börja använda era nya tjänster på en gång.',
            message: 'Forsätt göra världen hållbar!',
            buttonLabel: 'Stäng',
            alertModal: {
                title: 'Ursäkta, ett oväntat fel inträffade i beställningsprocessen',
                message: 'Fakturan har ej genererats och inga tjänster har aktiverats.\nLadda om sidan och försök igen.\n\nVi beklagar detta.',
            },
        },
        exitModal: {
            warning: 'Är du säker?',
            title: 'Är du säker på att du vill avsluta beställningsprocessen?',
            message: 'Notera att alla valda tjänster och ifylld information kommer att rensas.',
            primaryButton: 'Ja',
            backButton: 'Nej, ta mig tillbaka till beställningen',
        },
        termsAndConditions: {
            title: 'Tjänsteavtal',
            message: 'Text for terms & conditions goes here',
            errorMessage: 'Acceptera tjänsteavtalet för att fortsätta',
        },
        moreInfo: {
            title: 'Mer information om tjänsten',
        },
    },
    'modules.mailPreview': {
        editEmailInfoMessage: 'Skriv ett meddelande med lite information och bjud in den nya användaren',
        cancelEditing: 'Avsluta redigering',
        editMail: 'Redigera',
        saveChangesMessage: 'Spara dina ändringar för att uppdatera förhandsgranskningen',
        emailPreview: 'Förhandsgranskning av emailmeddelande',
        confirmationMessage: 'Emailmeddelandet har nu skickats till',
        preview: 'Förhandsgranska',
        noContactSelected: 'Ingen kontakt valts',
        noContactSelectedMessage: 'Välj en kontaktperson för att fortsätta',
        chooseContact: 'Välj en kontaktperson för att skicka ett email',
        previewOfSentMail: 'Skickat email',
        previewOfFailedMail: 'Misslyckat email',
        historicMailPreviewUnavailable: 'Emailet kan inte visas eftersom det skapades i en äldre version av Worldfavor',
        historicMailPreviewError: 'Något gick fel när emailet skulle visas',
        errorModal: {
            message1: 'Det finns inga krav eller kontakter som för denna organisation',
            message2: 'Vänligen lägg till krav / kontakter innan du kan skicka ett email',
        },
        exitModal: {
            warning: 'Är du säker?',
            title: 'Är du säker på att du vill avsluta?',
            message: 'Dina ändringar kommer inte att sparas.',
            primaryButton: 'Ja',
            backButton: 'Nej, ta mig tillbaka',
        },
        serverErrorModal: {
            title: 'Ursäkta, något gick fel',
            message: 'Ett oväntat fel inträffade.\nLadda om sidan och försök igen.',
        },
    },
    billingInformation: {
        ourReference: 'Vår referens',
        address: 'Postadress',
        address2: 'Adressrad 2',
        contactEmail: 'Emailadress till kontaktperson',
        contactName: 'Förnamn och efternamn på kontaktperson',
        countryCode: 'Landsnummer',
        vatNumber: 'Momsregistreringsnummer',
        zipCode: 'Postnummer',
        deliveryMethod: 'Leverans av faktura',
    },
    'modules.questionAnswering': {
        useLatestAnswer: 'Använd senaste svar',
        useLatestAnswer_info: '',
    },
    'modules.upgradeAccount': {
        pageTitle: 'Uppgradera ert konto',
        userLevels: {
            title: 'Användarnivåer',
            readMore: 'LÄS MER',
            highlight: 'GRUND-FUNKTION',
            manage: {
                title: 'MANAGE',
                description: 'Digitalisera CSR, samordna och rapportering i riktlinjer',
                b1: 'Digitaliserade riktlinjer',
                b2: 'Korsrefererade riktlinjer',
                b3: 'Revisoraccess',
                b4: 'Materialitetsverktyg',
                b5: 'Strategi-ramverk',
            },
            visualize: {
                title: 'VISUALIZE',
                description: 'Visualisera resultat för intressenter, digital hållbarhetsredovisning',
                b1: 'Sustainability Room/Employer Branding Room/Compliance Room',
                b2: 'Website Widgets',
            },
            valuechain: {
                title: 'VALUE CHAIN',
                description: 'Automatisera utvärdering av leverantörer',
                b1: 'Automatisera utvärdering',
                b2: 'Digitala kravpaket',
                b3: 'Aggregerad hållbarhet',
                b4: 'Leverantörsprofil',
                b5: 'Kategorisering',
                b6: 'Rapportering gratis för leverantörer',
            },
        },
        isoStandards: {
            title: 'Tillgängliga ISO-standarder',
            readMore: 'Läs mer om ISO-standarder',
            iso9001: {
                title: 'ISO 9001 - Ledningssystem för kvalitet',
                description: 'ISO 9001 hjälper er att införa ett ledningssystem för kvalitét i organisationen.',
            },
            iso14001: {
                title: 'ISO 14001 - Miljöledningssystem',
                description: 'ISO 14001 hjälper er att införa ett miljöledningssystem i organisationen.',
            },
            iso20121: {
                title: 'ISO 20121 - Hållbara evenemang',
                description: 'ISO 20121 hjälper företag och organisationer att förbättra hållbarheten genom hela evenemangscykeln.',
            },
            iso26000: {
                title: 'ISO 26000 - Socialt ansvarstagande',
                description: 'ISO 26000 är en internationell standard med riktlinjer för organisationers sociala ansvarstagande.',
            },
            iso27001: {
                title: 'ISO 27001 - Informationssäkerhet',
                description: 'ISO 27001 är en standard som hjälper er att införa ett ledningssystem för informationssäkerhet i organisationen.',
            },
        },
    },
    'modules.manage': {
        ownStructures: {
            emptyStateHeader: 'Du har ännu inte skapat något eget ramverk',
            emptyStateDescription: 'Gå till Egna ramverk för att komma igång',
            editor: {
                emptyStateHeader: 'Det här egna ramverket är tomt',
                emptyStateDescription: 'Klicka på <b>Lägg till</b> ovan för att börja lägga till innehåll',
            },
        },
        internalValueChain: {
            remainingAssignments: 'Uppgifter kvar att utföra: ',
        },
    },
    'modules.organization': {
        introModal: {
            header: {
                title: 'Välkommen till Worldfavor',
                description: 'Bara några små detaljer kvar innan du fortsätter. Se till att er information är korrekt.',
                required: '* Krävs',
            },
            form: {
                orgName: 'Organisationens namn *',
                regNumber: 'Organisationsnummer',
                vatNumber: 'Momsregistreringsnummer',
                glnNumber: 'GLN-nummer',
                country: 'Land *',
                logo: 'Logotyp',
                employeeRange: 'Antal anställda *',
                industries: {
                    label: 'Bransch *',
                    button: 'Lägg till/Ta bort',
                    pickerTitle: 'Välj bransch',
                    pickerDescription: 'Välj er organisations huvudsakliga bransch. Du kan välja fler än en bransch.',
                },
            },
            footer: {
                text: 'Basinformation om er organisation hjälper oss att ge er än bättre Worldfavor-upplevelse.\nNi kan ändra informationen när som helst.',
                submitButton: 'Spara',
            },
        },
    },
    'modules.dateRangePicker': {
        ranges: {
            today: 'Idag',
            lastWeek: 'Senaste 7 dagarna',
            lastMonth: 'Senaste månaden',
            last2Months: 'Senaste 2 månaderna',
            last3Months: 'Senaste 3 månaderna',
            last6Months: 'Senaste 6 månaderna',
            lastYear: 'Senaste året',
            last2Years: 'Senaste 2 åren',
            last3Years: 'Senaste 3 åren',
            customRange: 'Anpassat',
        },
        applyLabel: 'Ange datumintervall',
        cancelLabel: 'Rensa',
    },
    'modules.filters': {
        activeFilterMessage: 'Du har {{count}} aktivt filter',
        activeFilterMessagePlural: 'Du har {{count}} aktiva filter',
        clearAllFilters: 'Rensa alla filter',
        filterByUser: 'Filtrera efter användare',
        filterByOrganization: 'Filtrera per organisation',
        filterByObjectType: 'Filtrera efter objekttyp',
        filterByEventType: 'Filtrera efter händelsetyp',
        filterByDate: 'Filtrera efter datum',
        filterByMailStatus: 'Filtrera efter status',
        filterByMailPurpose: 'Filtrera efter avsikt',
        dateFilterPlaceholder: 'Välj datumintervall',
        mailStatus: {
            sent: 'Skickat',
            failedToSend: 'Kunde ej skickas',
        },
        mailPurpose: {
            sent: 'Skickat',
            failedToSend: 'Kunde ej skickas',
        },
    },
    EditAccess: 'Redigera åtkomst',
    Access: 'Åtkomst',
    Access_IA: 'En åtkomst',
    Access_DA: 'Åtkomsten',
    Parameter: 'Parameter',
    Parameters: 'Parametrar',
    Parameter_IA: 'En parameter',
    Parameter_DA: 'Parametern',
    ParameterValue: 'Parametervärde',
    ParameterValues: 'Parametervärden',
    ParameterValue_IA: 'Ett parametervärde',
    ParameterValue_DA: 'Parametervärdet',
    BillingInformation: 'Faktureringsuppgifter',
    BillingInformations: 'Faktureringsuppgifter',
    BillingInformation_IA: 'Faktureringsuppgifter',
    BillingInformation_DA: 'Faktureringsuppgiften',
    Location_IA: 'En plats',
    Location_DA: 'Platsen',
    Invoice: 'Faktura',
    Invoices: 'Fakturorna',
    Invoice_IA: 'En faktura',
    Invoice_DA: 'Fakturan',
    UniqueReports: 'Unika rapporter',
    RequirementFulfillment: 'Kravuppfyllnad',
    GoalFulfillment: 'Måluppfyllnad',
    VATNumber: 'Momsregistreringsnummer',
    Somebody: 'Någon',
    'modules.locations': {
        primaryAddress: 'Primär adress',
        setOrgPrimaryLocation: 'Ställ in primär adress',
        searchInputPlaceholder: 'Sök efter en plats eller adress',
        manualInputPlaceholder: 'Skriv fullständig adress',
        openInGoogleMaps: 'Öppna i Google Maps',
    },
    ClearFilter: 'Rensa filter',
    Certificate: 'Certifikat',
    Certificates: 'Certifikat',
    Certificate_IA: 'Ett certifikat',
    Certificate_DA: 'Certifikatet',
    ProductionSite: 'Produktionsanläggning',
    ProductionSites: 'Produktionsanläggningar',
    ProductionSite_IA: 'En produktionsanläggning',
    ProductionSite_DA: 'Produktionsanläggningen',
    CustomID: 'Anpassat ID',
    SetCustomID: 'Ange anpassat ID',
    YearlySpend: 'Årlig utgift',
    SetYearlySpend: 'Ange årlig utgift',
    for: 'för',
    UserPosition: 'Befattning',
    AggregatedView: 'Aggregerad vy',
    RawMaterial: 'Råvara',
    CountryOfOrigin: 'Ursprungsland',
    RequirementPackage: 'Kravpaket',
    RequirementPackages: 'Kravpaket',
    AnalyzePackage: 'Analyspaket',
    AnalyzePackages: 'Analyspaket',
    Categorize: 'Kategorisera',
    Share: 'Dela',
    ValidFromDate: 'Giltigt från',
    ValidUntilDate: 'Giltigt till',
    EmployeeCountSpan: 'Antal anställda',
    Issuer: 'Utfärdare',
    LatitudeLongitude: 'Latitud, Longitud',
    CustomFramework: 'Eget ramverk',
    CustomFrameworks: 'Egna ramverk',
    CustomCategory: 'Egen kategori',
    CustomCategories: 'Egna kategorier',
    OwnCategorizations: 'Egna kategoriseringar',
    SustainabilityRoom: 'Sustainability Room',
    SustainabilityRooms: 'Sustainability Rooms',
    SustainabilityRoom_IA: 'Ett Sustainability Room',
    SustainabilityRoom_DA: 'Sustainability Room',
    RequiredFormFieldHeader: 'Se till att du fyllde i alla obligatoriska fält',
    RequiredFormFieldDescription: 'Kontrollera om du fyllde i fälten för alla språk',
    'modules.mailSettings': {
        pageHeader: 'E-postinställningar',
        Notification: 'Notifering',
        ValueChainInvitation: 'Inbjudan',
        ValueChainReminder: 'Påminnelse',
        Active: 'Aktivt',
        NotActive: 'Ej aktivt',
        Time: 'Tid',
        EveryDay: 'Varje dag',
        Every: 'Varje',
        TimePrefix: 'kl',
        Sender: 'Avsändare',
        FromEmail: 'Avsändarmail',
        FromName: 'Avsändarnamn',
        ReplyEmail: 'Svarsmail',
        HelloText: 'Hälsning före mottagarnamn',
        BodyText: 'Brödtext',
        CallToActionText: 'Uppmaning',
        ActionButtonText: 'Text på inloggningsknapp',
        EndingText: 'Avslutning',
        RegardsText: 'Sluthälsning',
        SenderLine1: 'Avsändarrad 1',
        SenderLine2: 'Avsändarrad 2',
        DayOfWeek: 'Veckodag (1-7)',
        HourOfDay: 'Tidpunkt (timme, 0-23)',
        DayOfMonth: 'Dag i månaden (1-31)',
        MonthOfYear: 'Månad på året (1-12)',
        valueChainReminderMessages: {
            editDropdownMessage: 'Redigera påminnelsemeddelanden',
            editFormtitle: 'Redigera påminnelsemeddelanden',
            messagesInformation: 'Inget meddelande visas om det är mindre än en vecka sedan förfrågan aktiverades.',
            messagePreset: {
                timeAgoInfo: 'Meddelande när förfallodatumet saknas eller är mer än en vecka bort',
                timeLeftWarning: 'Meddelande när förfallodatumet är inom en vecka',
                sameDayWarning: 'Meddelande när förfallodatumet är samma dag',
                overdueWarning: 'Meddelande vid passerat förfallodatum',
            },
            validationMessage: 'Vänligen fyll i {{message}}',
            placeholders: {
                placeholderDescription: 'Dessa parametrar kan användas i texterna. De kommer ersättas med riktiga värden när ett email skickas.',
                weeksAgo: 'Tid sedan förfrågan aktiverades uttryckt i veckor. Om mindre än en vecka: "1 dag", "2 dagar" etc, annars: "1 vecka", "2 veckor" etc',
                daysAgo: 'Tid sedan förfrågan aktiverades uttryckt i dagar. "1 dag", "2 dagar" etc',
                weeksLeft: 'Tid kvar till förfallodatumet uttryckt i veckor. Om mindre än en vecka: "0 dagar", "1 dag", "2 dagar" etc, annars: "1 vecka", "2 veckor" etc.<br />(Kräver ett förfallodatum för att meddelandet ska visas)',
                daysLeft: 'Tid kvar till förfallodatumet uttryckt i dagar. "0 dagar", "1 dag", "2 dagar" etc.<br />(Kräver ett förfallodatum för att meddelandet ska visas)',
                dueDate: 'Förfallodatumet i format "7 jan 2018".<br />(Kräver ett förfallodatum för att meddelandet ska visas)',
                authorFullName: 'Fullständigt namn på avsändarpersonen i emailet',
                authorEmail: 'Emailadress till avsändarpersonen i emailet',
                authorPhoneNumber: 'Telefonnummer till avsändarpersonen i emailet',
                authorOrganizationName: 'Ert organisationsnamn',
                recipientFullName: 'Fullständigt namn på kontaktpersonen',
                recipientFirstName: 'Kontaktpersonens förnamn',
                recipientLastName: 'Kontaktpersonens efternamn',
                recipientEmail: 'Kontaktpersonens emailadress',
                recipientOrganizationName: 'Mottagarens organisationsnamn',
            },
        },
    },
    Industry: 'Bransch',
    Industries: 'Branscher',
    Goal: 'Mål',
    SetGoal: 'Ange mål',
    EditDetails: 'Redigera detaljer',
    EditContent: 'Redigera innehåll',
    Event: 'Händelse',
    TagMultiple: 'Tagga flera:',
    TagMultipleTooltip: 'Markera rutan för att snabbare tagga flera objekt till denna kategori',
    WithAttachedInformation: 'Bifogad information finns',
    WithoutAttachedInformation: 'Bifogad information saknas',
    FinishedGoals: 'Avklarade mål',
    UnfinishedGoals: 'Återstående mål',
    MailSetting: 'Inställningar för emailutskick',
    MailSettings: 'Inställningar för emailutskick',
    MailSetting_DA: 'Inställningar för emailutskick',
    MailSetting_IA: 'Inställningar för emailutskick',
    Uncategorize: 'Avkategorisera',
    AttemptedTo: 'Försökte',
    fulfillmentStates: {
        assessmentNeeded: 'Bedömning krävs',
        assessmentPending: 'Bedömning pågår',
        awaitingAssessment: 'Behöver bedömmas',
        reportingNeeded: 'Ska rapporteras på nytt',
        toBeReported: 'Ska rapporteras',
        expired: 'Ej uppfyllt (data har löpt ut)',
        certificateExpired: 'Certifikat har löpt ut',
        partiallyFulfilled: 'Delvis uppfyllt',
        answered: 'Svarat',
        notAnswered: 'Ej besvarat',
        invitationNotSent: 'Inbjudan inte skickad',
        invitationSent: 'Inbjudan skickad',
        deadlineSoon: 'Snart deadline',
    },
    Materiality: 'Väsentlighet',
    Deviation: 'Avvikelse',
    TemporaryPassword: 'Temporärt lösenord',
    Sum: 'Summa',
    Average: 'Genomsnitt',
    Count: 'Antal',
    Embed_Verb: 'Bädda in',
    FulfillmentStatus: 'Uppfyllnadsstatus',
    SelectedOption: 'Valt alternativ',
    'modules.emptyState': {
        genericHeader: 'Det finns ingenting att visa',
        genericDescription: '',
        genericFilteringHeader: 'Ingenting matchade din filtrering',
        genericFilteringDescription: 'Prova med andra filtreringsalternativ',
    },
    'modules.measure': {
        validationMessage: 'Du måste ange siffror',
        extractionSuccessMessage: 'Detta hittades i det inmatade värdet:',
        extractionExactMessage: '',
        useExtractedAsValue: 'Använd detta som värde',
        decimalSymbolInput: 'Du kan använda både komma (,) och punkt (.)\nsom decimaltecken',
        decimalSymbolOutput: 'Decimaltecken i förhandsgranskningen är {{decimalsymbol}}',
        notAvailableInfo: 'Indikera att det inte finns något data tillgänglig för den här perioden genom att kryssa i N/A (not available)',
        deviationLabel: 'Avvikelse från föregående period:',
    },
    ContactPersons: 'Kontaktpersoner',
    'modules.questionAnswerTypes': {
        accredited: 'Ackrediterad',
        compliant: 'Kompatibel',
        notCompliant: 'Ej kompatibel',
        registered: 'Registrerad',
        notRegistered: 'Inte registrerad',
        agree: 'Instämmer',
        doNotAgree: 'Instämmer ej',
        partlyCompliant: 'Delvis kompatibel',
        approved: 'Godkänd',
        notApproved: 'Ej godkänd',
        noTime: 'Ingen tid',
        notApplicable: 'Ej tillämpbar',
        checked: 'Kontrollerat',
        notChecked: 'Ej kontrollerat',
        partly: 'Delvis',
        noInfoAvailable: 'Ingen information tillgänglig',
        dontKnow: 'Vet ej',
        certified: 'Certifierad',
        lowRisk: 'Låg risk',
        mediumRisk: 'Medel risk',
        highRisk: 'Hög risk',
        inProgress: 'Pågående',
    },
    and: 'och',
    glnInputLabelDescription: 'GLN-nummer för registrerad enhet (Om GLN-nummer, dvs lokaliseringsnummer i enlighet med GS1, saknas – vänligen lämna fältet tomt)',
    mathOperations: {
        addition: 'Addition',
        subtraction: 'Subtraktion',
        multiplication: 'Multiplikation',
        division: 'Division',
        add: 'Addera',
        subtract: 'Subtrahera',
        multiply: 'Multiplicera',
        divide: 'Dividera',
        multiplyBy: 'Multiplicera med',
        divideBy: 'Dividera med',
        replaceWith: 'Ersätt med',
    },
    missingSourceValuePolicy: {
        invalidatePeriod: 'Ignorera period',
        useZeroAndContinue: 'Använd noll',
        ignoreStepAndContinue: 'Ignorera steg',
        useCustomValueAndContinue: 'Använd eget värde',
        useNextStep: 'Använd nästa steg',
    },
    dataSource: {
        reportedData: 'Rapporterad data',
        internalData: 'Intern data',
        constantData: 'Konstant data',
    },
    calculationGrouping: {
        local: 'Objekt',
        global: 'Totalen',
    },
    Person: 'Person',
    People: 'Personer',
    Person_IA: 'En person',
    Person_DA: 'Personen',
    confirmDeleteModal: {
        content: {
            title: 'Radera',
            message: 'Vill du permanent radera det här?',
        },
        dataRelation: {
            title: 'Ta bort härifrån',
            message: 'Vill ta ta bort det här härifrån?',
            messageWithObjectType: 'Vill ta ta bort {{objtype}} härifrån?',
        },
    },
    AnalyzeRequirement: 'Analyskrav',
    AnalyzeRequirements: 'Analyskraven',
    AnalyzeRequirement_IA: 'Ett analyskrav',
    AnalyzeRequirement_DA: 'Analyskravet',
    AnalyzeJob: 'Analysuppdrag',
    AnalyzeJobs: 'Analysuppdragen',
    AnalyzeJob_IA: 'Ett analysuppdrag',
    AnalyzeJob_DA: 'Analysuppdraget',
    MinimumValue: 'Lägsta värde',
    MaximumValue: 'Högsta värde',
    'modules.relativeMeasure': {
        editor: {
            header: 'Beräkningssteg för relativt mätvärde',
            addButtonCaption: 'Lägg till beräkningssteg',
            changeButtonCaption: 'Ändra beräkningssteg',
            emptyStateHeader: 'Du har inte lagt till några beräkningssteg',
            emptyStateDescription: 'Klicka på <b>Lägg till beräkningssteg</b> ovan',
            dragHandleInfo: 'Dra och släpp för att ändra ordning',
            forItem: 'För relativt mätvärde:',
            verificationMessage: 'Beräkningssteg inte korrekt konfigurerade',
            relativeMeasureStep: 'beräkningssteget',
            measurePicker: {
                title: 'Välj ett mätvärde eller relativt mätvärde för',
                selfReferenceAlert: {
                    title: 'Självhänvisning inte tillåten',
                    message: 'Det relativa mätvärdet som för närvarande redigeras kan inte väljas som källmätvärde i sig själv'
                }
            },
            distributeOnYear: {
                checkbox: 'Matcha med år',
                info: 'Om detta mätvärde innehåller data per år men ska ändå beräknas med andra mätvärden som har data inom samma år'
            },
            fallbackStepInfo: {
                title: 'Villkorligt steg:',
                message: 'Beräknas bara när ett värde inte hittas i föregående steg'
            }
        },
        steps: {
            columns: {
                measure: {
                    name: 'Mätvärde',
                    info: 'Välj ett mätvärde',
                    selectButtonCaption: 'Välj mätvärde',
                },
                operation: {
                    name: 'Räknesätt',
                    info: 'Välj räknesätt',
                },
                policy: {
                    name: 'Policy om värde saknas',
                    info: 'Vilken policy som ska användas när data saknas',
                },
                dataSource: {
                    name: 'Datakälla',
                    info: 'Vilken datakälla som ska användas',
                },
                customValue: {
                    name: 'Eget värde',
                    info: 'Ange ett nummer att använda i beräkningen istället för ett mätvärde eller om mätdata saknas',
                },
            },
        },
        preview: {
            operationTooltipText: 'Välj ett räknesätt ovan',
            measureTooltipText: 'Välj ett mätvärde ovan',
        },
    },
    'modules.measureTargets': {
        addTargets: 'Lägg till mål',
        includesTargets: 'Inkluderar mål',
        editor: {
            header: 'Associera med mål',
            forItem: 'För mätvärde:',
            addButtonCaption: 'Lägg till mål',
            emptyStateHeader: 'Du har inte lagt till några mål',
            emptyStateDescription: 'Klicka på <b>Lägg till mål</b> ovan för att göra det',
            verificationMessage: 'Välj mätvärde och datakälla för alla mål',
            columns: {
                measure: {
                    name: 'Mål',
                    info: 'Välj ett mätvärde att använda som mål',
                    selectButtonCaption: 'Välj mätvärde',
                },
                dataSource: {
                    name: 'Datakälla',
                    info: 'Välj vilken datakälla för mätvärdet som ska användas',
                },
            },
            measureSelector: {
                title: 'Välj ett mål för',
            },
        },
    },
    'modules.analyze': {
        package: {
            emptyStateHeader: 'Det här Analyspaketet är tomt',
            emptyStateDescription: 'Klicka på <b>Lägg till</b> ovan för att börja lägga till innehåll',
        },
        results: {
            totalScore: 'Total poäng',
            detailsHeader: 'Beräkningssteg',
            detailsCalcDateInfo: 'Vid den senaste beräkningen',
            analysisFor: 'Analys för:',
            scoreBeforeCalc: 'Poäng före de här beräkningarna',
            scoreAfterCalc: 'Poäng efter de här beräkningarna',
            scoreDifference: 'Skillnad',
            previousResults: 'Tidigare resultat',
            valueFromObjectData_tooltip_measure: '<strong>{{value_and_unit}}</strong><br />Värdet kommer från rapporterad data',
        },
        requirements: {
            calculationSteps: 'Beräkningssteg',
            showMore: 'Visa ytterligare {{count}} steg',
            showLess: 'Visa mindre',
            editButton: 'Redigera beräkningssteg',
            overrideItemTicket: {
                label: 'Källa',
                options: {
                    default: 'Rapporterad data',
                    internalDataOnTargetOrganization: 'Egen intern data på organisationen',
                },
            },
            editor: {
                header: 'Beräkningssteg for analys',
                addButtonCaption: 'Lägg till beräkningssteg',
                changeButtonCaption: 'Ändra beräkningssteg',
                viewButtonCaption: 'Visa beräkningssteg',
                emptyStateHeader: 'Ni har ännu inte lagt till några beräkningssteg',
                emptyStateDescription: 'Klicka på knappen <b>Lägg till beräkningssteg</b>',
                dragHandleInfo: 'Dra och släpp för att ändra ordning',
                forItem: 'För objekt:',
            },
            columns: {
                matchesRequirement: {
                    name: 'Matchar villkor',
                    info: 'Villkor som måste matcha för att ett beräkningssteg ska ske',
                },
                negateRequirement: {
                    name: 'Motsats',
                    info: 'Om motsatsen till villkoret måste matcha för att ett beräkningssteg ska ske',
                },
                operation: {
                    name: 'Operation',
                    info: 'Välj en matematisk operation eller \'Ersätt med\' för att ersätta med ett nytt värde',
                },
                points: {
                    name: 'Poäng',
                    info: '',
                },
                pointsOrAssumeValue: {
                    name: 'Poäng eller använd rapporterat värde',
                    info: '',
                    checkbox_tooltip: 'Kryssa för om det rapporterade värdet ska användas vid beräkning istället för poäng',
                },
                calculationGrouping: {
                    name: 'I/O',
                    info: 'Värdet som kommer användas i beräkningssteget',
                },
                terminal: {
                    name: 'Break',
                    info: 'Om villkoret matchar och Avbryt är förkryssat kommer alla efterföljande beräkningssteg på det här objektet ignoreras',
                },
                resultTask: {
                    name: 'Ytterliggare uppgift',
                    info: 'Ytterliggare uppgift som ska utföras om villkoret matchar',
                },
            },
        },
        additionalResultTask: {
            notDefined: 'Inget',
            writeLocalLabel: 'Sätt etikett',
            writeGlobalLabel: 'Sätt etikett på slutresultat',
            writeLocalAndGlobalLabel: 'Sätt etikett på objekt och slutresultat',
            categorizeTargetOrganization: 'Kategorisera organisation',
            uncategorizeTargetOrganization: 'Avkategorisera organisation',
            labelText: 'Etikettext',
            createMeasureAnswer: 'Lägg till resultatet på ett mätvärde',
            createInfluences: 'Lägg till förfrågningar på organisationen',
            deleteInfluences: 'Ta bort förfrågningar på organisationen',
            influences: {
                limitOnCategories: 'Om organisationen är i någon av dessa kategorier',
                limitOnInvertedCategories: 'Om organisationen inte är i någon av dessa kategorier',
            },
        },
    },
    DateAdded: 'Datum tillagt',
    calendarFrequency: {
        yearly: 'Årsvis',
        halfYearly: 'Halvårsvis',
        quarterly: 'Kvartalsvis',
        monthly: 'Månadsvis',
        custom: 'Anpassade intervall',
        halfYear: 'Halvår',
        quarter: 'Kvartal',
        month: 'Månad',
    },
    measurePeriod: {
        customIntervals: {
            header: 'Anpassade datumintervall',
            info: 'Lägg till en eller flera intervall och välj deras start- och slutdatum.\nÅr beräknas automatiskt.',
            addIntervalButton: 'Lägg till intervall',
            previewHeader: 'Förhandgranskning',
            previewInfo: 'De resulterande intervallen som blir valbara när mätdata läggs till.',
            columns: {
                startDate: 'Startdatum',
                endDate: 'Slutdatum',
                name: {
                    header: 'Namn',
                    info: 'Valfritt namn på intervallet.\nTillgängliga parametrar:'
						+ '<br />&#123;&#123;startDate&#125;&#125; - i format YYYY-MM-DD'
						+ '<br />&#123;&#123;startYear&#125;&#125;'
						+ '<br />&#123;&#123;startMonth&#125;&#125;'
						+ '<br />&#123;&#123;startMonthName&#125;&#125;'
						+ '<br />&#123;&#123;startDay&#125;&#125;'
						+ '<br />&#123;&#123;endDate&#125;&#125; - i format YYYY-MM-DD'
						+ '<br />&#123;&#123;endYear&#125;&#125;'
						+ '<br />&#123;&#123;endMonth&#125;&#125;'
						+ '<br />&#123;&#123;endMonthName&#125;&#125;'
						+ '<br />&#123;&#123;endDay&#125;&#125;',
                },
            },
        },
        intervalsMissingModal: {
            title: '',
            message: 'Minst ett intervall måste anges.',
        },
    },
    errorMessages: {
        reloadButtonCaption: 'Ladda om sidan',
        serverError: {
            title: 'Hoppsan, något gick fel hos oss',
            message: 'Vi ber om ursäkt för detta, prova att ladda om sidan.',
        },
    },
    Group: 'Grupp',
    Groups: 'Grupper',
    PortfolioCompany: 'Portföljebolag',
    PortfolioCompanies: 'Portföljebolag',
    PortfolioCompany_IA: 'Ett portföljebolag',
    PortfolioCompany_DA: 'Portföljebolaget',
    Categories: 'Kategorier',
    'modules.hierarchical': {
        limitedView: 'Begränsad vy',
        fullView: 'Full vy',
        dropdownActions: {
            extendWithOwnItems: 'Utöka med egna objekt',
        },
        createUniqueReport: 'Skapa unik rapport',
    },
    Depth: 'Djup',
    MeasurementPeriod: 'Mätdataperiod',
    'modules.materialityAnalysis': {
        Menu_MaterialityAnalysis: 'Materialitetsanalys',
        MaterialityAnalysis: 'Väsentlighetsanalys',
        MaterialityAnalysis_split: 'Väsentlighets-analys',
        griHeader: 'Companies like yours are reporting on these sustainability areas',
        griHeaderWithOrgName: 'Companies like {{orgname}} are reporting on these sustainability areas',
        sdgHeader: 'Working with the <strong>above sustainability areas</strong> directly contributes to the following <strong class=\'sdg\'>Sustainable Development Goals</strong>',
        sdgDescription: 'Showing the top 5 goals that matches the most reported GRI sustainability areas in your industry. Hover each goal to view specific targets within that goal.',
        guidanceExplanationText: 'Guidance from GRI for reporting on the area',
        moreInsights: 'Want more insights? Get in touch!',
        emptyStateText: 'Sorry, there is not enough data on your industry yet.',
        emptyStateDescription: 'Come back later, or select another industry by editing your profile settings below.',
        columns: {
            disclosure: {
                name: 'Hållbarhetsområde',
                info: 'Companies like yours report on the below sustainability areas based on GRI Sustainability Reporting Standards. The areas are distributed between GRI’s three main chapters: Economic, Social and Environmental, visualized in three different colors. Both the name of the sustainability area and its GRI disclosure code are shown below.',
            },
            match: {
                name: 'Matchning',
                info: 'The percentage tells you how many percent of companies in your industry report on the sustainability area, based on the companies included in our analysis. All companies in the analysis report based on GRI Standards.',
            },
            sdg: {
                name: 'Relaterade mål',
                info: 'The Sustainable Development Goals that match the sustainability area in GRI. By working with the sustainability areas you contribute to these goals. The match is based on the official linkage developed by GRI, UN Global Compact and WBCSD.',
            },
            learnMore: {
                name: 'Läs mer',
                info: 'Read up more on GRI’s guidance for reporting on each respective area.',
            },
            addToScope: {
                name: 'Är det inom ditt scope?',
                info: 'What is scope?',
            },
        },
        loaderBar: {
            text: 'Matching sustainability work of companies in your industry',
            textVariant: 'Matching sustainability work of similar companies',
            editProfile: 'Edit your profile',
        },
        scopeButton: {
            addToScope: 'Lägg till i scope',
            removeFromScope: 'Ta bort från scope',
            addedToScope: 'Tillagt till scope',
        },
    },
    'modules.signup': {
        claimOrg: {
            pageHeader: {
                title: 'You are almost there <span>{{userName}}</span>',
                description1: 'You are about to receive a <stron>free</stron> sustainability analysis based on your organization profile.',
                description2: '<strong>We just need a little more information about your organization.</strong>',
            },
            stepsIndicator: {
                step1: 'Sign up',
                step2: 'Information',
                step3: 'Insights',
            },
            form: {
                title: 'Tell us more about your company',
            },
        },
        landingPage: {
            descriptionSteps: {
                firstStep: {
                    text: 'Sustainability reports are gathered from numerous companies',
                    info: 'Current companies: <span class=\'number-of-companies\'>{{numberOfCompanies}}</span><i class=\'fa fa-info-circle\'></i>',
                    tooltipText: 'The analysis is based on sustainability reports from 300+ companies, covering 30000+ data points. Companies included have more than 250 employees, represent various industries, and use GRI Standards as reporting framework. Both nordic and international companies are represented. New data is frequently added to enrich the analysis.',
                },
                secondStep: {
                    text: 'The Reports are analyzed and mapped to GRI',
                    info: 'What is GRI?',
                },
                thirdStep: {
                    text: 'The results are mapped to the Sustainable Development Goals',
                    info: 'Learn more about the SDG\'s',
                },
                fourthStep: {
                    text: 'The results are shown below and are based on similar companies like yours',
                    info: '',
                },
            },
            pageHeader: {
                title: 'Välkommen <span>{{userName}}</span>',
                description: 'We\'ve found areas that are relevant for your organization based on sustainability reports presented by companies in your industry',
            },
            invite: 'Invite colleagues',
            footer: {
                madeWithLove: 'Made with <i class=\'fa fa-heart\'></i> in Stockholm, Sweden',
            },
        },
    },
    ShowMore: 'Visa mer',
    Finding: 'Avvikelse',
    Findings: 'Avvikelser',
    Finding_IA: 'En avvikelse',
    Finding_DA: 'Avvikelsen',
    FindingStepAnswer: 'Avvikelsesvar',
    FindingStepAnswers: 'Avvikelsevaret',
    FindingStepAnswer_IA: 'Ett avvikelsesvar',
    FindingStepAnswer_DA: 'Avvikelsesvar',
    'modules.findings': {
        severity: 'Allvarlighetsgrad',
        status: 'Status',
        openedAt: 'Öppningsdatum',
        closedAt: 'Stängningsdatum',
        deadlineAt: 'Deadline',
        lockedFinding: {
            title: 'Låst',
            description: 'Ej synlig för rapporterande organisation',
        },
        severities: {
            observation: 'Observation',
            minor: 'Mindre avvikelse',
            major: 'Större avvikelse',
            critical: 'Kritisk avvikelse',
            zeroTolerance: 'Nolltolerans',
        },
        actions: {
            completed: '-',
            toBeAssessed: 'Behöver utvärderas av {{organizationName}}',
            toBeReported: 'Behöver rapporteras av {{organizationName}}',
            toBeAssessedNoOrg: 'Behöver utvärderas',
            toBeReportedNoOrg: 'Behöver rapporteras',
        },
        ownerAndTarget: {
            owner: 'Ägare',
            targetCompletionDate: 'Mål sluttdatum',
            notSet: 'Inte satt',
            editOwnerAndTarget: 'Ändra ägare och slutdatum',
            addOwnerAndTarget: 'Välj ägare och slutdatum',
            form: {
                'modalTitle:': 'Välj en ägare och sätt ett slutdatum',
                ownerTitle: 'Ägare',
                ownerButtonCaption: 'Välj ägare',
                pickerTitle: 'Kollegor',
                pickerDescription: '',
                targetCompletionDateTitle: 'Mål sluttdatum',
            },
        },
        filterLabels: {
            open: 'Öppnad',
            closed: 'Slutförd',
            locked: 'Låst',
            unlocked: 'Olåst',
            closedOnTime: 'Slutförd i tid',
            notClosedOnTime: 'Inte slutförd i tid',
            openedAt: 'Öppnad',
            closedAt: 'Slutförd',
            deadlineAt: 'Deadline',
        },
        statuses: {
            open: 'Öppnad',
            closed: 'Stängd',
            locked: 'Låst',
        },
        answerMessages: {
            reporter: {
                answerUpdated: '{{step}} har uppdaterats och väntar på godkännande',
                answerDenied: '{{step}} nekades av {{verifier}} och måste revideras',
                answerApproved: '{{step}} godkändes av {{verifier}}',
                answerNeeded: '',
                optionalAnswerUpdated: '{{step}} uppdaterades med ytterliggare information',
            },
            verifier: {
                answerUpdated: '{{step}} har uppdaterats och väntar på godkännande av {{verifier}}',
                answerDenied: '{{step}} nekades och väntar på revidering',
                answerApproved: '{{step}} godkändes',
                answerNeeded: '',
                optionalAnswerUpdated: '{{step}} uppdaterades med ytterliggare information',
            },
        },
        verification: {
            varifiedLabel: 'Verifierad som',
            commentLabel: 'Kommentar',
            approved: 'Godkänd',
            denied: 'Ej godkänds',
            verifierUser: 'Verifierad av',
            verifiedAt: 'Datum för verifiering',
        },
        step: {
            emptyState: {
                nothingAddedYet: 'Ingenting tillagt ännu',
                cannotBeAdded: 'Kan inte läggas till ännu',
                cannotBeAddedVerificationNeeded: '{{organizationName}} kan inte lägga till information här. Aktiva steg måste godkännas innan de kan svara.',
                otherStepsNotCompleted: 'Information kan läggas till här när aktiva steg har godkänts av {{organizationName}}',
                ownerAndTargetNotSet: 'Välj en ägare och ett måldatum',
            },
        },
    },
    'modules.auth': {
        signInErrorModal: {
            title: 'Något gick fel',
            message: 'Vi kunde inte autentisera dig just nu och du behöver logga in på nytt.',
            button: 'Gå till login-sidan',
        },
        idleTimeWarning: {
            title: 'Utloggningsvarning',
            message: 'På grund av inaktivitet kommer du automatiskt att loggas ut inom 5 minuter. Fortsätt använda Worldfavor för att förbli inloggad.',
            button: 'OK',
        },
        idleTimeLogout: {
            title: 'Du loggades ut',
            message: 'På grund av 30 minuter inaktivitet loggades du ut automatiskt. För att fortsätta arbeta måste du logga in igen.',
            button: 'Gå till login-sidan',
        },
    },
    'modules.manageScopeDashboard': {
        columns: {
            linkage: {
                emptyState: 'No related goals',
                gri_sdg: {
                    name: 'Relaterade mål',
                    info: 'The Sustainable Development Goals that match the sustainability area in GRI. By working with the sustainability areas you contribute to these goals. The match is based on the official linkage developed by GRI, UN Global Compact and WBCSD.',
                },
            },
            learnMore: {
                name: 'Läs mer',
                info: 'Read up more on guidance for reporting on each respective area.',
            },
        },
        panels: {
            emptyStateNoAddedInfo: 'Ingen tillagd information',
            emptyStateNoQuestions: 'Inga frågor',
        },
        topAreasGuidance: {
            part1: 'Följande områden är inkluderade i ditt scope',
            part2: 'Klicka för att filtrera på ett specifikt område',
        },
        yourOwnData: 'Er egen data',
        addSubItemsHeader: 'Lägg till under ',
        addItemsToScopeHeader: 'Utöka scope',
        setAsDefaultDataSource: 'Öppna alltid aktuell vy med denna källa',
        views: {
            adderModal: {
                header: 'Ändra vyer',
                description: 'För att hantera Egna ramverk, gå till <a href=\'/rel/446023\'>sidan för Egna ramverk</a>.',
            },
        },
        relativeMeasureCache: {
            information: 'Denna dashboard innehåller beräkningar med sparade resultat.\n\nDu kan få alla beräkningar att köras igen genom att rensa sparade resultat härifrån, eller beräkna dem individuellt från varje diagram.',
            clearResultsButton: 'Rensa sparade resultat',
            onlyThisDashboardInfo: 'Påverkar endast beräkningar i dashboard:',
            modalHeader: 'Cache för beräkningsresultat',
            clearingSavedResults: 'Rensar sparade resultat...',
            success: 'Sparade resultat har rensats. Vänligen ladda om denna dashboard.',
            error: 'Det gick inte att rensa sparade resultat, försök igen.',
            reloadButtonCaption: 'Ladda om dashboard'
        }
    },
    'modules.solutionSwitcher': {
        headerText: 'Välj en lösning',
        footer: {
            additionalSolutions: 'Utforska ytterligare lösningar',
        },
    },
    Optional: 'Valfritt',
    Required: 'Krävs',
    'modules.visualization': {
        download: {
            downloadingMessage: 'Genererar bild, vänligen vänta...',
        },
        chartTypes: {
            line: 'Linje',
            bar: 'Stapel',
            pie: 'Cirkel',
            donut: 'Ring',
            stackedBar: 'Staplad Kolumn',
        },
        chartist: {
            emptyState: 'Ingen data än',
            notEnoughData: 'Inte tillräckligt med data för att visa graf',
            barOverlapingMessage: 'För mycket data eller inte tillräckligt med utrymme för att visa grafen',
            allValuesAreZeros: 'Alla värden är noll',
            period: 'Period',
            singleValue: 'Enskilt värde',
            saveButtonTooltipMessages: {
                selectedChartType: 'Den valda inställningen visas för det här objektet',
                savedChartType: 'Valda inställningar som sparats för det här objektet',
                clickToSaveChartType: 'Klicka för att spara inställningar för det här objektet',
            },
            downloadButtonTooltipMessage: 'Ladda ner visualiseringen som en bild',
        },
        emptyStateHeader: 'Ingen rapporterad data',
        errorMessage: {
            standardTitle: 'Ett oväntat fel inträffade när data laddades',
            relativeMeasureSelfReferenceErrorTitle: 'Beräkning ej tillåten - Ett relativt mätvärde refererar till sig självt: ',
            relativeMeasureWithoutStepsErrorTitle: 'Beräkning ej möjlig - Ett relativt mätvärde har inga beräkningssteg: ',
            relativeMeasureStepWithoutCustomValueErrorTitle: 'Beräkning ej möjlig - Ett beräkningssteg saknar ett Eget värde: ',
            relativeMeasureOrganizationsMissingInCacheErrorTitle: 'Omberäkning nödvändig - En eller flera organisationer saknas i det senaste beräknade resultatet',
        },
        aggregateOptions: {
            combine: 'Kombinera organisationer',
            combine_short: 'Kombinera org.',
            separateOrganizations: 'Separera organisationer',
            separateOrganizations_short: 'Separera org.',
            aggregateYearly: 'Kombinera år',
            combineFrequency: 'Kombinera frekvens',
            separateRelativeMeasureSources: 'Beräkn.källor',
            separateRelativeMeasureSourcesDescription: 'Delar upp datan enligt de beräkningskällor som det relativa mätvärdet innehåller',
        },
        conversionOptions: {
            asReported: 'Rapporterat',
            converted: 'Konverterat',
        },
        averageToggle: {
            label: 'Genomsnitt',
            tooltip: 'Dölj eller visa genomsnitt i grafen',
        },
        targetsToggle: {
            label: 'Mål',
            tooltip: 'Dölj eller visa mål i grafen',
        },
        yearFilter: {
            label: 'År',
            all: 'Alla',
            some: 'Några',
        },
        calcQueue: {
            calculating: 'Beräknar',
            calculatingAndSavingResult: 'Beräknar och sparar resultat',
            queued: 'I kö',
        },
        cache: {
            info: {
                lastCalculated: 'Beräknades senast för',
                for: 'i',
                seconds: 'sekunder',
            },
            recalculate: 'Beräkna nu',
            errorDuringClearing: 'Det gick inte att rensa det sparade resultat',
        },
        fiscalYear: {
            label: 'Räkenskapsårsförskjutning är aktiv',
            tooltipText: 'Dataperioder har förskjutits för att aggregeras till räkenskapsåret',
        },
    },
    ProductService: 'Produkt eller tjänst',
    ProductServices: 'Produkter och tjänster',
    ProductService_IA: 'En produkt/tjänst',
    ProductService_DA: 'Produkten/tjänsten',
    EAN_withExpansion: 'EAN (International Article Number)',
    UPC_withExpansion: 'UPC (Universal Product Code)',
    ISBN_withExpansion: 'ISBN (International Standard Book Number)',
    ConsistsOf: 'Består av',
    OwnershipOrganization: 'Från organisation',
    'modules.productService': {
        consistsOf: {
            pickerModal: {
                title: 'Består av',
                description: 'Ange vilka andra produkter/tjänster den består av',
                customDescription: 'Ange vilka {{information}} den består av',
            },
        },
        ownershipOrganization: {
            pickerModal: {
                title: 'Från vilken organisation?',
                customTitle: 'Från vilken {{information}}?',
            },
        },
    },
    Locked: 'Låst',
    calculationSchedule: 'Beräkningsschema',
    'modules.jobs': {
        state: {
            failed: 'Misslyckades',
            aborted: 'Avbröts',
            queued: 'Köad',
        },
        batchCalculateAnalyzeJobs: {
            anlyzeJobsCount: 'Analyser',
            precalculationMessage: 'Omberäkning av analyspaket: {{title}}',
            calculationDetails: '<strong>{{count}}</strong> analyser av <strong>{{total}}</strong> beräknade.',

        },
        batchCalculateInfluences: {
            precalculationMessage: 'Omberäkning av förfrågningar med kravpaket: {{title}}',
            calculationDetails: '<strong>{{count}}</strong> förfrågningar av <strong>{{total}}</strong> beräknade.',
            unfulfilledCalculationResultPolicy: {
                doNothing: 'Ingenting',
                setDueDateFromNow: 'Sätt nytt förfallodatum {{days}} dagar från nu (endast om existerande förfallodatum är tidigare)',
            },
        },
        batchCalculate: {
            recalcReqPackageButton: 'Beräkna om...',
            cancelRecalcReqPackageButton: 'Avbryt...',
            cancelRecalcConfirmation: {
                title: 'Avbryt beräkning',
                message: 'Är du säker på att du vill avbryta beräkningen?',
            },
            calculationPending: 'Omberäkning kommer påbörjas',
            latestCalc: 'Senaste omberäkning',
            recalculating: 'Beräknar om sedan',
            calculationQueued: 'I kö sedan',
            calculationQueuedInfo: 'Denna omberäkning är i kö och startar när föregående beräkningar har slutförts. Kravpaket och analyspaket delar på samma kö.',
            duration: 'Tidåtgång',
            calculationDetailsError: 'Det blev <strong>{{count}}</strong> fel.',
            form: {
                noOrganizationsTitle: 'Inga organisationer',
                noOrganizationsMessage: 'De valda kategorierna matchade inga organisationer.',
                unfulfilledCalculationResultPolicy: {
                    label: 'Vad ska hända när en förfrågan ändras från uppfylld till ej uppfylld?',
                },
                limitByCategories: {
                    label: 'Välj kategorier för att begränsa beräkningen. Om ingen är vald kommer beräkningen ske på alla organisationer med det här kravpaketet.',
                    selectedCategories: 'Valda kategorier:',
                    organizationCount: 'Antal organisationer:',
                },
            },
        },
        batchManageInfluences: {
            delete: {
                openModal: 'Ta bort existerande förfrågningar',
                modalHeader: 'Ta bort alla existerande förfrågningar på flera organisationer samtidigt',
                modalHeaderWithTitle: 'Ta bort existerande förfrågningar på flera organisationer samtidigt med kravpaket: {{title}}',
                postActionMessage: {
                    title: 'Klar med borttagning av förfrågningar',
                    titleWithTitle: 'Klar med borttagning av förfrågningar med kravpaket:\n{{title}}',
                    message: '\nAntal borttagna förfrågningar: <b>{{deleted}}</b>',
                },
            },
            openCreatorModal: 'Lägg till på organisationer',
            openUpdaterModal: 'Uppdatera existerande förfrågningar',
            creatorModalHeader: 'Skapa förfrågningar på flera organisationer samtidigt med kravpaket: {{title}}',
            creatorModalInformation: 'Organisationer som redan har en förfrågan med det här kravpaket ignoreras.',
            updaterModalHeader: 'Uppdatera alla existerande förfrågningar på flera organisationer samtidigt',
            updaterModalHeaderWithTitle: 'Uppdatera existerande förfrågningar på flera organisationer samtidigt med kravpaket: {{title}}',
            limitByCategories: {
                label: 'Välj kategorier för att begränsa organisationer',
                selectedCategories: 'Valda kategorier:',
                organizationCount: 'Antal organisationer:',
            },
            postCreateMessage: {
                title: 'Klar med skapande av förfrågningar med kravpaket:\n{{title}}',
                message: '\nAntal skapade förfrågningar: <b>{{created}}</b>\n\nOrganisationer som redan hade en förfrågan: <b>{{existed}}</b>',
            },
            postUpdateMessage: {
                title: 'Klar med uppdatering av förfrågningar',
                titleWithTitle: 'Klar med uppdatering av förfrågningar med kravpaket:\n{{title}}',
                message: '\nAntal uppdaterade förfrågningar: <b>{{updated}}</b>',
            },
        },
        batchManageAnalyzeJobs: {
            delete: {
                openModal: 'Ta bort existerande analyser',
                modalHeader: 'Ta bort alla existerande analyser på flera organisationer samtidigt',
                modalHeaderWithTitle: 'Ta bort existerande analyser på flera organisationer samtidigt med analyspaket: {{title}}',
                postActionMessage: {
                    title: 'Klar med borttagning av analyser',
                    titleWithTitle: 'Klar med borttagning av analyser med analyspaket:\n{{title}}',
                    message: '\nAntal borttagna analyser: <b>{{deleted}}</b>',
                },
            },
            openCreatorModal: 'Lägg till på organisationer',
            creatorModalHeader: 'Skapa analyser på flera organisationer samtidigt med analyspaket: {{title}}',
            creatorModalInformation: 'Organisationer som redan har en analys med det här analyspaketet ignoreras.',
            limitByCategories: {
                label: 'Välj kategorier för att begränsa organisationer',
                selectedCategories: 'Valda kategorier:',
                organizationCount: 'Antal organisationer:',
            },
            postCreateMessage: {
                title: 'Klar med skapande av analyser med analyspaket:\n{{title}}',
                message: '\nAntal skapade analyser: <b>{{created}}</b>\n\nOrganisationer som redan hade en analys: <b>{{existed}}</b>',
            },
        },
    },
    Start: 'Starta',
    ReportingPeriod: 'Rapporteringsperiod',
    Suggested: 'Föreslagna',
    InternalData: 'Intern data',
    ReportedData: 'Rapporterad data',
    SelectDate: 'Välj ett datum',
    'modules.requirement': {
        requirementDetailsHeader: 'Följande information krävs:',
        fulfillmentStatusLabel: 'Uppfyllnadstatus',
        informationToAttachLabel: 'Information som kan bifogas',
        requirementsLeftToComplete: 'saker kvar att slutföra',
        completed: 'slutfört',
        savingFulfillment: 'Sparar uppfyllnad',
        fulfillmentSaved: 'Uppfyllnad sparad',
        deviation: 'Avvikelse är <strong>{{percentage}}%</strong>',
        detailsLabel: 'Svarsdetaljer'

    },
    GLNNumber: 'GLN-nummer',
    ShouldAttachInformation: 'Du behöver bifoga ytterligare information',
    Tab: 'Flik',
    Attachment: 'Bilaga',
    Attachments: 'Bilagor',
    ReportMode: 'Rapporteringsläge',
    AssignedTo: 'Tilldelat till',
    Comments: 'Kommentarer',
    Verify: 'Verifiera',
    Action: 'Åtgärd',
    Actors: 'Aktörer',
    YearSum: 'Årssumma',
    InfoAdder: {
        singular: 'Informationsfråga',
        plural: 'Informationsfrågor',
        addButton: 'Skapa informationsfråga',
    },
    EmissionFactors: 'Emissionsfaktorer',
    StandardMeasures: 'Standardmätvärden',
    StandardRelativeMeasures: 'Standardberäkningar',
}
