import * as React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import AngularWrapper from './AngularWrapper'
import dateFns from 'date-fns'
import Routes from './reactRoutes'
import { getCulture } from './selectors/appSelector'
import { getMessages, getLocale } from '@worldfavor/i18n'
import { IntlProvider } from 'react-intl'
import CssBaseline from '@worldfavor/components/CssBaseline'
import { withStyles } from '@material-ui/core/styles'
import ErrorBoundary from '@worldfavor/components/ErrorBoundary'

let appVersion = '241202.1502'

const getAppVersion = () => {
    if (process.env.NODE_ENV === 'development') {
        appVersion = dateFns.format(new Date(), 'YYMMDD[.]HHmm')
    }
    return appVersion
}

export const Footer = withStyles({
    root: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    footer: {
        borderTop: '1px solid #e1e1e1',
        padding: '17px 0 15px',
        margin: 'auto',
        maxWidth: 1300,
        color: '#888',

        '& a': {
            color: '#888 !important',
        },

        '& ul': {
            '& li': {
                padding: '0 10px',
            },
        },
    },
})(({ classes, appVersion }) => (
    <div className={classes.root}>
        <footer
            className={classes.footer}
            role="contentinfo"
        >
            <div className="clearfix">
                <ul className="list-unstyled clearfix">
                    <li className="company-name">{ '© Worldfavor AB' }</li>
                    <li className="company-address">{ 'Tegnérgatan 38, 113 59 Stockholm, Sweden' }</li>
                    <li className="company-rights">{ 'All rights reserved.' }</li>
                    <li className="company-terms">
                        <a href="https://worldfavor.com/terms-conditions/" target="_blank" rel="noopener noreferrer">
                            { 'Terms & conditions' }
                        </a>
                    </li>
                </ul>
            </div>
            <div className="appVersionInfo">
                { 'Version ' + appVersion }
            </div>
        </footer>
    </div>
))

class LegacyApp extends React.Component {

  state = {
      authenticated: false,
  }

  _onAuthenticated = () => this.setState({ authenticated: true })

  render() {
      const { culture } = this.props
      const locale = getLocale(culture)
      const { authenticated } = this.state
      return (
          <AngularWrapper onAuthenticated={this._onAuthenticated}>
              <IntlProvider
                  key={locale}
                  locale={locale}
                  messages={getMessages(locale)}
              >
                  <div style={{ paddingTop: 50, paddingBottom: 120 }}>
                      <ErrorBoundary>
                          <CssBaseline />
                          <Switch>
                              {
                                  authenticated && Routes.map(({ name, ...rest }) => <Route key={name} {...rest} />)
                              }
                          </Switch>

                          <Footer appVersion={getAppVersion()} />
                      </ErrorBoundary>
                  </div>
              </IntlProvider>
          </AngularWrapper>
      )
  }
}

const mapStateToProps = (state) => {
    return {
        culture: getCulture(state),
    }
}

export default withRouter(connect(mapStateToProps)(LegacyApp))
