import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfRequirement', wfRequirement)
        .directive('wfRequirementHoverTrigger', wfRequirementHoverTrigger)

    wfRequirementHoverTrigger.$inject = ['$compile', '$parse']
    function wfRequirementHoverTrigger($compile, $parse) {
        const directive = {
            restrict: 'A',
            priority: 9999, // process this directive first
            terminal: true, // stop compiling other directives on the
            // element, we'll fix it in `post`
            controllerAs: 'requirementHoverVm',
            controller() {

            },
            compile(element, attrs) {
                return {
                    post(scope, element, attrs, ctrl) {
                        const nativeElement = element[0]; let options; let influence; let enabled = false

                        if (attrs.wfRequirementHoverTrigger.length) {
                            options = $parse(attrs.wfRequirementHoverTrigger)(scope)
                            if (options && options.influence && options.influence.isInternal) enabled = true
                            else if (!options || !options.influence) enabled = true
                        }

                        if (enabled) {
                            ctrl.popoverTemplateUrl = 'scripts/wf/requirements/wfRequirementPopover.template.html'
                            nativeElement.setAttribute('popover-trigger', '::\'mouseenter\'')
                            nativeElement.setAttribute('popover-enable', 'requirementHoverVm.actualRequirement')
                            nativeElement.setAttribute('popover-popup-close-delay', '100')
                            nativeElement.setAttribute('popover-popup-delay', '100')
                            nativeElement.setAttribute('popover-placement', 'right-top')
                            nativeElement.setAttribute('popover-class', 'wf-requirement-popover')
                            nativeElement.setAttribute('popover-append-to-element', 'true')
                            nativeElement.setAttribute('uib-popover-template', '::requirementHoverVm.popoverTemplateUrl')

                            element.addClass('wf-requirement-hover-trigger')
                        }

                        // Prevent recursion
                        nativeElement.removeAttribute('wf-requirement-hover-trigger')

                        // Compile the element so other directive gets processed
                        $compile(element)(scope)
                    },
                }
            },
            // controller: [ '$scope', '$element', function ($scope, $element) {
            // 	this.element = $element;
            // } ]
        }

        return directive
    }

    wfRequirement.$inject = []
    function wfRequirement() {
        const directive = {
            require: ['?^^wfRequirementHoverTrigger', '?^^wfMeasureAnsweringManager'],
            controller: wfRequirementController,
            controllerAs: 'requirementVm',
            templateUrl: 'scripts/wf/requirements/wfRequirement.directive.html',
            restrict: 'E',
            link,
        }

        return directive

        function link(scope, element, attr, controllers) {
            let
                hoverElement
				
            const vm = scope.requirementVm
				
            const wfRequirementHoverTriggerCtrl = controllers[0]

            vm.wfMeasureAnsweringManagerCtrl = controllers[1]

            if (wfRequirementHoverTriggerCtrl && (hoverElement = wfRequirementHoverTriggerCtrl.element)) {
                hoverElement.on('mouseenter.wfRequirementHoverTrigger', (event) => {
                    hoverElement.mouseenter.off('mouseenter.wfRequirementHoverTrigger')

                    hoverElement.popover({

                    })
                })
            }

            if (wfRequirementHoverTriggerCtrl) {
                wfRequirementHoverTriggerCtrl.actualRequirement = vm.actualRequirement
                wfRequirementHoverTriggerCtrl.requirementVm = vm

                vm.wfRequirementHoverTriggerCtrl = wfRequirementHoverTriggerCtrl
            }

            // if (vm.itemContent.type === enums.objectType.measure && vm.wfMeasureAnsweringManagerCtrl) {
            // 	vm.wfMeasureAnsweringManagerCtrl.update(vm.itemContent.wfid, {
            // 		fulfillsLocally: vm.fulfills
            // 	});
            // }

            // wfRequirementHoverTriggerCtrl.popoverEnabled = true;
            // wfRequirementHoverTriggerCtrl.popoverTemplateUrl = vm.popoverTemplateUrl;
        }
    }

    wfRequirementController.$inject = ['$scope', '$element', 'dataOperationsService', '$timeout', 'wfAuth', 'modalService', '$parse', '$attrs', '$translate', 'requirements', '$q', 'apiProxy', 'wfObject', '$rootScope']
    function wfRequirementController($scope, $element, dataOps, $timeout, wfAuth, modal, $parse, attrs, $translate, requirements, $q, apiProxy, wfObject, $rootScope) {
        const
            settings = $parse(attrs.settings)($scope)
			
        let item
			
        let itemComposite
			
        let itemContent
			
        let requirementOptions
			
        let uiMode
			
        const vm = this
			
        let requirementSpec = {}
			
        let defaultRequirement
			
        let specificRequirement
			
        let requirementWatcher
			
        let requirementWatcherTimeoutPromise
			
        let selectorOptions
			
        const popoverTemplateUrl = 'scripts/wf/requirements/wfRequirementPopover.template.html'
			
        let control
			
        let intersectionSettings
			
        const maxAgePeriods = {
            365: 'calendarFrequency.yearly',
            183: 'calendarFrequency.halfYearly',
            90: 'calendarFrequency.quarterly',
            30: 'calendarFrequency.monthly',
        }

        if (settings) {
            item = $parse(attrs.item)($scope) || settings.item
            itemComposite = $parse(attrs.itemComposite)($scope)
            intersectionSettings = $parse(attrs.intersection)($scope)

            if (itemComposite) {
                itemContent = itemComposite.content
                itemComposite.requirementVm = vm
            }
            else if (item.type === enums.objectType.virtualDataRelation || item.type === enums.objectType.dataRelation) {
                itemContent = item.childContent
            }
            else {
                itemContent = item
            }

            vm.itemContent = itemContent

            // If intersected in back-end then use the originalRelation on the item
            if (item.type == enums.objectType.virtualDataRelation && typeof item.originalRelationWfid === 'string') {
                // console.log("use spec on item.originalRelation")
                item = item.originalRelation
            }

            // If intersected in hierarchical in front-end then use the originalRelation on the itemComposite
            if (itemComposite && itemComposite.originalRelation) {
                item = itemComposite.originalRelation
                // console.log("use spec on itemComposite.originalRelation")
            }

            if (itemContent.type === enums.objectType.finding) {
                item = itemContent // Fulfillments on findings are always on the finding itself and requirements are always virtual

                // // Create virtual requirement
                // wfObject.inject({ "type": 60,
                // 	"objectId": item.id, // Sent to server (normally the same as wffid_requirement)
                // 	"objectType": item.type, // Sent to server (normally the same as wffid_requirement)
                // 	"derivedType": 0,
                // 	"value": null,
                // 	"rule": enums.requirementRule.manual, // Finding fulfillment are always set manually
                // 	"skipManager": false,
                // 	"innerRequirement": null,
                // 	"organizationId": null, // Not needed because findings are always for a specific organization
                // 	"hasComment": false,
                // 	"creatorOrganizationId": item.creatorOrganizationId,
                // 	"creatorUserId": item.creatorUserId,
                // 	"wffid_requirement": item.wfid, // Required for JSData to find the requirement (normally the same as objectType/objectId)
                // 	"creatorUserWfid": item.creatorUserWfid,
                // 	"id": 0, // Sent to server
                // 	"createdAt": null,
                // 	"wfid": "60-|" + item.wfid
                // });
            }

            requirementOptions = settings.requirementOptions
            uiMode = settings.uiMode || enums.uiMode.view

            if (settings.hideElement) $element.hide()

            if ('control' in attrs) {
                control = $parse(attrs.control)($scope)
            }

            if ('hideLabel' in attrs) {
                vm.hideLabel = true
            }
        }
        else {
            $element.remove()
            return
        }

        $scope.$on('$destroy', () => {
            if (requirementWatcher) requirementWatcher()

            $timeout.cancel(requirementWatcherTimeoutPromise)
        })

        if (item.requirements && item.requirements.length) {
            requirementSpec = item.getRequirementSpecification(settings.forOrganizationId)
        }
        else {
            // For some strange reason the requirement is not always instantly available, so we watch for it until it becomes available (within 5 seconds).
            requirementWatcherTimeoutPromise = $timeout(() => {
                requirementWatcher()
            }, 10000)

            requirementWatcher = $scope.$watch(() => {
                return item.requirements ? item.requirements.length : 0
            }, () => {
                if (item.requirements && item.requirements.length) {
                    requirementSpec = item.getRequirementSpecification(settings.forOrganizationId)
                    vm.hasRequirement = requirementSpec.actual
                    $timeout.cancel(requirementWatcherTimeoutPromise)
                    requirementWatcher()

                    vm.compiler.compile()
                }
            })
        }

        // vm.activate = activate;
        activate()

        function activate() {
            _.assign(vm, {
                compiler: {},
                editDefaultRequirement,
                editSpecificRequirement,
                getDefaultRequirementText,
                getSpecificRequirementText,
                getActualRequirementText,
                hasSpecificRequirement,
                hasDefaultRequirement,
                uiMode,
                isAdminMode: uiMode == enums.uiMode.admin,
                isViewMode: uiMode == enums.uiMode.view,
                hasRequirement: false,
                forSpecificOrganization: !!settings.forOrganizationId,
                specificRequirement: void 0,
                popoverTemplateUrl,
                showFulfillmentIndicator: typeof settings.showFulfillmentIndicator === 'boolean' ? settings.showFulfillmentIndicator : true,
                hideAdminTools: settings.hideAdminTools,
                fulfills: null,
                actuallyFulfills: null,
                showManualFulfillmentAssessment: false,
                setManualFulfillmentAssessment,
                showManualFulfillmentButton: settings.showManualFulfillmentButton !== undefined ? settings.showManualFulfillmentButton : true,
                enableIndicatorPopover: 'enableIndicatorPopover' in settings ? settings.enableIndicatorPopover : true,
                getDefaultReportingPeriod,
                getSpecificReportingPeriod,
            })

            if (hasSpecificRequirement()) {
                vm.specificRequirement = requirementSpec.specific
            }

            vm.actualRequirement = requirements.getActualRequirement({
                itemRelation: item,
                itemComposite,
                organizationId: settings.forOrganizationId,
            })

            vm.hasRequirement = !!vm.actualRequirement

            if (vm.actualRequirement && vm.forSpecificOrganization) {

                vm.fulfillmentResult = requirements.checkLocalFulfillment(item.isRelationalType() ? item.childContent : item, item, vm.actualRequirement, intersectionSettings, { useDetailedResult: true })
                vm.fulfills = vm.fulfillmentResult.fulfillmentState == enums.fulfillmentState.fulfilled

                if (vm.isAdminMode && vm.actualRequirement.rule === enums.requirementRule.manual) {
                    vm.showManualFulfillmentAssessment = true
                }
            }

            if (itemContent.type === enums.objectType.finding && !vm.isAdminMode) {
                vm.showFulfillmentIndicator = false
            }

            vm.isEmptyRequirement = vm.actualRequirement && vm.actualRequirement.rule === enums.requirementRule.emptyRequirement

            if (vm.actualRequirement && (vm.actualRequirement.rule === enums.requirementRule.manual || vm.actualRequirement.maxAgeInDays || (vm.fulfillmentResult && vm.fulfillmentResult.fulfillmentState === 'partiallyFulfilled'))) {
                syncFulfillmentState(forcedFulfillmentState(vm.actualRequirement, vm.fulfillmentResult && vm.fulfillmentResult.fulfillmentState))
                // syncFulfillmentState(vm.actualRequirement.maxAgeInDays && vm.fulfillmentResult ? vm.fulfillmentResult.fulfillmentState : (vm.fulfillmentResult && vm.fulfillmentResult.fulfillmentState === "partiallyFulfilled" ? vm.fulfillmentResult.fulfillmentState : null));
            }

            _.assign(control, _.pickBy(vm, (value) => {
                return typeof value === 'function'
            }))

            if (settings.hideRequirementTexts && !vm.isAdminMode) vm.hideRequirementTexts = true

            $scope.$on('localFulfillmentChanged', ($event, item, fulfills, flags) => {
                if (item.wfid !== itemContent.wfid) return

                vm.fulfills = fulfills // true, false, 'exception' or null

                if (vm.actualRequirement && (vm.actualRequirement.rule === enums.requirementRule.manual || vm.actualRequirement.maxAgeInDays || (flags && flags.fulfillmentState === 'partiallyFulfilled'))) {
                    // wfQuestionAnswering directive does an initial emit of "localFulfillmentChanged"
                    // which should not put the fulfillment state to "Assessment needed". That's what the boolean is for (forceAssessmentNeeded)
                    if (vm.actualRequirement.rule === enums.requirementRule.manual) syncFulfillmentState(!(flags && flags.isInitialFulfillmentCheck))
                    else syncFulfillmentState(forcedFulfillmentState(vm.actualRequirement, flags && flags.fulfillmentState))
                }
                else {
                    vm.showPartialIndicatation = false
                    vm.fulfillmentStateText = undefined
                    vm.fulfillmentState = undefined
                    vm.actuallyFulfills = undefined
                }

                if (vm.compiler && vm.compiler.compile) vm.compiler.compile()
            })

            $scope.$on('checkLocalFulfillment', ($event, _itemContent, options) => {
                if (options && options.initial) return

                if (itemContent.wfid != _itemContent.wfid) return

                vm.checkLocalFulfillment(_itemContent, options)
            })

            vm.checkLocalFulfillment = function (itemContent, options) {
                const forceFulfillmentState = _.get(options, 'fulfillmentState')

                if (vm.actualRequirement) {
                    if (options) options.useDetailedResult = true
                    else options = { useDetailedResult: true }

                    vm.fulfillmentResult = requirements.checkLocalFulfillment(itemContent, item, vm.actualRequirement, intersectionSettings || (options ? options.intersectionSettings : undefined), options)
                    vm.fulfills = vm.fulfillmentResult.fulfills
                    if (options && options.onChecked) {
                        options.onChecked({
                            fulfillmentState: vm.fulfillmentResult.fulfillmentState,
                            actuallyFulfills: vm.fulfills,
                            fulfills: vm.fulfills,
                        })
                    }

                    if (vm.actualRequirement.rule === enums.requirementRule.manual || vm.actualRequirement.magAgeInDays) {
                        // When "checkLocalFulfillment" is emitted from wfFinding component
                        // is passes along which fulfillment state should be set in the options object.
                        // If it's not defined then it defaults to vm.fulfillmentResult.fulfillmentState
                        syncFulfillmentState(typeof forceFulfillmentState === 'number' ? forceFulfillmentState : vm.fulfillmentResult.fulfillmentState)
                    }
                    else {
                        vm.showPartialIndicatation = false
                        vm.fulfillmentStateText = undefined
                        vm.fulfillmentState = undefined
                        vm.actuallyFulfills = undefined
                    }
                }
                else {
                    vm.fulfills = null
                    vm.showPartialIndicatation = false
                    vm.fulfillmentStateText = undefined
                    vm.fulfillmentState = undefined
                    vm.actuallyFulfills = undefined
                }

                vm.compiler.compile()
            }
        }

        function forcedFulfillmentState(requirement, fulfillmentState) {
            if (requirement.maxAgeInDays && typeof fulfillmentState === 'number') return fulfillmentState
            else if (fulfillmentState === 'partiallyFulfilled') return fulfillmentState
        }

        function getActualRequirementText()
        {
            return getText(vm.actualRequirement)
        }

        function hasDefaultRequirement() {
            return (requirementSpec && requirementSpec.standard)
        }

        function hasSpecificRequirement() {
            return requirementSpec && requirementSpec.specific
        }

        function getText(requirement, outputDetails) {
            if (!requirement) return

            const isDeviationRuleSet = [ enums.requirementRule.withinDeviationFromSamePeriodLastYear,
                enums.requirementRule.withinDeviationFromPreviousPeriod
            ].includes(requirement.rule)

            const requirementOption = _.find(requirementOptions, { rule: requirement.rule, value: isDeviationRuleSet ? undefined : requirement.value })
            if (outputDetails) {
                outputDetails.selectedRequirementOption = requirementOption
            }

            if (requirementOption) {
                if (requirementOption.translationKeyWithValue) {
                    return $translate.instant(requirementOption.translationKeyWithValue, { requirementValue: requirement.value })
                }
                else {
                    return requirementOption.name
                }
            }
            else {
                return vm.isAdminMode
                    ? $translate.instant('modules.valueChain.requirements.CurrentRequirementInvalid_admin')
                    : $translate.instant('modules.valueChain.requirements.CurrentRequirementInvalid_reporter')
            }
        }

        function getDefaultRequirementText() {
            const requirement = requirementSpec.standard

            if (!requirement && vm.isAdminMode) {
                return $translate.instant('modules.valueChain.requirements.None')
            }

            const outputDetails = {}
            const text = getText(requirement, outputDetails)

            vm.selectedDefaultRequirementOption = outputDetails.selectedRequirementOption

            return text
        }

        function getSpecificRequirementText() {
            const requirement = requirementSpec.specific

            return getText(requirement)
        }

        function getDefaultReportingPeriod() {
            return getReportingPeriod(requirementSpec.standard)
        }

        function getSpecificReportingPeriod() {
            return getReportingPeriod(requirementSpec.specific)
        }

        function getReportingPeriod(requirement) {
            if (!requirement) return

            if (requirement.maxAgeInDays) {
                return $translate.instant('ReportingPeriod') + ': ' + (maxAgePeriods[requirement.maxAgeInDays.toString()] ? $translate.instant(maxAgePeriods[requirement.maxAgeInDays]) : requirement.maxAgeInDays + ' ' + $translate.instant('Days').toLowerCase())
            }
        }

        function editSpecificRequirement(options) {
            let measurePeriodSettings; let promise

            if (!selectorOptions) {
                selectorOptions = _.filter(settings.requirementOptions, (item) => {
                    // if (item.rule === enums.requirementRule.manual)
                    // 	return false;

                    if (options && options.includeUnselectable) return true
                    else return item.selectable !== false
                })
            }

            promise =  modal.editRequirement({
                item,
                forOrganizationId: settings.forOrganizationId,
                selectorOptions,
                useGoal: options && options.useGoal,
            })

            promise.then((updatedRequirementSpec) => {
                if (!updatedRequirementSpec) // If undefined the user clicked cancel in the modal
                    return

                requirementSpec.specific = updatedRequirementSpec.specific

                if (requirementSpec.specific === null) {
                    vm.specificRequirement = null
                    requirementSpec.actual = requirementSpec.standard

                }
                else {
                    vm.specificRequirement = requirementSpec.specific
                    requirementSpec.actual = requirementSpec.specific
                }

                vm.actualRequirement = requirements.getActualRequirement({
                    itemRelation: item,
                    itemComposite,
                    organizationId: settings.forOrganizationId,
                })

                if (settings.onUpdated) {
                    // console.log("settings.onUpdated", vm.actualRequirement);
                    settings.onUpdated(vm.actualRequirement)
                }

                if (vm.forSpecificOrganization) {
                    vm.showManualFulfillmentAssessment = vm.actualRequirement && vm.actualRequirement.rule === enums.requirementRule.manual
                }

                vm.isEmptyRequirement = vm.actualRequirement && vm.actualRequirement.rule === enums.requirementRule.emptyRequirement

                if (vm.wfRequirementHoverTriggerCtrl) vm.wfRequirementHoverTriggerCtrl.actualRequirement = vm.actualRequirement

                vm.hasRequirement = !!vm.actualRequirement

                $scope.$broadcast('requirementChanged')
                $scope.$emit('requirementChanged')

                vm.compiler.compile()
                $timeout()
            })

            return promise
        }

        function editDefaultRequirement() {
            if (!selectorOptions) {
                selectorOptions = _.filter(settings.requirementOptions, (item) => {
                    return item.selectable !== false
                })
            }
            modal.editRequirement({
                item,
                selectorOptions,
            }).then((updatedRequirementSpec) => {
                if (!updatedRequirementSpec) // Undefined if modal was just closed without any change being made
                    return

                requirementSpec.standard = updatedRequirementSpec.standard

                if (requirementSpec.specific === null) {
                    requirementSpec.actual = requirementSpec.standard
                }
                else {
                    requirementSpec.actual = requirementSpec.specific
                }

                vm.actualRequirement = requirements.getActualRequirement({
                    itemRelation: item,
                    itemComposite,
                    organizationId: settings.forOrganizationId,
                })

                if (settings.onUpdated) settings.onUpdated(vm.actualRequirement)

                if (vm.wfRequirementHoverTriggerCtrl) vm.wfRequirementHoverTriggerCtrl.actualRequirement = vm.actualRequirement

                vm.hasRequirement = !!vm.actualRequirement

                $scope.$broadcast('requirementChanged')

                vm.compiler.compile()
                $timeout()
            })
        }

        function setManualFulfillmentAssessment() {
            const availableFulfillmentStates = [
                { value: 0, name: $translate.instant('NotFulfilled') },
                { value: 1, name: $translate.instant('Fulfilled') },
                { value: 2, name: $translate.instant('fulfillmentStates.assessmentNeeded') },
                { value: 3, name: $translate.instant('fulfillmentStates.reportingNeeded') },
            ]

            const formSpec = {
                schema: {
                    type: 'object',
                    properties: {
                        fulfillmentState: {
                            title: 'Status',
                            type: 'integer',
                        },
                    },
                },
                form: [
                    {
                        key: 'fulfillmentState',
                        type: 'select',
                        titleMap: availableFulfillmentStates,
                    },
                    // {
                    // 	key: "comment",
                    // 	type: "textarea"
                    // }
                ],
            }

            modal.editor({
                fulfillmentState: null,
                // comment: null
            },
            {
                title: 'Choose manual fulfillment state',
                action(model) {
                    return $q((resolve, reject) => {
                        if (model.fulfillmentState === null) {
                            resolve()
                            return
                        }
                        apiProxy('fulfillment.setFulfillment', {
                            influenceId: _.get(intersectionSettings, 'influence.id'),
                            requirementId: vm.actualRequirement.id,
                            fulfillmentState: model.fulfillmentState,
                            objectType: vm.actualRequirement.objectType,
                            objectId: vm.actualRequirement.objectId,
                            // comment: model.comment
                        }).then((res) => {
                            wfObject.inject(res)
                            item.fulfillmentWfid = res.wfid
                            resolve()
                            syncFulfillmentState()
                            $rootScope.$broadcast('calculateFulfillment')
                            $scope.$emit('manualFulfillmentChanged')
                        })
                    })
                },
                customFormSpecification: formSpec,
            })

        }

        function syncFulfillmentState(forceAssessmentNeeded_or_forceState) {
            let forceAssessmentNeeded
            let forceState
            let currentFulfillmentState

            if (typeof forceAssessmentNeeded_or_forceState === 'boolean') {
                forceAssessmentNeeded = forceAssessmentNeeded_or_forceState
            }
            else if (typeof forceAssessmentNeeded_or_forceState === 'number') {
                forceState = forceAssessmentNeeded_or_forceState
            }
            else if (forceAssessmentNeeded_or_forceState === 'partiallyFulfilled') {
                forceState = forceAssessmentNeeded_or_forceState
            }

            vm.fulfillmentStateText = undefined
            vm.fulfills = false

            if (forceAssessmentNeeded === true) {
                currentFulfillmentState = enums.fulfillmentState.assessmentNeeded
            }
            else if (typeof forceState === 'number' || forceState === 'partiallyFulfilled') {
                currentFulfillmentState = forceState
            }
            else {
                currentFulfillmentState = _.get(item, 'fulfillment.fulfillmentState')
            }

            vm.fulfillmentState = currentFulfillmentState

            switch (currentFulfillmentState) {
                case enums.fulfillmentState.unfulfilled:
                    vm.showPartialIndicatation = false
                    vm.fulfillmentStateText = $translate.instant('NotFulfilled')
                    break

                case enums.fulfillmentState.fulfilled:
                    vm.fulfills = true
                    vm.showPartialIndicatation = false
                    vm.fulfillmentStateText = $translate.instant('Fulfilled')
                    break

                case enums.fulfillmentState.assessmentNeeded:
                    vm.showPartialIndicatation = true
                    vm.fulfillmentStateText = $translate.instant('fulfillmentStates.assessmentNeeded')
                    break

                case enums.fulfillmentState.reportingNeeded:
                    vm.showPartialIndicatation = true
                    vm.fulfillmentStateText = $translate.instant('fulfillmentStates.reportingNeeded')
                    break

                case enums.fulfillmentState.expired:
                    vm.showPartialIndicatation = false
                    vm.fulfillmentStateText = $translate.instant('fulfillmentStates.expired')
                    break

                case 'partiallyFulfilled':
                    vm.showPartialIndicatation = true
                    vm.fulfillmentStateText = $translate.instant('fulfillmentStates.partiallyFulfilled')
                    break
            }

            // For questions: vm.fulfills is being set to undefined from somewhere unknown.
            // Quick fix is to use another property to hold the value
            vm.actuallyFulfills = vm.fulfills
        }
    }
})()
