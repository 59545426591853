import { calculateMeasureAnswerDeviation } from '@worldfavor/utils/helpers'

(function () {
    'use strict'

    // Regexp that finds the first valid number, with our without decimals, delimited either by "," or "."
    // Basically: (digits) optionally followed by (. or , and digits)
    // Valid examples: 15000   120,0   -120,9482   120.0   -120.9482
    // Invalid examples: 15 000   15.000.000   -15.000,0   15,000.0   -15 000,396
    const numberRegExp = /-?\d+(?:(?:\.|\,)\d{1,})?/g
    const numberRegExpExact = /^-?\d+(?:(?:\.|\,)\d{1,})?$/

    angular
        .module('schemaForm')
        .config(['schemaFormProvider', 'schemaFormDecoratorsProvider', 'sfPathProvider',
            function (schemaFormProvider, schemaFormDecoratorsProvider, sfPathProvider) {
                const measureAnswerValue = function (name, schema, options) {
                    if (schema.format === 'measureAnswerValue') {
                        const f = schemaFormProvider.stdFormObj(name, schema, options)

                        f.key  = options.path
                        f.type = 'measureAnswerValue'
                        f.validationMessage = {
                            validNumber: '',
                        }
                        f.$validators = {
                            validNumber(value) {
                                const
                                    regexpMatch = value ? (value + '').replace(/\s/g, '').match(numberRegExpExact) : null

                                const matched = !!regexpMatch

                                return matched
                            },
                        }
                        options.lookup[sfPathProvider.stringify(options.path)] = f
                        return f
                    }
                }

                schemaFormProvider.defaults.string.unshift(measureAnswerValue)

                schemaFormDecoratorsProvider.addMapping(
                    'bootstrapDecorator',
                    'measureAnswerValue',
                    'scripts/wf/form/schema-form-measureAnswerValue.html',
                )
            },
        ])

    angular
        .module('wf.common')
        .directive('wfSchemaMeasureAnswerValue', ['$rootScope', '$timeout', '$q', '$translate', 'modalService', 'wfObject', 'apiProxy', 'dataOperationsService', '$parse',
            function ($rootScope, $timeout, $q, $translate, modal, wfObject, apiProxy, dataOps, $parse) {
                return {
                    restrict: 'A',
                    scope: true,
                    require: 'ngModel',
                    link(scope, element, attrs, ngModel) {
                        const
                            vm = scope.vm = {
                                rawValue: undefined,
                                formattedValue: undefined,
                                extractNumber,
                                useExtractedAsValue,
                                measureAnswerFormId: _.get(scope, 'form.schema[\'x-schema-form\'].measureAnswerFormId'),
                                measureId: _.get(scope, 'form.schema[\'x-schema-form\'].measureId'),
                                unitSymbol: _.get(scope, 'form.schema[\'x-schema-form\'].unitSymbol'),
                                unitName: _.get(scope, 'form.schema[\'x-schema-form\'].unitName'),
                                unitId: _.get(scope, 'form.schema[\'x-schema-form\'].unitId'),
                                baseUnitFactor: _.get(scope, 'form.schema[\'x-schema-form\'].baseUnitFactor'),
                                showNotAvailableCheckbox: _.get(scope, 'form.schema[\'x-schema-form\'].showNotAvailableCheckbox'),
                                previousAnswer: _.get(scope, 'form.schema[\'x-schema-form\'].latestValueInPreviousPeriod'),
                                previousAnswerBaseUnitFactor: _.get(scope, 'form.schema[\'x-schema-form\'].latestBaseUnitFactorInPreviousPeriod'),
                                previousAnswerUnitId: _.get(scope, 'form.schema[\'x-schema-form\'].latestValueUnitIdInPreviousPeriod'),
                                previousAnswerUnit: _.get(scope, 'form.schema[\'x-schema-form\'].latestValueUnitInPreviousPeriod'),
                                showValidationMessage: false,
                                showUseExtractedAsValueButton: false,
                                decimalSymbol: numeral.localeData(numeral.options.currentLocale).delimiters.decimal,
                                valueNotAvailable: false,
                                notAvailableChanged,
                            }

                        const commaOrPeriodRegExp = /,|\./g

                        activate()

                        function activate() {
                            vm.rawValue = $parse(attrs.ngModel)(scope)

                            vm.decimalSymbolText = vm.decimalSymbol === ',' ? 'komma (,)' : 'period (.)'

                            if (vm.rawValue) vm.rawValue = vm.rawValue.replace(/\./g, ',') // Use comma as decimal mark in text input

                            if (vm.unitSymbol && vm.unitSymbol != '%') {
                                vm.unitSymbol = ' ' + vm.unitSymbol
                            }

                            scope.$watch('vm.rawValue', (rawValue) => {
                                extractNumber()
                            })

                            if (vm.rawValue === null) {
                                vm.valueNotAvailable = true
                                $timeout(() => ngModel.$setViewValue('-1'), 200)

                            }

                            $rootScope.$on('measureUnitChanged_' + vm.measureAnswerFormId, ($event, newUnit) => {
                                if (newUnit) {
                                    vm.unitSymbol = ' ' + newUnit.symbol
                                    vm.unitName = newUnit.name
                                    vm.baseUnitFactor = newUnit.baseUnitFactor
                                    vm.unitId = newUnit.id
                                    calculateDeviation()
                                }
                            })

                            $rootScope.$on('periodChanged_' + vm.measureAnswerFormId, ($event, latestValueInPreviousPeriod, latestBaseUnitFactorInPreviousPeriod, latestValueUnitInPreviousPeriod) => {
                                vm.previousAnswer = latestValueInPreviousPeriod
                                vm.previousAnswerBaseUnitFactor = latestBaseUnitFactorInPreviousPeriod
                                //vm.previousAnswerUnitId = latestValueUnitIdInPreviousPeriod;
                                vm.previousAnswerUnit = latestValueUnitInPreviousPeriod
                                calculateDeviation()
                            })
                        }

                        function notAvailableChanged() {
                            if (vm.valueNotAvailable) {
                                vm.previousViewValue = ngModel.$viewValue
                                ngModel.$setViewValue('-1')
                            }
                            else {
                                ngModel.$setViewValue(vm.previousViewValue)
                            }
                        }

                        function extractNumber() {
                            let
                                value = vm.rawValue || null

                            let regexpMatch

                            let stringResult

                            let numberResult = null

                            if (value) {
                                // Check if the last character is a decimal mark and that it is the only occurrence.
                                // If it is then remove it so that we don't show a validation message for this scenario
                                if ((value.match(commaOrPeriodRegExp) || []).length === 1
							&& ((value.lastIndexOf(',') === value.length - 1) || (value.lastIndexOf('.') === value.length - 1))
                                ) {
                                    value = value.substr(0, value.length - 1)
                                }

                                // Remove whitespaces
                                value = value.replace(/\s/g, '')
                            }

                            regexpMatch = value ? value.match(numberRegExp) : null
                            stringResult = regexpMatch ? regexpMatch[0] : null
                            if (stringResult) stringResult = stringResult.replace(/\,/g, '.')

                            vm.formattedValue = numberResult = stringResult ? parseFloat(stringResult) : null // Replace any commas with periods and parse to float

                            vm.showUseExtractedAsValueButton = false
                            vm.isFormattedValueDecimal = false
                            vm.rawNumber = undefined

                            if (typeof vm.formattedValue === 'number') { // If a number was successfully extracted with regexp
                                vm.rawNumber = vm.formattedValue
                                vm.isFormattedValueDecimal = stringResult.indexOf(',') !== -1 || stringResult.indexOf('.') !== -1 // Toggles info text about decimal marks
                                vm.formattedDisplayValue = numeral(vm.formattedValue).format('0,0.[00000000000000000000]')
                                vm.formattedValue = vm.formattedValue.toString().replace(/\./g, ',')

                                // If the value and the regex result have same length then we dont need to show a validation message to the user
                                if (value.length === stringResult.length) {
                                    ngModel.$setViewValue(stringResult)
                                    vm.showValidationMessage = false
                                    // console.log(stringResult);

                                }
                                else { // Otherwise the value contained other character then just a number so show a validation message to the user
                                    ngModel.$setViewValue(undefined)
                                    // console.log(undefined);

                                    vm.showValidationMessage = true
                                    vm.showUseExtractedAsValueButton = true
                                }
                            }
                            else { // If a number couldn't be extracted with regexp
                                ngModel.$setViewValue(undefined)
                                // console.log(undefined);

                                vm.showValidationMessage = true
                            }

                            calculateDeviation()

                            return numberResult
                        }

                        function useExtractedAsValue() {
                            vm.rawValue = vm.formattedValue.toString().replace(/\./g, ',')
                            extractNumber()
                        }

                        scope.validateField = function () {

                        }

                        scope.$on('schemaFormReset', reset)
                        scope.$on('schemaFormValidate', scope.validateField)
                        scope.$on('schemaFormBeforeSubmit', schemaFormBeforeSubmit)
                        // scope.$on('schemaFormFileUploadSubmit', scope.submit);

                        function reset() {
                            ngModel.$setViewValue(undefined)
                        }

                        function schemaFormBeforeSubmit(event, model) {
                            if (vm.valueNotAvailable) {
                                model.value = '-1'
                                model.notAvailable = true
                            }
                        }

                        function calculateDeviation() {
                            const {
                                previousAnswer,
                                previousAnswerUnit,
                                baseUnitFactor,
                                unitId,
                                rawNumber,
                            } = vm

                            const deviationPercentage = calculateMeasureAnswerDeviation(rawNumber, { baseUnitFactor, id: unitId }, previousAnswer, previousAnswerUnit)

                            vm.deviationFromPreviousAnswer = deviationPercentage !== undefined ? numeral(deviationPercentage).format('0,0.[000]') : undefined
                            vm.showDeviation = deviationPercentage !== undefined
                        }
                    },
                }
            }])
})()
